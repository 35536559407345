@import "../../theme/scss/resources";

.container{
  background: #0d0a07 url("../../theme/assets/other/honee_main_footer_bg.png") no-repeat;
  background-size: cover;
  padding-top: 10rem;

  &.containerWB{
    background: initial !important;
  }



  & .borderWrap{
    padding: 6.25rem 0;
    border-top: 1px solid #FF8C00;

    & .contentWrap{
      @include media(lg) {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
      }

      & .navWrap {
        display: grid;
        grid-gap: 0.75rem;

        margin-top: 2rem;

        @include media(lg) {
          grid-template-columns: repeat(2, max-content);
          justify-content: center;
          width: 100%;
          max-width: 52rem;
          justify-self: center;
          grid-gap: 2rem;

          margin-top: 0;
        }

        @include media(xl) {
          max-width: 60rem;
          grid-gap: 2rem;
        }

        @include media(fullHD) {
          grid-gap: 4rem;
        }

        & .navItem {
          width: max-content;
          margin: 0 auto;
          position: relative;
          cursor: pointer;
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2.2rem;

          color: #F5F5F5;
          text-align: center;

          @include media(lg) {
            font-size: 1.4rem;
            line-height: 2.2rem;
          }

          @include media(fullHD) {
            font-size: 1.6rem;
            line-height: 2.2rem;
          }

          &:hover {
            color: #FF8C00;
          }

          &.navItemSoon{
            &:hover {
              color: #F5F5F5 !important;
            }
          }

          & .navItemAbsSoon{
            position: absolute;
            right: -7rem;
            top: -3rem;

            cursor: pointer;

            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.4rem;
            color: #F5F5F5;
            border: 1px solid #F5F5F5;
            padding: 0.5rem;
            border-radius: 0.5rem;

            @include media(lg) {
              right: -2rem;
              top: -2.25rem;
            }
          }
        }
      }

      & .socWrap{
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-items: center;
        justify-content: center;
        grid-gap: 0.75rem;

        margin-top: 2rem;

        @include media(sm) {
          grid-template-columns: repeat(4, max-content);
        }

        @include media(lg) {
          margin-top: 0;
          justify-self: end;
        }

        & .socItem{
          cursor: pointer;
          display: grid;

          & img{
            width: 90%;

            @include media(xs) {
              width: auto;
            }
          }
        }
      }
    }
  }
}