@import "../../theme/scss/resources";

.container{


  @include media(xl) {
    grid-column: 1 / 3;
  }

  & .contentWrap{
    display: grid;
    grid-gap: 2rem;
    //min-height: 80rem;


    @include media(xl) {
      grid-template-columns: repeat(3, 1fr);
      min-height: 27rem;
    }

    &.contentWrapSubscribe{
      grid-template-columns: initial !important;
      grid-gap: initial !important;
    }

    & .titleWSF{
      margin-bottom: 2.5rem;
    }

    & .subscribeWrap{
      & .subscribeNoteWrap{
        margin-bottom: 4.5rem;

        & .subscribeNoteRow{
          color: #F5F5F5;
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      & .btn{
        @include media(xl) {
          max-width: 40rem;
        }

      }
    }

    & .infoWrap{
      display: grid;
      grid-template-rows: max-content max-content 1fr;

      & .reinvestWrap{
        margin-bottom: 2.5rem;

        & .reinvestBox{
          position: relative;
          width: max-content;
          display: grid;
          grid-template-columns: max-content max-content;
          align-items: center;
          grid-gap: 1.25rem;

          & .profitInvestItemTitle{
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.9rem;

            color: #F5F5F5;
          }

          & .profitBox{
            background: rgba(3, 183, 89, 0.1);
            border: 1px solid #03B759;
            border-radius: 1rem;

            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 1.8rem;

            color: #F5F5F5;
            max-width: 4.919rem;
            text-align: center;
            padding: 0.7rem 0;

            @include media(md) {
              max-width: initial;
              width: 8.3rem;
            }
          }

          & .investBox{

            & input{
              cursor: pointer;
              position: relative;
              width: 6.9rem;
              height: 3.4rem;
              -webkit-appearance: none;
              outline: none;
              background: #211406;
              border-radius: 2rem;
              transition: 0.5s;
              border: 1px solid #8C9497;

              &:checked{
                border: 1px solid #00FF7A;

                &:before{
                  left: 4rem;
                  background: #00FF7A;
                }
              }

              &:before{
                content: '';
                position: absolute;
                top: 0.4rem;
                left: 0.5rem;
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 5rem;
                background: #8C9497;
                transition: 0.5s;
              }
            }
          }
        }

        & .reinvestNote{
          color: #FFFFFF;

          font-size: 1.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-top: 2rem;

          & span{
            color: #FF8C00;
          }
        }

        & .questionWrap{
          cursor: pointer;
          position: absolute;
          right: -2rem;
          top: -1rem;

          & .questionImgWrap{
            display: grid;
          }

          &:hover + .questionHoverNote {
            display: block;
          }
        }

        & .questionHoverNote{
          display: none;
          position: absolute;
          right: -2rem;
          top: -7.5rem;

          width: 21rem;

          color: #FFF;

          text-align: center;

          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          padding: 1.25rem 0.75rem;
          border: 1px solid #FF8C00;
          border-radius: 1rem;

          background: #1d1206;

          @include media(sm) {
            right: -12rem;
          }
        }
      }

      & .button{
        align-self: end;
      }

      & .infoLevelWrap{
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1rem;

        & .infoLevelWrapName{
          color: #FFFFFF;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        & .infoLevelWrapValue{
          display: grid;
          align-items: center;
          justify-items: center;
          justify-content: center;

          width: 9.5rem;
          height: 4rem;

          color: #00FF7A;

          border-radius: 1rem;
          border: 1px solid #00FF7A;

          background: rgba(0, 255, 122, 0.10);

          text-align: center;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      & .infoInputWrap{
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;

        & .inputTitle{
          color: #F5F5F5;

          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0.75rem;
        }

        & input{
          -moz-appearance: textfield;

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        & .inputAvailable{
          color: #F5F5F5;

          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 0.5rem;

          & span{
            color: #FF8C00;
          }
        }
      }
    }

    & .mainBtnWrap{
      display: grid;
      grid-template-rows: 21.3rem 1fr;
      width: 21.3rem;
      margin: 0 auto;
      grid-gap: 2rem;

      @include media(xl) {
        grid-gap: initial;
      }

      & .mainBtnBox{
        display: grid;
        align-items: center;
        justify-items: center;

        & .mainBtn{
          cursor: pointer;

          display: grid;
          position: relative;

          border-radius: 5rem;
          overflow: hidden;

          width: 16.1rem;
          height: 16.1rem;

          & .mainBtnBg{
            display: grid;
            align-items: center;
            justify-items: center;
          }

          & .mainBtnContent{
            display: grid;
            align-items: center;
            position: absolute;
            left: 0;
            right: 0;
            top: 6rem;
            bottom: 0;
            margin: 0 auto;
            text-align: center;
            font-size: 1.7rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 10rem;
            height: 5rem;
          }
        }
      }

      & .barWrap{
        align-self: end;

        & .botLevel{
          display: grid;
          align-items: center;
          justify-items: center;

          position: relative;

          height: 4.8rem;
          width: 100%;
          border: 1px solid #03B759;
          border-radius: 0.8rem;
          background: rgba(0, 255, 122, 0.1);

          //@include media(sm) {
          //  width: 27rem;
          //}

          &.botLevelOrange{
            border: 1px solid #F7931A;
            background: rgba(255, 194, 0, 0.15) -23.04%;
            //background: linear-gradient(138deg, rgba(255, 194, 0, 0.15) -23.04%, rgba(255, 194, 0, 0.01) 118.95%);
            //backdrop-filter: blur(1rem);

            & .botLevelBar{
              background: #F7931A;
            }
          }

          & .botLevelBar{
            position: absolute;
            left: -0.1rem;
            top: -0.1rem;
            height: 4.8rem;
            background: #03B759;
            border-radius: 0.8rem;
            z-index: 10;
          }

          & .botLevelText{
            position: relative;
            z-index: 20;

            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.4rem;
            text-align: center;

            color: #FFFFFF;
          }
        }
      }
    }

    & .settingsWrap{
      @include media(xl) {
        display: grid;
        grid-template-rows: max-content 1fr;
      }
      & .btnsWrap{
        display: grid;
        grid-gap: 1rem;


        @include media(xl) {
          align-content: space-between;
          grid-gap: initial;
        }

        &.btnsWrapCustom{
          height: initial !important;
        }

        &.btnsWrapFhight{
          @include media(xl) {
            height: 14rem;
          }
        }
      }
    }
  }
}