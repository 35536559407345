@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    display: grid;
    grid-gap: 2rem;

    & .titleRow{
      & .title3{
        margin-bottom: 0;
      }
    }

    & .contentBox{
      display: grid;

      grid-gap: 2rem;

      @include media(md) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include media(xl) {
        grid-gap: 4rem;
      }

      & .contentBoxItem{
        padding: 2.5rem;
        border: 1px solid #FFC200;
        border-radius: 1rem;
        background: #101c20;

        overflow: hidden;
        transition: 0.5s;

        @include media(xl) {
          padding: 3.5rem;
        }

        &:first-child{
          border: 1px solid #F7931A;
        }

        &:nth-child(2){

        }

        &:last-child{
          border: 1px solid #00FF7A;
        }

        &.contentBoxItemActiveAnimate{
          &:first-child{
            border: 1px solid #F7931A;
            background: linear-gradient(138deg, rgba(247, 147, 26, 0.15) -23.04%, rgba(247, 147, 26, 0.01) 118.95%);
            backdrop-filter: blur(10px);

            box-shadow: 0 0 0 rgba(247, 147, 26, 0.15);
            animation: pulseA 2s infinite;

            @-webkit-keyframes pulseA {
              0% {
                -webkit-box-shadow: 0 0 0 0 rgba(247, 147, 26, 0.01);
              }
              70% {
                -webkit-box-shadow: 0 0 0 10px rgba(247, 147, 26, 0.07);
              }
              100% {
                -webkit-box-shadow: 0 0 0 0 rgba(247, 147, 26, 0.15);
              }
            }
            @keyframes pulseA {
              0% {
                -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
                box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
              }
              70% {
                -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
                box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
              }
              100% {
                -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
                box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
              }
            }
          }

          &:nth-child(2){
            background: linear-gradient(138deg, rgba(255, 194, 0, 0.15) -23.04%, rgba(255, 194, 0, 0.01) 118.95%);
            backdrop-filter: blur(10px);
            border-radius: 1rem;

            box-shadow: 0 0 0 rgba(247, 147, 26, 0.15);
            animation: pulseB 2s infinite;

            @-webkit-keyframes pulseB {
              0% {
                -webkit-box-shadow: 0 0 0 0 rgba(255, 194, 0, 0.01);
              }
              70% {
                -webkit-box-shadow: 0 0 0 10px rgba(255, 194, 0, 0.07);
              }
              100% {
                -webkit-box-shadow: 0 0 0 0 rgba(255, 194, 0, 0.15);
              }
            }
            @keyframes pulseB {
              0% {
                -moz-box-shadow: 0 0 0 0 rgba(255, 194, 0, 0.4);
                box-shadow: 0 0 0 0 rgba(255, 194, 0, 0.4);
              }
              70% {
                -moz-box-shadow: 0 0 0 10px rgba(255, 194, 0, 0);
                box-shadow: 0 0 0 10px rgba(255, 194, 0, 0);
              }
              100% {
                -moz-box-shadow: 0 0 0 0 rgba(255, 194, 0, 0);
                box-shadow: 0 0 0 0 rgba(255, 194, 0, 0);
              }
            }
          }

          &:last-child{
            border: 1px solid #00FF7A;
            background: linear-gradient(138deg, rgba(0, 255, 122, 0.15) -23.04%, rgba(0, 255, 122, 0.01) 118.95%);
            backdrop-filter: blur(10px);

            box-shadow: 0 0 0 rgba(0, 255, 122, 0.15);
            animation: pulseC 2s infinite;

            @-webkit-keyframes pulseC {
              0% {
                -webkit-box-shadow: 0 0 0 0 rgba(0, 255, 122, 0.01);
              }
              70% {
                -webkit-box-shadow: 0 0 0 10px rgba(0, 255, 122, 0.07);
              }
              100% {
                -webkit-box-shadow: 0 0 0 0 rgba(0, 255, 122, 0.15);
              }
            }
            @keyframes pulseC {
              0% {
                -moz-box-shadow: 0 0 0 0 rgba(0, 255, 122, 0.4);
                box-shadow: 0 0 0 0 rgba(0, 255, 122, 0.4);
              }
              70% {
                -moz-box-shadow: 0 0 0 10px rgba(0, 255, 122, 0);
                box-shadow: 0 0 0 10px rgba(0, 255, 122, 0);
              }
              100% {
                -moz-box-shadow: 0 0 0 0 rgba(0, 255, 122, 0);
                box-shadow: 0 0 0 0 rgba(0, 255, 122, 0);
              }
            }
          }
        }

        & span{
          position: absolute;
          display: block;
        }

        & .topA {
          top: 0;
          left: -100%;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, transparent, #F5F5F5);
          animation: btn-anim1 1.5s linear infinite;
        }

        @keyframes btn-anim1 {
          0% {
            left: -100%;
          }

          50%,
          100% {
            left: 100%;
          }
        }

        & .rightA {
          top: -100%;
          right: 0;
          width: 1px;
          height: 100%;
          background: linear-gradient(180deg, transparent, #F5F5F5);
          animation: btn-anim2 1.5s linear infinite;
          animation-delay: 0.25s;
        }

        @keyframes btn-anim2 {
          0% {
            top: -100%;
          }

          50%,
          100% {
            top: 100%;
          }
        }

        & .bottomA {
          bottom: 0;
          right: -100%;
          width: 100%;
          height: 1px;
          background: linear-gradient(270deg, transparent, #F5F5F5);
          animation: btn-anim3 1.5s linear infinite;
          animation-delay: 0.5s;
        }

        @keyframes btn-anim3 {
          0% {
            right: -100%;
          }

          50%,
          100% {
            right: 100%;
          }
        }

        & .leftA {
          bottom: -100%;
          left: 0;
          width: 1px;
          height: 100%;
          background: linear-gradient(360deg, transparent, #F5F5F5);
          animation: btn-anim4 1.5s linear infinite;
          animation-delay: 0.75s;
        }

        @keyframes btn-anim4 {
          0% {
            bottom: -100%;
          }

          50%,
          100% {
            bottom: 100%;
          }
        }

        & .contentBoxItemTitle{
          font-size: 2.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          text-align: center;

          margin-bottom: 3rem;

          @include media(lg) {
            font-size: 2rem;
          }

          @include media(xl) {
            font-size: 3.4rem;
          }
        }

        & .contentBoxItemName{
          font-size: 1.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;

          @include media(xl) {
            font-size: 2rem;
          }
        }

        & .contentBoxItemValue{
          font-size: 2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;

          color: #F5F5F5;

          @include media(xl) {
            font-size: 2.6rem;
          }
        }
      }
    }
  }
}