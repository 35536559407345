@import "../../../theme/scss/resources";

.container{
  & .btnWrap{
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-around;
    grid-gap: 2rem;

    width: 20rem;
    margin: 0 auto;
    margin-top: 2.5rem;

    &.btnWrapOE{
      grid-template-columns: initial !important;


      & .btnWrapItem{
        border: 1px solid #FFFF00 !important;

        & .btnWrapItemImgWrap{
          transform: initial !important;
          border: none !important;

          & img{
            width: 3rem !important;
            height: 3rem !important;
          }
        }

        & .btnWrapItemContent{
          color: #FFFF00 !important;
        }
      }


    }

    & .btnWrapItem{
      cursor: pointer;
      border-radius: 1rem;
      border: 1px solid #00FF7A;

      background: rgba(255, 255, 255, 0.01);

      backdrop-filter: blur(12px);
      padding: 1rem;

      width: 8.5rem;
      height: 8.5rem;

      &:last-child{
        border: 1px solid #FF8C00;
        background: rgba(255, 255, 255, 0.01);

        & .btnWrapItemContent{
          color: #FF8C00;
        }
      }

      & .btnWrapItemImgWrap{
        display: grid;
        justify-items: center;
        align-items: center;

        width: 4.2rem;
        height: 4.2rem;

        margin: 0 auto;
      }

      & .btnWrapItemContent{
        font-style: normal;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.9rem;

        color: #00FF7A;;
        text-align: center;
      }
    }
  }
}