@import "../../theme/scss/resources";

.container{
  height: 33rem;
  grid-column: 1 / 3;

  @include media(md) {
    grid-column: 1;
    height: 28.5rem;
  }

  @include media(xl) {
    display: grid;
    grid-column: auto;
    order: 4;
    height: 33rem;
  }

  & .honeeCardBody {
    height: 100% !important;

    & .cardBody{
      height: 100%;
      display: grid;
      grid-template-rows: max-content 1fr;

      & .titleWSF {
        margin-bottom: 2rem;
      }

      & .contentBox {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-content: end;

        & .contentBoxItem{
          cursor: pointer;
          display: grid;
          justify-items: center;
          grid-template-rows: 1fr max-content;
          grid-gap: 0.5rem;
          height: 19rem;

          &:hover{

            & .contentBoxItemBar{
              background: #F5F5F5 !important;

              & .contentBoxItemBarValue{
                opacity: 1 !important;
              }
            }
          }

          & .contentBoxItemValue{

          }

          & .contentBoxItemBar{
            background: #878F92;
            border-radius: 0.8rem;
            width: 0.8rem;
            align-self: end;
            height: 100%;

            & .contentBoxItemBarValue{
              opacity: 0;
              position: relative;
              top: -4rem;
              left: -2.6rem;

              display: grid;
              text-align: center;

              width: 6rem;
              height: 2.5rem;

              font-style: normal;
              font-weight: 700;
              font-size: 1.4rem;
              line-height: 2.5rem;

              color: #171817;
              background: #F5F5F5;
              border-radius: 0.4rem;

              & img{
                position: absolute;
                bottom: -0.9rem;
                left: 2.3rem;
              }
            }
          }



          & .contentBoxItemDay{
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: rgba(247, 147, 26, 0.5);


            &:first-letter{
              text-transform: uppercase !important;
            }
          }
          & .contentBoxItemDayInfluencer{
            color: rgba(253, 0, 213, 0.5);
          }
        }
      }
    }
  }
}