@import "../../../theme/scss/resources";

.container{
  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #F5F5F5;
      margin-bottom: 2rem;
    }

    & .listWrap{
      & .listPosWrap{
        height: 6.2rem;
        margin-bottom: 5rem;

        & .listBox{
          cursor: pointer;
          position: relative;

          display: grid;
          grid-gap: 2.25rem;

          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #FF8C00;
          backdrop-filter: blur(7px);
          border-radius: 1rem;

          padding: 1.4rem 2.4rem;

          height: 6.2rem;
          //overflow-y: scroll;
          //


          & .listBtn{
            cursor: pointer;

            position: absolute;
            right: 2.4rem;
            top: 2.15rem;

            width: 2rem;
            height: 2rem;

            display: grid;
            justify-items: center;
            align-items: center;

            transition: all 0.5s ease-out;

            &.listBtnActive{
              transform: rotate(180deg);
            }
          }

          & .listResult{
            display: grid;
            grid-template-columns: max-content max-content 1fr;
            align-items: center;
            grid-gap: 0.75rem;

            //& :nth-child(2){
            //  display: none;
            //}


            & .listResultImgWrap{
              display: grid;
            }

            & .listResultText{
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F5F5F5;
            }

            & .listResultTextBalance{
              justify-self: end;
              padding-right: 3rem;
            }
          }
        }

        & .abslistWrap{
          //overflow-y: hidden;
          //max-height: 0;
          overflow: hidden;
          position: relative;

          max-height: 0;

          -ms-overflow-style: none;
          scrollbar-width: none;

          transition: all 0.5s ease-out;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          &.abslistWrapActive{
            max-height: 25.3rem;

            & .abslistBorderWrap{
              max-height: 23.3rem;
            }
          }



          & .abslistBorderWrap{
            background: #291b09;
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            border-radius: 1rem;

            max-height: 0;
            margin-top: 2rem;
            overflow: scroll;

            -ms-overflow-style: none;
            scrollbar-width: none;

            transition: all 0.5s ease-out;

            &::-webkit-scrollbar {
              width: 0;
              height: 0;
            }

            & .abslistBox{
              overflow-y: scroll;
              position: relative;

              display: grid;
              grid-gap: 2.25rem;



              padding: 1.4rem 2.4rem;

              -ms-overflow-style: none;
              scrollbar-width: none;

              transition: all 0.5s ease-out;

              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }

              //height: 6.2rem;

              & .listBoxItem{
                cursor: pointer;

                display: grid;
                grid-template-columns: max-content max-content 1fr;
                align-items: center;
                grid-gap: 0.75rem;

                & .listBoxItemImgWrap{
                  display: grid;
                }

                & .listBoxItemText{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;
                }

                & .listBoxItemTextBalance{
                  justify-self: end;
                }
              }
            }
          }
        }
      }
      & .button{
        cursor: pointer;

        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.2rem;


        padding: 1.3rem 0;
        text-align: center;

        border-radius: 1rem;

        max-width: 22.7rem;
        margin:  0 auto;
      }
    }
  }
}