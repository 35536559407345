@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin-bottom: 1.25rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin-bottom: 2.5rem;
      }
    }

    & .modalContentBox {
      & .inputWrap{
        &.inputWrapWithErr{
          position: relative;
          & input{
            border: 1px solid #CF0000;
          }

          & .inputErr{
            position: absolute;
            right: 0;
            bottom: -1.5rem;

            color: #CF0000;
            font-style: normal;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }

        & .inputTitle{
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.8rem;

          color: #F5F5F5;
          margin-bottom: 0.5rem;
        }

        & input{
          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #FF8C00;
          border-radius: 1rem;
          width: 100%;

          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.8rem;

          color: #F5F5F5;
          padding: 1.4rem 1.5rem;
        }
      }
    }

    & .saveBtn{
      cursor: pointer;
      width: 100%;
      padding: 1.1rem 0;
      text-align: center;
      background: #F7931A;
      border: 1px solid #F7931A;
      border-radius: 1rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin: 0 auto;
      margin-top: 2.5rem;
      max-width: 19rem;
    }
  }
}