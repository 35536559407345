@import "../../theme/scss/resources";

.container{
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
  border-top: 1px solid #FF8C00;
  border-bottom: 1px solid #FF8C00;
  background: #0d0802;

  & .contentWrap{

    & :global(.overlay){
      &:after, &:before{
        display: none !important;
        width: 6rem;
      }
    }

    & .contentWrapItem{
      display: grid;
      align-items: center;
      margin-left: 3rem;
      margin-right: 3rem;

      & img{
        @include media(lg) {
          width: 90%;
        }
        @include media(xl) {
          width: auto;
        }
      }
    }
  }
}