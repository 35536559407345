@import "../../theme/scss/resources";

.container{
  & .contentWrap{
    display: grid;
    grid-gap: 2rem;

    @include media(xl) {
      grid-template-columns: 47rem 1fr;
      grid-template-rows: 9rem 13.3rem 50rem 18rem 1fr;
    }
  }
}