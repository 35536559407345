@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    display: none !important;
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .note{
      color: #F5F5F5;

      text-align: center;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      & span{
        color: #FF8C00;
      }
    }

    & .checkRow{
      cursor: pointer;

      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: 0.75rem;
      align-items: center;
      justify-content: center;
      width: max-content;

      margin: 0 auto;
      margin-top: 3rem;
      margin-bottom: 2rem;

      & .check{
        display: grid;
      }

      & .checkNote{
        color: #00FF7A;

        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
    }

    & .btnWrap{
      & .button{
        text-transform: uppercase;
      }

      & .cancelBtn{
        opacity: 0;
        color: #8C9497;

        text-align: center;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;

        width: max-content;
        margin: 0 auto;
        margin-top: 2rem;

        &.cancelBtnA{
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}