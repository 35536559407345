@import "../../theme/scss/resources";

.container{
  background: #0d0a07 url("../../theme/assets/other/h2_main_present_ecosystem_bg.png") no-repeat;
  background-size: cover;
  padding: 8.5rem 0;

  @include media(xl) {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .contentWrap{
    grid-gap: 3rem;

    @include media(xl) {
      display: grid;
      grid-template-columns: 1fr 1fr;

    }

    @include media(fullHD) {
      display: grid;
      grid-template-columns: 60rem 1fr;
      grid-gap: 3rem;
    }

    & .textWrap{
      @include media(lg) {
        grid-column: 1;
      }

      & .title2{
        margin-bottom: 2rem;
        text-align: center;

        @include media(xl) {
          text-align: left;
        }

        @include media(lg) {
          margin-bottom: 6rem;
        }
        & span{
          color: #FF8C00;
        }
      }

      & .note{
        color: #F5F5F5;

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.3rem;

        padding: 1rem 0;
        text-align: center;

        @include media(xl) {
          text-align: initial;
        }
      }

      & .btnWrap{
        display: grid;
        margin-top: 3rem;
        justify-self: center;
        grid-column: 1;

        @include media(lg) {
          align-self: end;
          max-width: 35rem;
        }

        @include media(xl) {
          justify-self: initial;
        }
      }
    }

    & .pictureWrap{
      & .pictureBox{
        display: grid;
        justify-items: center;

        margin-top: 2rem;

        @include media(md) {
          margin-top: 0;
        }

        @include media(xl) {
          justify-items: end;
        }

        & img{
          width: 100%;

          @include media(md) {
            width: initial;
          }
        }
      }
    }

    & .btnWrap{
      margin-top: 3rem;
      grid-column: 1;

      @include media(lg) {
        display: grid;
        align-self: end;
        max-width: 35rem;
      }
    }
  }
}