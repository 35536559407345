@import "../../theme/scss/resources";

.container{

  & .titleWSF{
    margin-bottom: 1rem !important;
  }

  & .contentWrap{
    display: grid;

    @include media(xl) {
      grid-template-columns: 31.67% 27.11% 41.22%;
    }

    @include media(xxl) {
      grid-template-columns: 30.5rem 28rem 1fr;
    }

    & .mainWrap{
      border-bottom: 1px solid #915207;
      padding-bottom: 3.4rem;

      @include media(xl) {
        border-bottom: none;
        padding-bottom: 0;

        border-right: 1px solid #915207;
        padding-right: 2rem;
      }

      @include media(xxl) {
        padding-right: 3.4rem;
      }

      & .title2{
        margin-bottom: 1rem;
      }

      & .percent{
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.2rem;

        color: #F5F5F5;
        margin-bottom: 0.7rem;

        & span{
          color: #F7931A;
        }
      }

      & .barWrap{
        display: grid;
        grid-gap: 0.5rem;

        & .barBox{
          & .barTitle{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            color: #F5F5F5;
            margin-bottom: 0.5rem;
          }

          & .barBoxNote{
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: #F5F5F5;
            opacity: 0.5;
          }

          & .bar{
            position: relative;
            display: grid;
            align-items: center;
            justify-items: center;

            background: rgba(3, 183, 89, 0.1);
            border: 1px solid #03B759;
            border-radius: 0.8rem;

            width: 100%;
            height: 3.2rem;

            @include media(md) {
              max-width: 26.7rem;
            }

            & .barValues{
              font-style: normal;
              font-weight: 500;
              font-size: 1.2rem;
              line-height: 1.2rem;
              text-align: center;

              color: #FFFFFF;
              z-index: 20;
            }

            & .barBg{
              position: absolute;
              left: 0;
              top: -0.1rem;
              background: #03B759;
              height: 3.2rem;
              border-radius: 0.8rem;
              z-index: 10;
            }
          }

          & .barBoxPercent{
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: #F5F5F5;

            & span{
              color: #03B759;
            }
          }
        }
      }

      & .barBoxDetailsBtn{
        cursor: pointer;

        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2.2rem;

        text-decoration-line: underline;
        color: #F7931A;

        margin-top: 0.5rem;
      }
    }

    & .profitWrap{
      border-bottom: 1px solid #915207;
      padding: 3.4rem 0;

      @include media(xl) {
        display: grid;
        grid-template-rows: max-content max-content 1fr;
      }

      @include media(xl) {
        border-bottom: none;
        border-right: 1px solid #915207;
        padding: 0 2rem;
      }

      @include media(xxl) {
        padding: 0 3.4rem;
      }

      & .title2{
        margin-bottom: 1rem;
      }

      & .profitAll{
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #03B759;

        display: grid;
        align-items: center;
        justify-items: center;
        background: rgba(3, 183, 89, 0.1);
        border: 1px solid #03B759;

        height: 4.4rem;
        border-radius: 1rem;
      }

      & .profitBox{
        margin-top: 2.5rem;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;

        @include media(xl) {
          align-self: end;
          grid-template-rows: max-content max-content;
          grid-gap: 1rem;
          margin-top: 1rem;
        }

        & .profitBoxItem{
          & .profitBoxItemTitle{
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: #F5F5F5;
          }

          & .profitBoxItemValue{
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: #F7931A;
          }
        }
      }
    }

    & .refWrap{
      padding-top: 3.4rem;

      @include media(xl) {
        padding-top: 0;
        padding-left: 2rem;

        @include media(xl) {
          display: grid;
          grid-template-rows: max-content 1fr;
        }
      }

      @include media(xxl) {
        padding-left: 3.4rem;
      }

      & .title2{
        margin-bottom: 1rem;
      }

      & .refBox{
        display: grid;
        grid-gap: 3rem;

        @include media(xl) {
          grid-gap: 2rem;
        }

        & .refBoxItem{
          @include media(sm) {
            display: grid;
            grid-template-columns: 1fr 14.6rem;
            grid-gap: 2rem;
            align-items: end;
          }

          @include media(xl) {
            grid-template-columns: 19.8rem 14.6rem;
          }

          @include media(xxl) {
            grid-template-columns: 1fr 14.6rem;
          }

          & .fakeInputWrap{
            & .fakeInputTitle{
              font-style: normal;
              font-weight: 400;
              font-size: 1.2rem;
              line-height: 1.6rem;

              color: #F5F5F5;
              margin-bottom: 0.5rem;
            }

            & .fakeInput{
              display: grid;
              align-items: center;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.4rem;

              color: #F5F5F5;

              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              -webkit-backdrop-filter: blur(7px);
              backdrop-filter: blur(7px);
              border-radius: 1rem;

              padding: 0 1.3rem;

              width: 100%;
              height: 4.8rem;

              & span{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }


              @include media(xl) {
                max-width: 24rem;
                overflow: hidden;
              }

              @include media(xxl) {
                max-width: initial;
                overflow: hidden;
              }
            }
          }

          & .copyBtn{
            cursor: pointer;

            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            font-style: normal;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 1.8rem;

            color: #F5F5F5;

            background: #F7931A;
            border-radius: 1rem;

            width: 100%;
            height: 4.8rem;
            margin-top: 2rem;

            &.copyBtnActive{
              color: #F7931A !important;
              background: transparent !important;
              border: 1px solid #F7931A !important;
            }

            @include media(sm) {
              max-width: 14.6rem;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}