@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    display: grid;
    order: 3;
  }

  & .honeeCardBody{
    height: 44rem;
    overflow: hidden;

    @include media(md) {
      //height: 33rem;
    }

    & .cardBodyWithScroll{
      & .title3{
        margin-bottom: 2rem;
      }

      & .titleRow{
        display: none;

        grid-template-columns: repeat(3, 33.333%);
        justify-content: space-between;

        margin-bottom: 2rem;
        padding-right: 2rem;


        @include media(xl) {
          display: grid;
          grid-template-columns: repeat(4, 25%);
        }

        @include media(fullHD) {
          padding-right: 6rem;
        }

        & .title6{
          margin-bottom: 0;

          &.titleRowItemM{
            @include media(md) {
              display: none;
            }
          }

          &.titleRowItemD{
            display: none;

            @include media(md) {
              display: initial;
              text-align: center;
            }
          }
          &:nth-child(2){
            text-align: center;
          }
          &:nth-child(3){
            text-align: center;
          }

          &:last-child{
            text-align: right;
          }
        }
      }

      & .contentBox{
        display: grid;
        grid-gap: 1.5rem;

        overflow-y: scroll;
        height: 33.1rem;
        scroll-padding-bottom: 0;
        grid-template-rows: max-content;
        grid-auto-rows: max-content;

        &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
        &::-webkit-scrollbar {width: 0.5rem;}
        &::-webkit-scrollbar-thumb {border-radius: 4px; background: #FF8C00;}
        //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}

        @include media(xl) {
          height: 26.1rem;
        }


        & .contentBoxItem {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr;
          grid-template-rows: max-content;
          align-items: center;
          justify-content: space-between;

          margin-right: 1.6rem;

          padding: 2rem 0;
          border-bottom: 1px solid #F7931A;

          @include media(xl) {
            grid-template-columns: repeat(4, 1fr);
            padding: 0;
            border-bottom: none;
            grid-gap: 0;
          }

          @include media(fullHD) {
            margin-right: 5.6rem;
          }

          &:first-child{
            padding-top: 0;
          }

          &:last-child{
            padding-bottom: 0;
            border: none;
          }

          & .contentBoxItemWrap{
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: space-between;

            @include media(xl) {
              grid-auto-flow: initial;
              justify-content: center;
              justify-items: center;
            }

            &:first-child{
              @include media(xl) {
                justify-content: left;
                justify-items: left;
              }
            }

            &:last-child{

              @include media(xl) {
                justify-content: right;
                justify-items: right;
              }

              & .contentBoxItemValues{
                text-align: right;
                color: #F7931A !important;
                cursor: pointer;
              }
            }

            & .contentBoxItemWrapTitle{
              @include media(xl) {
                display: none;
              }
            }

            & .contentBoxItemAmount {
              text-align: center;
            }

            & .contentBoxItemTotal {
              text-align: right;

              font-style: normal;
              font-weight: 500;
              font-size: 1.4rem !important;
              line-height: 1.9rem !important;

              color: #F5F5F5 !important;
              width: max-content;
              justify-self: end;

              @include media(md) {
                font-size: 1.6rem !important;
                line-height: 2.2rem !important;
                justify-self: center;
              }

              & .contentBoxItemTotalRow {
                &:first-child {
                  color: #F5F5F5 !important;

                  @include media(md) {
                    display: none;
                  }
                }

                &:last-child {
                  //text-align: right !important;
                }

                &.fluc {
                  &:first-letter {
                    text-transform: uppercase;
                  }
                }
              }
            }

            & .contentBoxItemNameWrap {
              display: grid;
              grid-template-columns: repeat(2, max-content);
              align-items: center;
              grid-gap: 1.5rem;

              & .contentBoxItemNameImgWrap {
                display: grid;
              }

              & .contentBoxItemNameBox {
                & .contentBoxItemFullName {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 0.7rem;
                  line-height: 1rem;

                  color: #8C9497;
                }

                & .contentBoxItemShortName {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 1.5rem;
                  line-height: 2rem;

                  color: #F5F5F5;
                }
              }
            }

            & .contentBoxItemValues {
              font-style: normal;
              font-weight: 500;
              font-size: 1.1rem;
              line-height: 2rem;

              color: #F5F5F5;
              text-align: center;

              & .tabletShow {
                @include media(md) {
                  display: none;
                }
              }

              &.contentBoxItemSM {
                display: none;
                @include media(sm) {
                  display: initial;
                }
              }

              &.contentBoxItemM {
                @include media(md) {
                  display: none;
                }
              }

              &.contentBoxItemD {
                display: none;

                @include media(md) {
                  display: initial;
                  text-align: center;
                }
              }

              &.contentBoxItemValuesBtn{
                display: grid;
                align-items: center;
                justify-items: center;
                text-align: center;

                cursor: pointer;

                border: 1px solid #F7931A !important;
                border-radius: 1rem;
                background: rgba(255, 255, 0, 0.05) !important;
                color: #F7931A !important;

                height: 3.2rem;
                padding: 0 0.75rem;
              }

              & .contentBoxItemValuesRowWrap {

                & .contentBoxItemValuesRow {
                  width: max-content;
                  margin: 0 auto;

                  font-style: normal;
                  font-weight: 500;
                  font-size: 1.4rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;

                  &:first-child {
                    border-bottom: 1px solid rgba(247, 147, 26, 0.4);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//@import "../../theme/scss/resources";
//
//.container{
//  @include media(xl) {
//    display: grid;
//    order: 3;
//  }
//
//  & .honeeCardBody{
//    & .formWrap{
//      & .titleWSF{
//        margin-bottom: 1.5rem;
//      }
//
//      & .settingsWrap{
//        display: grid;
//        grid-auto-flow: column;
//        justify-content: space-between;
//
//        margin-bottom: 2rem;
//
//        @include media(xl) {
//          max-width: calc(100% - 15.2rem);
//        }
//
//        & .settingsItem{
//          & .settingsItemName{
//            color: #8C9497 !important;
//            margin-bottom: 1.5rem;
//            text-align: center;
//          }
//
//          & .settingsItemValue{
//            display: grid;
//            align-items: center;
//            justify-items: center;
//            text-align: center;
//
//            border-radius: 1rem;
//            border: 1px solid #00FF7A;
//
//            background: rgba(0, 255, 122, 0.10);
//
//            backdrop-filter: blur(10px);
//            width: 9.6rem;
//            height: 3.2rem;
//
//            color: #FFF;
//
//            font-size: 1.6rem;
//            font-style: normal;
//            font-weight: 500;
//            line-height: normal;
//          }
//
//          & .settingsItemCoin{
//            display: grid;
//            grid-template-columns: max-content max-content;
//            grid-gap: 1rem;
//            align-items: center;
//
//            & .settingsItemCoinName{
//              display: none;
//              color: #FFF;
//
//              font-size: 1.6rem;
//              font-style: normal;
//              font-weight: 500;
//              line-height: normal;
//
//              @include media(sm) {
//                display: block;
//              }
//            }
//          }
//
//          &:first-child{
//            & .settingsItemName{
//              text-align: left;
//            }
//          }
//
//          &:last-child{
//            & .settingsItemName{
//              text-align: right;
//            }
//          }
//        }
//      }
//
//      & .inputWrap{
//        @include media(md) {
//          display: grid;
//          grid-template-columns: 1fr 14rem;
//          align-items: start;
//          grid-gap: 1.2rem;
//        }
//
//        & .inputBox{
//          position: relative;
//
//          & input{
//            font-style: normal;
//            font-weight: 400;
//            font-size: 1.2rem;
//            line-height: 1.6rem;
//
//            color: #F5F5F5;
//            width: 100%;
//
//            background: rgba(15, 32, 52, 0.1);
//            border: 1px solid #FF8C00;
//            backdrop-filter: blur(7px);
//            border-radius: 1rem;
//
//            padding: 1.4rem 2.4rem;
//          }
//
//          & .inputSum{
//            padding: 1.4rem 6.4rem 1.4rem 2.4rem;
//            -moz-appearance: textfield;
//
//            &::-webkit-inner-spin-button {
//              -webkit-appearance: none;
//              margin: 0;
//            }
//          }
//
//          & .inputAbs{
//            position: absolute;
//            right: 2rem;
//            top: 1.35rem;
//            height: 2rem;
//
//            & .inputAbsCur{
//              font-style: normal;
//              font-weight: 400;
//              font-size: 1.4rem;
//              line-height: 1.9rem;
//
//              color: #F5F5F5;
//              padding-right: 1rem;
//            }
//
//            & .inputAbsBtn{
//              cursor: pointer;
//
//              font-style: normal;
//              font-weight: 400;
//              font-size: 1.4rem;
//              line-height: 1.9rem;
//
//              color: #F7931A;
//              padding-left: 1rem;
//              border-left: 1px solid #D9D9D9;
//            }
//          }
//
//          & .inputNote{
//            font-style: normal;
//            font-weight: 500;
//            font-size: 1.2rem;
//            line-height: 1.6rem;
//
//            color: #F5F5F5;
//            margin-top: 0.5rem;
//
//            & span{
//              color: #F7931A;
//            }
//          }
//        }
//
//        & .mainBtn{
//          display: grid;
//          justify-items: center;
//          align-items: center;
//          text-align: center;
//
//          font-style: normal;
//          font-weight: 700;
//          font-size: 1.8rem;
//          line-height: 2.5rem;
//
//          color: #8C9497;
//
//          border: 1px solid #8C9497;
//          border-radius: 1rem;
//          height: 4.6rem;
//
//
//          @include media(md) {
//            display: grid;
//          }
//
//          &.mainBtnActive{
//            cursor: pointer;
//
//            background: #F7931A;
//            color: #F5F5F5;
//            border: 1px solid #F7931A;
//          }
//        }
//      }
//
//      & .daysWrap{
//        margin-top: 1.5rem;
//        margin-bottom: 2rem;
//
//        & .daysTitle{
//          font-style: normal;
//          font-weight: 500;
//          font-size: 1.4rem;
//          line-height: 1.9rem;
//
//          margin-bottom: 1rem;
//          color: #F5F5F5;
//        }
//
//        & .daysBox{
//          display: grid;
//          grid-auto-flow: column;
//          grid-gap: 1.8rem;
//          align-items: center;
//
//          @include media(md) {
//            justify-content: left;
//          }
//
//          &.daysBoxInitial{
//            & .daysBoxItem{
//              &:first-child{
//                color: #F7931A;
//                background: rgba(247, 147, 26, 0.1);
//                border: 1px solid #F7931A;
//              }
//            }
//          }
//
//          & .daysBoxItem{
//            cursor: pointer;
//
//            font-style: normal;
//            font-weight: 600;
//            font-size: 1.8rem;
//            line-height: 1.8rem;
//
//            color: #F5F5F5;
//            border: 1px solid #F5F5F5;
//            border-radius: 1rem;
//            max-width: 8.3rem;
//            text-align: center;
//            padding: 0.7rem 0;
//
//            @include media(md) {
//              max-width: initial;
//              width: 8.3rem;
//            }
//
//            &.daysBoxItemActive{
//              color: #F7931A;
//              background: rgba(247, 147, 26, 0.1);
//              border: 1px solid #F7931A;
//            }
//          }
//        }
//      }
//
//      & .profitInvestWrap{
//        display: grid;
//        grid-template-columns: 4.919rem max-content;
//        grid-gap: 4rem;
//
//        margin-bottom: 2.5rem;
//
//        @include media(md) {
//          grid-template-columns: 8.3rem max-content;
//        }
//
//        & .profitInvestWrapItem{
//          & .profitInvestItemTitle{
//            font-style: normal;
//            font-weight: 500;
//            font-size: 1.4rem;
//            line-height: 1.9rem;
//
//            color: #F5F5F5;
//            margin-bottom: 1rem;
//          }
//
//          & .profitBox{
//            background: rgba(3, 183, 89, 0.1);
//            border: 1px solid #03B759;
//            border-radius: 1rem;
//
//            font-style: normal;
//            font-weight: 600;
//            font-size: 1.8rem;
//            line-height: 1.8rem;
//
//            color: #F5F5F5;
//            max-width: 4.919rem;
//            text-align: center;
//            padding: 0.7rem 0;
//
//            @include media(md) {
//              max-width: initial;
//              width: 8.3rem;
//            }
//          }
//
//          & .investBox{
//            & input{
//              position: relative;
//              width: 6.9rem;
//              height: 3.4rem;
//              -webkit-appearance: none;
//              outline: none;
//              background: #D9D9D9;
//              border-radius: 2rem;
//              transition: 0.5s;
//              box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
//
//              &:checked{
//                background: #F7931A;
//
//                &:before{
//                  left: 4rem;
//                }
//              }
//
//              &:before{
//                content: '';
//                position: absolute;
//                top: 0.5rem;
//                left: 0.5rem;
//                width: 2.4rem;
//                height: 2.4rem;
//                border-radius: 5rem;
//                background: #ffffff;
//                transition: 0.5s;
//              }
//            }
//          }
//        }
//      }
//
//      & .noteWrap{
//        margin-top: 1.5rem;
//
//        & .noteWrapRow{
//          margin-bottom: 0.5rem;
//
//          @include media(sm) {
//            display: grid;
//            grid-template-columns: max-content max-content;
//            align-items: center;
//            grid-gap: 0.5rem;
//          }
//
//          & .noteWrapRowName{
//            font-style: normal;
//            font-weight: 500;
//            font-size: 1.2rem;
//            line-height: 1.9rem;
//
//            color: #F5F5F5;
//          }
//
//          & .noteWrapRowValue{
//            color: #FFFF00;
//
//            border-radius: 1rem;
//            border: solid 1px #FF0;
//
//            padding: 0.75rem 1rem;
//            text-align: center;
//          }
//
//          & span{
//
//          }
//
//          &:first-child{
//            margin-bottom: 1.5rem;
//          }
//        }
//
//        & .noteWrapText{
//          margin-top: 2.5rem;
//
//          text-align: center;
//          font-style: normal;
//          font-weight: 500;
//          font-size: 1.2rem;
//          line-height: 1.6rem;
//
//          color: #FF8C00;
//
//          border-radius: 1rem;
//          border: 1px solid #FF8C00;
//          padding: 2rem;
//
//          background: #261D12;
//
//          @include media(fullHD) {
//            font-size: 1.4rem;
//          }
//        }
//      }
//
//      & .mainBtnM{
//        font-style: normal;
//        font-weight: 700;
//        font-size: 1.8rem;
//        line-height: 2.5rem;
//        text-align: center;
//        padding: 1rem 0;
//
//        color: #8C9497;
//
//        border: 1px solid #8C9497;
//        border-radius: 1rem;
//
//        margin-top: 3rem;
//
//        @include media(md) {
//          display: none;
//        }
//
//        &.mainBtnMActive{
//          cursor: pointer;
//
//          background: #F7931A;
//          color: #F5F5F5;
//          border: 1px solid #F7931A;
//        }
//      }
//    }
//  }
//}