@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin-bottom: 1.25rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin-bottom: 2.5rem;
      }
    }

    & .titleRow{
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      grid-gap: 1rem;

      margin-bottom: 1.25rem;

      @include media(md) {
        margin-bottom: 2.5rem;
        grid-gap: 2rem;
      }

      & .backBtn{
        cursor: pointer;

        border: 1px solid #FFFFFF;
        border-radius: 1rem;

        padding: 1rem;

        color: #FFFFFF;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &:hover{
          border: 1px solid #FF8C00;
        }

        & span{
          display: none;
          padding-left: 1rem;

          @include media(md) {
            display: initial;
          }
        }
      }

      & .title{
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.5rem;
        color: #F5F5F5;
        margin-bottom: 0;

        @include media(md) {
          font-size: 2.4rem;
          line-height: 3.3rem;
        }
      }
    }

    & .modalContentBox{
      & .inputWrap{
        & .inputTitle{
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;

          margin-bottom: 0.75rem;
        }

        & input{
          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #FF8C00;
          border-radius: 1rem;
          width: 100%;

          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.8rem;

          color: #F5F5F5;
          padding: 1.4rem 1.5rem;

          -moz-appearance: textfield;

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        & .balance{
          color: #f5f5f5;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          margin-top: 0.5rem;
        }

        & .inputNote{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;
          margin-top: 0.5rem;

          & span{
            color: #F7931A;
          }
        }
      }

      & .sliderWrap{
        margin: 1.5rem 0;

        & .sliderTitle{
          color: #F5F5F5;

          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          margin-bottom: 0.5rem;
        }

        & .sliderTitleValues{
          display: grid;
          grid-auto-flow: column;
          justify-content: space-between;

          & .sliderTitleValuesItem{
            color: #FFF;

            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.2px;
          }
        }

        & .sliderBox{

          & .customSlider {
            width: 100%;
            height: 0.2rem;
            background-color: lightgray;
            margin: 1rem auto;
          }

          & .customThumb {
            height: 1.6rem;
            width: 1.6rem;
            background-color: #FF0;
            cursor: pointer;
            border-radius: 50%;
            position: relative;
            top: -0.7rem;
          }

          & .customTrack {
            position: absolute;
            height: 0.2rem;
          }

          & .filledTrack {
            background-color: #FF0;
          }

          & .unfilledTrack {
            background-color: #5b5806;
          }
        }
      }

      & .profitWrap{
        display: grid;
        grid-gap: 1.5rem;

        margin-bottom: 1.5rem;

        & .profitItem{
          display: grid;
          grid-gap: 1.25rem;

          @include media(md) {
            grid-template-columns: max-content max-content;
            align-items: center;
          }

          & .profitItemName{
            color: #FFFFFF;

            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          & .profitItemValue{
            color: #FF0;

            text-align: center;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            border-radius: 1rem;
            border: 1px solid #FF0;

            padding: 1rem;
          }
        }
      }

      & .reinvestWrap{
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1.5rem;

        margin-bottom: 3.5rem;

        & .reinvestTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.9rem;

          color: #F5F5F5;
        }

        //& .reinvestBox{
        //  background: rgba(3, 183, 89, 0.1);
        //  border: 1px solid #03B759;
        //  border-radius: 1rem;
        //
        //  font-style: normal;
        //  font-weight: 600;
        //  font-size: 1.8rem;
        //  line-height: 1.8rem;
        //
        //  color: #F5F5F5;
        //  max-width: 4.919rem;
        //  text-align: center;
        //  padding: 0.7rem 0;
        //
        //  @include media(md) {
        //    max-width: initial;
        //    width: 8.3rem;
        //  }
        //}

        & .reinvestBox{
          display: grid;
          align-items: center;

          & input{
            position: relative;
            width: 6.9rem;
            height: 3.4rem;
            -webkit-appearance: none;
            outline: none;
            background: #D9D9D9;
            border-radius: 2rem;
            transition: 0.5s;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

            &:checked{
              background: #F7931A;

              &:before{
                left: 4rem;
              }
            }

            &:before{
              content: '';
              position: absolute;
              top: 0.5rem;
              left: 0.5rem;
              width: 2.4rem;
              height: 2.4rem;
              border-radius: 5rem;
              background: #ffffff;
              transition: 0.5s;
            }
          }
        }
      }
    }

    & .modalConfirmWrap{
      display: grid;
      grid-gap: 1.5rem;

      margin-bottom: 3.5rem;

      & .modalConfirmWrapItem{
        display: grid;
        grid-gap: 0.5rem;

        @include media(md) {
          grid-template-columns: max-content max-content;
          align-items: center;
          grid-gap: 1.5rem;
        }

        & .modalConfirmWrapItemName{
          color: #FFFFFF;

          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        & .modalConfirmWrapItemValue{
          color: #00FF7A;

          text-align: center;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          border-radius: 1rem;
          border: 1px solid #00FF7A;

          padding: 1rem;

          &.modalConfirmWrapItemValueRed{
            color: #FF060A !important;
            border: 1px solid #FF060A !important;
          }
        }
      }
    }
  }
}