@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .title2{
      font-size: 2.8rem;
      text-align: center;
    }

    & .note{
      color: #FFFEFE;

      text-align: center;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;

      margin: 0 auto;
      margin-top: 4rem;
      margin-bottom: 5rem;

      max-width: 43rem;

      & span{
        color: #F7931A;
      }
    }

    & .btnsWrap{
      display: grid;
      grid-gap: 1rem;

      @include media(md) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2.5rem;
      }
    }

    & .btnsWrap{
      & .custom{

      }
    }
  }
}