@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

:global(.modalWithoutP-overlay) {
  :global(.popup-content) {
    padding: 0 !important;
  }
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;

    top: 0.25rem;
    right: 0.25rem;

    @include media(md) {
      top: 1rem;
      right: 1rem;
    }
  }

  & .modalContentWrap{
    & .titleRow{
      display: grid;
      grid-template-columns: 5rem 8rem 10rem 8rem 4rem;
      border-bottom: 1px solid #5B666A;

      @include media(xs) {
        grid-template-columns: 6rem 8rem 10rem 11rem 5rem;
      }

      @include media(md) {
        grid-template-columns: 9rem 11.5rem 18rem 17.7rem 8rem;
      }

      & .titleRowItem{
        display: grid;
        align-items: center;

        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.3rem;
        text-align: center;
        padding: 0.5rem;
        color: #F5F5F5;


        border-right: 1px solid #5B666A;

        @include media(md) {
          font-size: 1.4rem;
          line-height: 1.8rem;
          padding: 1rem;
        }

        &:last-child{
          border-right: none;
        }
      }
    }

    & .contentBox{
      & .contentRow{
        display: grid;
        grid-template-columns: 5rem 8rem 10rem 8rem 4rem;
        border-bottom: 1px solid #5B666A;

        @include media(xs) {
          grid-template-columns: 6rem 8rem 10rem 11rem 5rem;
        }

        @include media(md) {
          grid-template-columns: 9rem 11.5rem 18rem 17.7rem 8rem;
        }

        &:last-child{
          border-bottom: none;
        }

        & .contentRowItem{
          display: grid;
          align-items: center;

          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 1.5rem;
          text-align: center;

          color: #F5F5F5;
          height: 6rem;

          border-right: 1px solid #5B666A;

          &:last-child{
            border-right: none;
          }

          @include media(md) {
            font-size: 1.3rem;
            line-height: 1.9rem;
          }

          &.bolded{
            font-size: 1rem;
            line-height: 1.3rem;

            font-weight: 700;

            @include media(md) {
              font-size: 1.6rem;
              line-height: 2.4rem;
            }
          }

          &.bigest{
            font-size: 1.2rem;
            line-height: 1.8rem;

            @include media(md) {
              font-size: 1.6rem;
              line-height: 2.4rem;
            }
          }
        }
      }
    }
  }
}