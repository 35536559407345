@import "../../../theme/scss/resources";

.container{
  display: grid;
  width: 3.2rem;

  &.containerBig{
    width: 5.6rem;

    & img{
      width: 5.6rem;
      height: 5.6rem;
    }
  }

  &.containerIndex{
    width: 3.2rem;

    & img{
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}