@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    display: grid;
    grid-gap: 2rem;

    & .titleRow{
      & .title3{
        margin-bottom: 0;
      }
    }

    & .contentBox {
      display: grid;
      grid-template-rows: 1fr 31.8rem;

      @include media(md) {
        grid-template-columns: 1fr 31.8rem;
        grid-template-rows: initial;
        grid-gap: 2rem;
      }

      & .settingsWrap{
        min-height: 23.5rem;


        & .settingsBox{
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(4, 5rem);
          grid-gap: 1rem;
          height: 23rem;

          @include media(md) {
            height: 31.8rem;
          }

          @include media(md) {
            grid-template-rows: repeat(4, 1fr);
          }

          & .settingsItem {
            cursor: pointer;

            display: grid;
            align-content: center;
            justify-content: center;
            text-align: center;

            border-radius: 1rem;
            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            padding: 1rem 0.5rem;

            &.settingsItemPassive {
              cursor: initial !important;
            }

            &.settingsItemGreen {
              border: 1px solid #00FF7A;
              background: rgba(0, 255, 122, 0.10);
            }

            &.settingsItemRed {
              border: 1px solid #F00;
              background: rgba(255, 0, 0, 0.10);
            }

            &.settingsItemOrange {
              border: 1px solid #FF8C00;
              background: rgba(255, 140, 0, 0.10);
            }

            & .settingsItemName, & .settingsItemValue {
              color: #F5F5F5;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        & .dropBox{
          display: grid;
          grid-template-rows: 7.5rem max-content max-content max-content 1fr;
          grid-gap: 1rem;

          min-height: 31.8rem;

          & .dropName{
            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            height: 7.5rem;

            border-radius: 1rem;
            border: 1px solid #58C1EF;

            background: rgba(88, 193, 239, 0.10);

            &.titleWSF{
              font-size: 1.4rem;
              margin-bottom: 0;
            }

          }

          & .dropTitle{
            height: 5.5rem;
            cursor: pointer;

            display: grid;
            justify-items: center;
            align-content: center;
            align-self: end;

            border-radius: 1rem;
            border: 1px solid #FFF;
            background: rgba(255, 255, 255, 0.10);



            color: #F5F5F5;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            padding: 1rem;

            & .dropTitleBack{
              display: grid;
              grid-template-columns: max-content max-content;
              align-items: center;
              grid-gap: 1rem;

              & .dropTitleBackImgWrap{
                display: grid;
              }

              & .dropTitleBackNote{
                font-size: 1.8rem;
                margin-bottom: 0;
              }
            }

            & .dropTitleArrow{
              transform: rotate(180deg);
            }
          }

          & .dropItem{
            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            cursor: pointer;
            height: 4.5rem;


            border-radius: 1rem;
            border: 1px solid #58C1EF;
            background: #0e1b1b;

            color: #F5F5F5;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;

            padding: 1rem;


            &.green{
              border: 1px solid #00FF7A !important;
              background: rgba(0, 255, 122, 0.10) !important;
            }

            &.orange{
              border: 1px solid #FF8C00 !important;
              background: rgba(255, 140, 0, 0.10) !important;
            }

            &.red{
              border: 1px solid #F00 !important;
              background: rgba(255, 0, 0, 0.10) !important;
            }
          }
        }
      }

      & .beeWrap{
        height: 22rem;
        @include media(md) {
          height: initial;
        }
        & .imgWrap{
          display: grid;
          position: relative;

          & img{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
    }
  }
}