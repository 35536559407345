@import "../../theme/scss/resources";

.container{
  height: 75rem;
  overflow: hidden;

  & .contentWrap{
    padding: 3.5rem 1.5rem 3.5rem 3.5rem;

    @include media(fullHD) {
      padding: 3.5rem 3.5rem 3.5rem 6.5rem;
    }

    & .titleWSF{
      margin-bottom: 2rem;
    }

    & .titleRow{
      display: grid;
      grid-template-columns: repeat(3, 33.333%);
      justify-content: space-between;

      margin-bottom: 2rem;
      padding-right: 2rem;

      @include media(md) {
        grid-template-columns: repeat(5, 20%);
      }

      @include media(fullHD) {
        padding-right: 6rem;
      }

      & .titleRowItem{
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;

        color: #8C9497;

        &:nth-child(2){
          text-align: center;
          display: none;

          @include media(md) {
            display: block;
            text-align: center;
          }
        }

        &:nth-child(3){
          display: none;

          @include media(md) {
            display: block;
            text-align: center;
          }
        }

        &:nth-child(4){
          text-align: center;

        }

        &:last-child{
          text-align: right;
        }
      }
    }

    & .contentBox{
      display: grid;
      grid-gap: 1.5rem;

      overflow-y: scroll;
      height: 60rem;
      scroll-padding-bottom: 0;
      grid-auto-rows: max-content;

      &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
      &::-webkit-scrollbar {width: 0.5rem;}
      &::-webkit-scrollbar-thumb {border-radius: 4px; background: #F7931A;}
      //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}


      & .contentBoxItem{
        display: grid;
        grid-auto-rows: max-content;
        grid-template-columns: repeat(3, 33.333%);
        align-items: center;
        justify-content: space-between;
        height: max-content;

        padding-right: 1.6rem;

        @include media(md) {
          grid-template-columns: repeat(5, 20%);
        }

        @include media(fullHD) {
          padding-right: 5.6rem;
        }

        &.colorGreen{
          & .contentBoxItemNameWrap{
            & .contentBoxItemNameBox{
              color: #03B759 !important;
            }
          }

          & .contentBoxItemValues{
            color: #03B759 !important;
          }
        }

        &.colorOrange{
          & .contentBoxItemNameWrap{
            & .contentBoxItemNameBox{
              color: #F7931A !important;
            }
          }

          & .contentBoxItemValues{
            color: #F7931A !important;
          }
        }

        &.colorViolet{
          & .contentBoxItemNameWrap{
            & .contentBoxItemNameBox{
              color: #b57edc !important;
            }
          }

          & .contentBoxItemValues{
            color: #b57edc !important;
          }
        }

        &.colorBlue{
          & .contentBoxItemNameWrap{
            & .contentBoxItemNameBox{
              color: #58c1ef !important;
            }
          }

          & .contentBoxItemValues{
            color: #58c1ef !important;
          }
        }

        &.colorYellow{
          & .contentBoxItemNameWrap{
            & .contentBoxItemNameBox{
              color: #FFFF00 !important;
            }
          }

          & .contentBoxItemValues{
            color: #FFFF00 !important;
          }
        }

        &.colorRed{
          & .contentBoxItemNameWrap{
            & .contentBoxItemNameBox{
              color: #FF060A !important;
            }
          }

          & .contentBoxItemValues{
            color: #FF060A !important;
          }
        }

        &.colorDark{
          & .contentBoxItemNameWrap{
            & .contentBoxItemNameBox{
              color: #BDB76B !important;
            }
          }

          & .contentBoxItemValues{
            color: #BDB76B !important;
          }
        }


        & .contentBoxItemNameWrap{
          display: grid;
          grid-template-columns: repeat(2, max-content);
          align-items: center;
          grid-gap: 1rem;

          & .contentBoxItemNameImgWrap{
            display: grid;
          }

          & .contentBoxItemNameBox{
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.9rem;

            color: #F5F5F5;
          }
        }

        & .contentBoxItemValues{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;

          &:nth-child(2){
            display: none;

            @include media(md) {
              display: block;
              text-align: center;
            }
          }

          &:nth-child(3){
            display: none;

            @include media(md) {
              display: block;
              text-align: center;
            }
          }

          &:nth-child(4){
            text-align: center;
          }

          &:last-child{
            text-align: right;
          }

          &.contentBoxItemValuesSpecial{
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.2rem;

            color: #03B759;
          }
        }
      }
    }
  }
}