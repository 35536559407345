@import "../../theme/scss/resources";

.container{
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;

  & .contentWrap{
    display: grid;
    border-radius: 1rem;
    border: 1px solid #F7931A;
    background: rgba(247, 147, 26, 0.05);
    backdrop-filter: blur(7px);
    height: 4.8rem;

    & .note{
      display: grid;
      align-items: center;
      justify-items: center;
      text-align: center;

      color: #F7931A;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      padding: 0 0.5rem;

      @include media(sm) {
        padding: 0;
      }

      & br{
        display: none;

        @include media(sm) {
          display: initial;
        }
      }


    }

    & .closeBtn{
      cursor: pointer;

      display: grid;
      align-items: center;
      justify-items: center;

      border-left: 1px solid #E80000;
    }
  }
}