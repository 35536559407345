@import "../../theme/scss/resources";

.container{

}

.authentication{
  display: grid;
  grid-auto-rows: max-content 1fr max-content;
  min-height: 100vh;

  background: #0d0a07 url("../../theme/assets/other/honee_main_bg.png") no-repeat;
  background-size: 300%;
  background-position: 85% 5%;

  @include media(sm) {
    background-size: 200%;
    background-position: 95% 5%;
  }

  @include media(lg) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }

  //@include media(xl) {
  //  background: #0d0a07 url("../../theme/assets/other/honee_main_bg.png") no-repeat;
  //  background-size: contain;
  //  background-position: initial;
  //}
  //
  //@include media(xxl) {
  //  //background-size: 100%;
  //}
}

.whitepaper{
  position: relative;
  min-height: 130rem;
  background: #0d0a07 url("../../theme/assets/other/honee_cabinet_bg.png") no-repeat;
  background-size: cover;
  //background: #0d0a07 url(http://localhost:3000/static/media/honee_cabinet_bg.52af0e1….png) no-repeat;
  //background: linear-gradient(138deg, rgba(201, 110, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%) !important;
  //backdrop-filter: blur(27px) !important;
}

.cabinet{
  min-height: 100vh;
  background: #0d0a07 url("../../theme/assets/other/honee_cabinet_bg.png") no-repeat;
  background-size: cover;

  & .posWrap{
    & .cabinetWrap{
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;

      @include media(lg) {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-gap: 2rem;
      }
    }
  }
}

.main{
  background: #0d0a07 url("../../theme/assets/other/honee_main_bg.png") no-repeat;
  background-size: 300%;
  background-position: 85% 5%;

  @include media(sm) {
    background-size: 200%;
    background-position: 95% 5%;
  }

  @include media(lg) {
    background-size: 100%;
    background-position: 100% 0;
  }

  @include media(xl) {
    background: #0d0a07 url("../../theme/assets/other/honee_main_bg.png") no-repeat;
    background-size: contain;
    background-position: initial;
  }

  @include media(xxl) {
    background-size: 100%;
  }
}

:global(.Toastify__toast){
  //background: #FFFFFF !important;

  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  line-height: 1.5rem !important;

  color: #F5F5F5 !important;
  border-radius: 0.8rem !important;

  background: rgba(15, 32, 52, 0.1) !important;
  border: 1px solid #FF8C00 !important;
  backdrop-filter: blur(7px) !important;

  & :global(.Toastify__close-button){
    color: #f48601 !important;
    opacity: 1 !important;
  }

  & :global(.Toastify__progress-bar){
    background: #f48601 !important;
  }
}