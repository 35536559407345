@import "../../theme/scss/resources";

.container{
  @include pagePadding;
  padding-top: 1.5rem !important;

  @include media(md) {
    padding-top: 3rem !important;
  }

  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 4rem;

      color: #58C1EF;

      margin-bottom: 1.75rem;

      text-align: center;

      @include media(md) {
        text-align: left;

        font-size: 3.8rem;
        line-height: 5.2rem;

        margin-bottom: 3.5rem;
      }
    }

    & .inputsBox{
      display: grid;
      grid-gap: 2rem;

      max-width: 30rem;
      margin: 0 auto;

      @include media(md) {
        margin: initial;
      }

      & .inputWrap{

        & .inputTitle{
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;

          margin-bottom: 0.75rem;
        }

        & input{
          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #58C1EF;
          backdrop-filter: blur(7px);

          border-radius: 1rem;

          height: 4.8rem;
          width: 100%;
          max-width: 30rem;

          font-size: 14px;
          line-height: 19px;

          color: #F5F5F5;
          padding: 0 1.5rem;
        }
      }
    }

    & .links{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

      color: #F5F5F5;

      padding-top: 3rem;
      padding-bottom: 3rem;

      text-align: center;

      @include media(md) {
        text-align: left;
      }

      & span{
        cursor: pointer;
        color: #58C1EF;
        border-bottom: 1px solid #58C1EF;
        margin-left: 0.25rem;
      }
    }

    & .mainBtn{
      cursor: pointer;

      background: #58C1EF;
      border-radius: 1rem;

      text-align: center;

      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #F5F5F5;

      max-width: 30rem;
      margin: 0 auto;

      padding: 1.25rem 0;

      @include media(md) {
        margin: initial;
      }
    }
  }
}