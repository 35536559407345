@import "../../theme/scss/resources";

.timerWrap{
  & .timerTitle{
    color: #FFD600;

    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @include media(md) {
      font-size: 2.1rem;
    }
  }

  & .timerBox{
    margin: 1rem 0;
    padding: 2rem 1rem;
    border-radius: 1rem;
    border: 1px solid #FFD600;

    background: rgba(255, 214, 0, 0.10);

    color: #FFFFFF;
    font-size: 2rem;

    @include media(md) {
      font-size: 3.4rem;
    }
  }
}