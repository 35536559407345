@import "../../../theme/scss/resources";

.container{
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  grid-gap: 1rem;
  justify-content: right;

  @include media(xl) {
    justify-content: center;
  }

  & .stockWrap{
    & .stock{
      display: grid;
      align-items: center;
      justify-items: center;

      @include media(xl) {
        grid-template-columns: max-content max-content;
        grid-gap: 0.5rem;
      }

      & .stockIcon{
        display: grid;
      }

      & .stockNameWrap{
        & .stockName{
          display: none;
          @include media(xl) {
            display: block;
          }
        }

        & .stockCategory{
          display: none;
          font-size: 1rem;
          color: #00FFFF;
          text-transform: uppercase;
          text-align: left;

          @include media(xl) {
            display: block;
          }
        }
      }


    }

    & .category{
      display: block;
      font-size: 1rem;
      color: #00FFFF;
      text-transform: uppercase;

      @include media(xl) {
        display: none;
      }
    }
  }



  & .separator{
    display: grid;
  }
}