@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    display: grid;
    grid-row: 3 / 5;
    grid-column: 1;
  }

  & .honeeCardBody{
    border: 1px solid #FFD600;
    border-radius: 1rem;

    & .cardBodyWithScroll{
      display: grid;

      & .titleRow{
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        padding-right: 2rem;

        @include media(fullHD) {
          padding-right: 3rem;
        }

        & .title2{
          font-size: 2.4rem;
          line-height: 2.9rem;

          margin-bottom: 0;

          @include media(lg) {
            font-size: 4rem;
            line-height: 5rem;
          }



          & span{
            color: #FFD600;
            font-size: 2.4rem;
            line-height: 2.9rem;

            @include media(lg) {
              font-size: 4rem;
              line-height: 5rem;
            }
          }
        }

        & .questionIconWrap{
          cursor: pointer;
          display: grid;
        }
      }

      & .graphWrap{

        @include media(sm) {
          padding-right: 2rem;
        }

        @include media(fullHD) {
          padding-right: 3rem;
        }

        & .graphBox{
          width: 100%;
          height: 30rem;
          position: relative;
          overflow: hidden;

          @include media(sm) {
            height: 34rem;
          }

          & .absData{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);

            display: grid;
            grid-auto-rows: max-content;
            align-items: center;
            justify-items: center;
            align-content: center;

            width: 11rem;
            height: 11rem;

            border-radius: 50rem;
            border: 2.115px solid #FFD600;

            background: rgba(255, 214, 0, 0.10);

            box-shadow: 0 0 44.42px 0 rgba(0, 0, 0, 0.07);

            @include media(xs) {
              width: 14rem;
              height: 14rem;
            }

            @include media(sm) {
              width: 16rem;
              height: 16rem;
            }

            & .absDataTitle{
              color: #FFD600;

              text-align: center;
              font-size: 2.2rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.458px;
            }

            & .absDataValue{
              color: #FFD600;

              text-align: center;
              font-size: 2.2rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.458px;
            }
          }
        }

        & .graphLoad{
          display: grid;
          align-items: center;
          align-content: center;
          justify-items: center;
          justify-content: center;

          width: 100%;
          height: 30rem;

          @include media(sm) {
            height: 34rem;
          }

          & .graphLoadText{
            color: #F5F5F5;

            font-size: 3rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            text-align: center;
          }
        }
      }

      & .listWrap{
        & .listTitleRow{
          display: grid;
          grid-template-columns: 1fr 1fr;
          justify-content: space-between;

          margin-bottom: 2rem;
          padding-right: 2rem;


          @include media(fullHD) {
            padding-right: 3rem;
          }

          & .title6{
            margin-bottom: 0;

            &:last-child{
              text-align: right;
            }
          }
        }

        & .listBox{
          display: grid;
          grid-auto-rows: max-content;
          grid-gap: 1.5rem;

          overflow-y: scroll;
          height: 17.8rem;
          scroll-padding-bottom: 0;

          &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
          &::-webkit-scrollbar {width: 0.5rem;}
          &::-webkit-scrollbar-thumb {border-radius: 4px; background: #d9d9d9;}

          & .listBoxItem{
            height: max-content;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            grid-gap: 1rem;

            padding-right: 1.6rem;

            @include media(fullHD) {
              padding-right: 2.6rem;
            }

            & .cryptoBox{
              display: grid;
              grid-template-columns: max-content max-content;
              align-items: center;
              grid-gap: 1.5rem;

              & .iconWrap{
                display: grid;
              }

              & .nameWrap{
                & .name{
                  color: #F5F5F5;

                  font-size: 1.5rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                & .percent{
                  position: relative;
                  color: #F5F5F5;

                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding-left: 0.75rem;

                  & span{
                    position: absolute;
                    top: -1.2rem;
                    left: -0.75rem;
                    font-size: 3rem;
                  }
                }
              }
            }

            & .infoBox{

              & .rate{
                text-align: right;
                color: #F5F5F5;

                font-size: 1.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              & .change{
                text-align: right;
                color: #F5F5F5;

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}