@import "../../theme/scss/resources";

.container{
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 2rem;

  & .contentWrap{
    display: grid;
    grid-gap: 2rem;

    @include media(xl) {
      grid-template-columns: 1fr 42.7rem;
    }

    @include media(fullHD) {
      grid-template-columns: 1fr 53rem;
    }
  }
}