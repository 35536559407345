@import "../../theme/scss/resources";

//:global(.echarts-for-react) {
//  width: 100% !important;
//  height: 100% !important;
//}

.container{
  & .honeeCardBody{
    display: grid;
    grid-template-rows: 1fr 21rem;
    height: 31.5rem;
    overflow: hidden;

    padding: 3.5rem 0 0 0 !important;

    @include media(md) {
      height: 27.5rem;
    }

    @include media(xl) {
      height: 100%;
    }

    & .cardHeader {
      padding-bottom: 0;

      & .titleRow{
        display: grid;
        align-items: center;
        grid-gap: 1.5rem;

        padding: 0 3.5rem;

        @include media(md) {
          grid-template-columns: max-content max-content;
          grid-gap: 2.5rem;
        }

        @include media(lg) {
          grid-template-columns: auto;
          grid-auto-flow: column;
          justify-content: space-between;
        }

        & .filterWrap{
          width: max-content;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1rem;

          @include media(sm) {
            grid-gap: 1.5rem;
          }

          @include media(md) {
            width: auto;
          }

          @include media(lg) {
            grid-gap: 1rem;
          }

          @include media(xl) {
            grid-gap: 1.5rem;
          }

          & .filterItem{
            display: grid;
            align-items: center;

            cursor: pointer;
            width: 7rem;
            height: 3.2rem;

            color: #FFFFFF;

            text-align: center;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            border: 1px solid #8C9497;
            border-radius: 1rem;

            @include media(sm) {
              width: 12rem;
            }

            @include media(lg) {
              width: 8rem;
            }

            @include media(xl) {
              width: 12rem;
            }

            &.filterItemActive{
              border: 1px solid #F7931A !important;
              background: rgba(247, 147, 26, 0.10) !important;
            }
          }
        }

        & .title3{
          margin-bottom: 0;
        }
      }
    }

    & .cardBody{
      padding: 0;
      //height: max-content;

      & .chartWrap{
        //height: max-content;

        & .soonNote{
          display: grid;
          align-items: center;
          justify-items: center;
          height: 21rem;

          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 2.5rem;
          color: #8C9497;
        }
      }
    }
  }
}