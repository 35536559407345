@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

:global(.modalWithoutP-overlay) {
  :global(.popup-content) {
    padding: 0 !important;
  }
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .noteWrap{
      & .note{
        color: #F5F5F5;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    & .tableContent{
      border: 1px solid #FF8C00;
      margin-top: 1.5rem;

      & .titleRow{
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid #FF8C00;

        & .titleRowItem{
          color: #FFFFFF;
          text-align: center;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          padding: 1rem 0.5rem;

          &:first-child{
            border-right: 1px solid #FF8C00;
          }
        }
      }

      & .tableWrap{
        & .loader{
          display: grid;
          align-items: center;
          justify-items: center;
          text-align: center;

          height: 31.4rem;

          color: #FFFFFF;
          font-size: 3rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        & .tableRow{
          display: grid;
          grid-template-columns: 1fr 1fr;
          border-bottom: 1px solid #FF8C00;

          &:last-child{
            border-bottom: none;
          }

          & .tableRowItem{
            color: #FFFFFF;
            text-align: center;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            padding: 1rem 0.5rem;

            &:first-child{
              border-right: 1px solid #FF8C00;
            }
          }
        }
      }
    }
  }
}