@import "../../theme/scss/resources";

.container{
  & .honeeCardBody {
    display: grid;
    grid-gap: 3rem;

    @include media(xl) {
      grid-template-columns: max-content max-content;
      justify-content: space-between;
    }

    & .infoBox{
      & .titleWSF{
        margin-bottom: 1rem;
      }

      & .infoValue{
        display: grid;
        align-items: center;
        justify-items: center;
        text-align: center;
        height: 5.3rem;

        color: #F5F5F5;

        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        border-radius: 1rem;
        border: 1px solid #FFC200;

        background: rgba(255, 194, 0, 0.10);
      }
    }

    & .mainWrap{
      display: grid;
      grid-gap: 1.5rem;

      & .titleRow{
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-gap: 1rem;
        align-items: center;

        & .imgWrap{
          display: grid;

          & img{
            width: 4rem;
          }
        }

        & .mainT{
          font-size: 3rem;
        }

        & .title2{
          margin-bottom: 0;
        }
      }

      & .contentRow{
        display: grid;
        grid-gap: 1.5rem;

        & .contentRowNote{
          color: #F5F5F5;

          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @include media(xl) {
            max-width: 38rem;
          }
        }

        & .contentRowInfo{
          display: grid;
          grid-gap: 1rem;

          @include media(sm) {
            grid-template-columns: max-content max-content;
            grid-gap: 3.5rem;
          }
        }
      }
    }

    & .otherWrap{
      display: grid;
      grid-gap: 1.5rem;

      & .otherTitleRow{
        & .title2{
          margin-bottom: 0;
        }
      }

      & .otherRowInfo{
        display: grid;
        column-gap: 6rem;
        row-gap: 1rem;

        @include media(sm) {
          grid-template-columns: max-content max-content;
        }
      }
    }
  }
}