@import "../../../theme/scss/resources";

.container{
  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #F5F5F5;
      margin-bottom: 2rem;
    }

    & .contentBox{
      display: grid;
      grid-gap: 2rem;

      @include media(md) {
        grid-template-columns: 33.9rem 1fr;
      }

      & .previewWrap{
        display: grid;

        & img{
          width: 100%;

          @include media(md) {
            height: 100%;
          }
        }
      }

      & .navigationWrap{
        display: grid;
        grid-gap: 1rem;

        & .navigationRow{
          position: relative;

          & .navigationRowTitle{
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #F5F5F5;
            margin-bottom: 0.5rem;
          }

          & input {
            background: #1E363F;
            border: 1px solid #58C1EF;
            border-radius: 1rem;

            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #F5F5F5;

            padding: 1.2rem;
            width: 100%;

            @include media(xs) {
              padding: 2.2rem 1.2rem;
            }

            @include media(md) {
              padding: 1rem 1.2rem;
            }
          }

          & .inputWithErr{
            border: 1px solid #CF0000 !important;
          }

          & .inputErr{
            position: absolute;
            right: 0;
            bottom: -1.5rem;

            color: #CF0000;
            font-style: normal;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.25rem;
          }

          & .navigationRowBox{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0.6rem;

            @include media(md) {
              grid-gap: 1rem;
            }

            &.navigationRowBoxColored{
              & :first-child{
                background-color: #008FCE;
              }

              & :nth-child(2){
                background-color: #F7931A;
              }

              & :last-child{
                background-color: #CF0000;
              }
            }

            & .navigationRowBoxItem{
              cursor: pointer;
              position: relative;

              display: grid;
              height: 4.27rem;

              border-radius: 1rem;
              overflow: hidden;

              @include media(xs) {
                height: 6.245rem;
              }

              @include media(md) {
                height: 3.8rem;
              }

              &.navigationRowBoxItemActiveC{
                border: 1px solid #F5F5F5;
                &:after{
                  content: '';
                  margin: auto;
                  position: absolute;
                  top: 0; left: 0; bottom: 0; right: 0;

                  width: 1.44rem;
                  height: 1.1rem;

                  background: url("../../../theme/assets/svg/omt_check_icon_white.svg");
                }
              }

              &.navigationRowBoxItemActiveT{
                border: 1px solid #58c1ef;
                &:after{
                  content: '';
                  margin: auto;
                  position: absolute;
                  top: 0; left: 0; bottom: 0; right: 0;

                  width: 1.44rem;
                  height: 1.1rem;

                  background: url("../../../theme/assets/svg/omt_check_icon_blue.svg");
                }
              }

              & img{
                width: 100%;
                height: 4.27rem;

                @include media(xs) {
                  height: 6.245rem;
                }

                @include media(md) {
                  height: 3.8rem;
                }
              }
            }
          }
        }
      }
    }

    & .btn{
      cursor: pointer;

      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.2rem;

      color: #58C1EF;

      padding: 1.3rem 0;
      text-align: center;

      background: #1C343E;
      border: 1px solid #58C1EF;
      border-radius: 1rem;

      max-width: 22.7rem;
      margin:  0 auto;
      margin-top: 3.5rem;
    }
  }
}