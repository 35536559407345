@import "../../../theme/scss/resources";

.logoWrap{
  display: grid;
  cursor: pointer;
  width: max-content;

  &.logoWrapCabinet{
    @include media(lg) {
      align-self: center;

    }

    & img{

      @include media(lg) {
        position: relative;
        top: -2.25rem;
      }
    }
  }
}