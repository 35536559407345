@import "../../theme/scss/resources";

.container{
  & .contentWrap{
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    height: 100%;

    & .titleWSF{
      margin-bottom: 1.5rem;
    }

    & .contentBox{
      display: grid;
      grid-gap: 2rem;

      @include media(sm) {
        grid-template-columns: 1fr 1fr;
        align-items: start;
        grid-gap: 2rem;
      }

      & .contentBoxItem{
        & .contentBoxItemTitle{
          color: #FFFFFF;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-bottom: 0.75rem;
        }

        & .contentBoxItemTotal{
          display: grid;
          grid-template-columns: max-content 1fr;
          align-items: center;
          grid-gap: 1rem;

          & .imgWrap{
            display: grid;
          }

          & .value{
            color: #FF8C00;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    & .button{
      margin-top: 5rem;

      @include media(xl) {
        margin-top: 0;
        align-self: end;
      }
    }
  }
}