@mixin media($breakpoint) {
  @if $breakpoint == xxs {
    @media only screen and (min-width: 320px) {
      @content;
    }
  }

  @if $breakpoint == xs {
    @media only screen and (min-width: 420px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (min-width: 1020px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media only screen and (min-width: 1350px) {
      @content;
    }
  }

  @if $breakpoint == xxl {
    @media only screen and (min-width: 1440px) {
      @content;
    }
  }

  @if $breakpoint == fullHD {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }
}
