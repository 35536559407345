@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    display: grid;
    grid-gap: 2rem;

    & .titleRow{
      display: grid;
      align-items: center;
      grid-gap: 1.75rem;

      @include media(md) {
        grid-template-columns: max-content max-content;
      }

      @include media(xl) {
        grid-template-columns: max-content max-content 1fr;
      }

      & .title3{
        margin-bottom: 0;
      }

      & .available{
        color: #00FF7A;

        text-align: center;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 1rem 1.5rem;

        border: 1px solid #00FF7A;
        border-radius: 1rem;
      }

      & .tableBtn{
        color: #F5F5F5;


        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include media(sm) {
          grid-column: 1 / 3;
        }

        @include media(xl) {
          grid-column: auto;
          text-align: right;
        }

        & span{
          cursor: pointer;
          color: #F7931A;
          text-decoration-line: underline;
        }
      }
    }

    & .contentBox{
      display: grid;
      grid-gap: 2rem;

      @include media(md) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include media(xl) {
        grid-gap: 8rem;
      }

      & .contentBoxItem{
        &:first-child{
          & .contentBoxItemName{
            color: #F7931A;
          }
          & .contentBoxItemValue{
            border: 1px solid #F7931A;
            background: linear-gradient(138deg, rgba(247, 147, 26, 0.15) -23.04%, rgba(247, 147, 26, 0.01) 118.95%);
          }
          & .contentBoxItemBtn{
            border: 1px solid #F7931A;
            color: #F7931A;
          }
        }

        &:last-child{
          & .contentBoxItemName{
            color: #58C1EF;
          }
          & .contentBoxItemValue{
            border: 1px solid #58C1EF;
            background: linear-gradient(138deg, rgba(88, 193, 239, 0.15) -23.04%, rgba(88, 193, 239, 0.01) 118.95%);
          }
          & .contentBoxItemBtn{
            border: 1px solid #58C1EF;
            color: #58C1EF;
          }
        }

        & .contentBoxItemName{
          display: grid;
          align-items: end;

          color: #FFC200;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @include media(md) {
            height: 7.2rem;
          }

          @include media(fullHD) {
            height: 4.8rem;
          }
        }

        & .contentBoxItemValue{
          display: grid;
          align-items: center;

          height: 8rem;
          background: linear-gradient(138deg, rgba(255, 194, 0, 0.15) -23.04%, rgba(255, 194, 0, 0.01) 118.95%);

          border-radius: 1rem;
          border: 1px solid #FFC200;

          color: #F5F5F5;

          text-align: center;
          font-size: 3.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-top: 1.25rem;
          margin-bottom: 2rem;
        }

        & .contentBoxItemBtn{
          cursor: pointer;

          display: grid;
          align-items: center;

          height: 4.8rem;

          border-radius: 1rem;
          border: 1px solid #FFC200;

          color: #FFC200;

          text-align: center;
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}