@import "../../theme/scss/resources";

.container{
  & .contentWrap{
    display: grid;
    grid-gap: 1rem;

    @include media(sm) {
      grid-template-columns: repeat(3, max-content);
      grid-gap: 2rem;
    }

    & .contentWrapItem{
      display: grid;
      align-items: center;

      cursor: pointer;
      height: 4rem;

      color: #F5F5F5;

      text-align: center;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      border: 1px solid #8C9497;
      border-radius: 1rem;
      padding: 0 1rem;

      &.contentWrapItemActive{
        border: 1px solid #F7931A;
      }
    }
  }
}