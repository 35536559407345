@import "../../theme/scss/resources";

.container{
  height: 0;
  overflow: hidden;
  grid-column: 1 / 3;
  display: none;

  @include media(xl) {
    order: 2;
  }
  & .card{
    &.cardOrange{
      border: 1px solid #F7931A;
      background: linear-gradient(138deg, rgba(255, 194, 0, 0.15) -23.04%, rgba(255, 194, 0, 0.01) 118.95%);
      backdrop-filter: blur(1rem);
    }
    & .cardBody{
      display: grid;
      grid-gap: 2rem;

      @include media(xl) {
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        align-items: center;
      }

      //@include media(xl) {
      //  grid-template-columns: max-content max-content max-content;
      //  justify-content: space-between;
      //}

      & .textWrap{
        & .titleBox{
          display: grid;
          grid-template-columns: max-content 1fr;
          grid-gap: 1rem;
          align-items: center;

          & .imgWrap{
            display: grid;
          }

          & .text{
            color: #FFC200;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @include media(sm) {
              font-size: 3rem;
            }
          }
        }

        & .linkBox{
          position: relative;
          cursor: pointer;

          color: #F5F5F5;
          font-size: 1.1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;

          padding-left: 5rem;

          width: max-content;

          @include media(sm) {
            font-size: 1.4rem;
          }

          & .imgWrap{
            display: grid;
            position: absolute;
            right: -2rem;
            top: 0.1rem;

            @include media(sm) {
              top: 0.4rem;
            }
          }
        }
      }

      & .barWrap{
        & .botLevel{
          display: grid;
          align-items: center;
          justify-items: center;

          position: relative;

          height: 5rem;
          width: 100%;
          border: 1px solid #8C9497;
          border-radius: 0.8rem;
          background: rgba(3, 183, 89, 0.1);

          //@include media(sm) {
          //  width: 27rem;
          //}

          &.botLevelOrange{
            border: 1px solid #F7931A;
            background: rgba(255, 194, 0, 0.15) -23.04%;
            //background: linear-gradient(138deg, rgba(255, 194, 0, 0.15) -23.04%, rgba(255, 194, 0, 0.01) 118.95%);
            //backdrop-filter: blur(1rem);

            & .botLevelBar{
              background: #F7931A;
            }
          }

          & .botLevelBar{
            position: absolute;
            left: -0.1rem;
            top: -0.1rem;
            height: 5rem;
            background: #8C9497;
            border-radius: 0.8rem;
            z-index: 10;
          }

          & .botLevelText{
            position: relative;
            z-index: 20;

            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.4rem;
            text-align: center;

            color: #FFFFFF;
          }
        }
      }
    }
  }
}