@import "../../theme/scss/resources";

.container{
  display: grid;
  & .card{
    display: grid;

    & .cardBody{
      display: grid;
      grid-template-rows: max-content 1fr;

      & .positionWrap{
        display: grid;
        grid-gap: 1.5rem;

        & .title{
          font-style: normal;
          font-weight: 400;
          font-size: 2.4rem;
          line-height: 3.3rem;

          color: #F5F5F5;
          margin-bottom: 0.5rem;
        }

        & .inputTitle,
        & .areaTitle,
        & .fileTitle{
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.9rem;

          color: #F5F5F5;
          margin-bottom: 0.8rem;
        }

        & .inputsWrap{
          display: grid;
          grid-gap: 1.5rem;

          & .inputBox{
            position: relative;

            &.inputWrapWithErr{
              & input{
                border: 1px solid #CF0000 !important;
              }
            }
            & input{
              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 0.5rem;
              height: 4.8rem;
              width: 100%;
              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.2rem;

              color: #F5F5F5;
              padding: 1.3rem;

              &::-webkit-input-placeholder { color: #8C9497; }
              &::-moz-placeholder { color: #8C9497; }
              &:-ms-input-placeholder { color: #8C9497; }
              &:-moz-placeholder { color: #8C9497; }
            }

            & .inputErr{
              position: absolute;
              right: 0;
              bottom: -1.5rem;

              color: #CF0000;
              font-style: normal;
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 1.25rem;
            }

            & .fakeInput{
              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 0.5rem;
              height: 4.8rem;

              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.2rem;

              color: #F5F5F5;
              padding: 1.3rem;
            }
          }
        }

        & .areaWrap{
          & .areaBox{
            position: relative;
            &.inputWrapWithErr{
              & textarea{
                border: 1px solid #CF0000 !important;
              }
            }

            & textarea{
              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 0.5rem;
              height: 24.6rem;
              width: 100%;
              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.2rem;

              color: #F5F5F5;
              padding: 1.3rem;
              resize: none;
            }

            & .inputErr{
              position: absolute;
              right: 0;
              bottom: -1.5rem;

              color: #CF0000;
              font-style: normal;
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 1.25rem;
            }
          }
        }

        & .fileWrap{
          & .fileBox{

            & .posWrap{
              display: grid;
              grid-gap: 1.5rem;

              @include media(md) {
                grid-template-columns: 1fr 19rem;
                grid-gap: 3.4rem;
                max-width: 48.6rem;
              }

              & .fakeInput{
                background: rgba(15, 32, 52, 0.1);
                border: 1px solid #FF8C00;
                backdrop-filter: blur(7px);
                border-radius: 0.5rem;
                height: 4.8rem;

                font-style: normal;
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 2.2rem;

                color: #F5F5F5;
                padding: 1.3rem;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              & input{
                display: none;
              }

              & .button{
                margin: 0;
              }
            }
          }
        }

        & .button{
          margin-bottom: 3.25rem;
          margin-top: 2.5rem;

          @include media(md) {
            max-width: 19rem;
            margin-bottom: 7.5rem;
            margin-top: 5rem;
          }
        }
      }

      & .textWrap{
        display: grid;
        align-content: end;
        align-self: end;

        font-style: normal;
        font-weight: 400;
        font-size: 1.75rem;
        line-height: 2.5rem;

        color: #F5F5F5;
        padding-top: 1.75rem;
        border-top: 1px solid #D9D9D9;

        @include media(md) {
          font-size: 2.4rem;
          line-height: 3.3rem;
          padding-top: 3.5rem;
        }
      }
    }
  }
}