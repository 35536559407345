@import "../../theme/scss/resources";

.container{
  //@include walletPadding;
  //
  //padding-top: 4.5rem;
  //padding-bottom: 4.5rem;
  //
  //background: #031319;
  //height: 100%;
  //min-height: 100rem;

  display: grid;
  grid-auto-rows: max-content;

  & .contentWrap{
    display: grid;
    grid-gap: 2rem;

    @include media(md) {
      grid-template-columns: max-content 1fr;
    }

    @include media(xl) {
      grid-template-columns: 1fr 42.7rem;
    }

    @include media(fullHD) {
      grid-template-columns: 1fr 53rem;
    }
  }
}