@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin-bottom: 1.25rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin-bottom: 2.5rem;
      }
    }

    & .modalContentBox {
      display: grid;
      grid-gap: 3rem;

      @include media(md) {
        grid-template-columns: 1fr 23.2rem;
        grid-gap: 6rem;
      }

      & .settingsWrap{
        min-height: 23.5rem;

        & .settingsBox{
          display: grid;
          grid-gap: 1rem;

          & .settingsItem{
            cursor: pointer;

            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            align-content: center;

            border-radius: 1rem;
            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            padding: 1rem;

            &.settingsItemPassive{
              cursor: initial !important;
            }

            & .settingsItemNote, & .settingsItemArrow{
              color: #F5F5F5;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        & .dropBox{
          display: grid;
          grid-gap: 1rem;

          & .dropTitle{
            cursor: pointer;

            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            align-content: center;

            border-radius: 1rem;
            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);

            color: #F5F5F5;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            padding: 1rem;

            & .dropTitleBack{
              display: grid;
              grid-template-columns: max-content max-content;
              align-items: center;
              grid-gap: 1rem;

              & .dropTitleBackImgWrap{
                display: grid;
              }

              & .dropTitleBackNote{

              }
            }

            & .dropTitleArrow{
              transform: rotate(180deg);
            }
          }

          & .dropItem{
            cursor: pointer;

            border-radius: 1rem;
            border: 1px solid #58C1EF;
            background: #0e1b1b;

            color: #F5F5F5;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            padding: 1rem;

            &.green{
              border: 1px solid #03b759 !important;
              color: #03b759 !important;
            }

            &.orange{
              border: 1px solid #f7931a !important;
              color: #f7931a !important;
            }

            &.red{
              border: 1px solid #c61e36 !important;
              color: #c61e36 !important;
            }
          }
        }
      }
      
      & .beeWrap{
        height: 22rem;
        @include media(md) {
          height: initial;
        }
        & .imgWrap{
          display: grid;
          position: relative;

          & img{
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
}