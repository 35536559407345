@import "../../theme/scss/resources";

.container{
  overflow: hidden;

  & .contentWrap{
    @include media(lg) {
      display: grid;
      grid-template-columns: 1fr max-content;
      column-gap: 7rem;
    }

    @include media(xl) {
      column-gap: 10rem;
    }

    @include media(fullHD) {
      column-gap: 14.4rem;
    }
  }
}