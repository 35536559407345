@import "../../theme/scss/resources";

.container{
  height: 100%;
  display: grid;
  min-height: inherit;

  @include pagePadding;
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;

  @include media(md) {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  @include media(lg) {
    padding-left: 42rem !important;
  }

  @include media(fullHD) {
    padding-left: 58rem !important;
  }

  & .contentWrap{
    min-height: 110rem;

    & .title1{
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 3rem;
    }

    & .update{
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 3.6rem;

      color: #F7931A;
      margin-bottom: 4rem;
    }

    & .contentBox{
      display: grid;
      grid-gap: 4rem;

      & .contentBoxItem{
        display: grid;
        grid-gap: 2.5rem;

        & .bolded{
          font-weight: 600;
        }

        & .link{
          cursor: pointer;
          color: #F7931A
        }

        & .paragraphBox{
          & .paragraphTitle{
            font-style: normal;
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 3.6rem;

            color: #FFFFFF;
            margin-bottom: 1.5rem;
            text-transform: uppercase;
          }

          & .paragraphSubTitle{
            font-style: normal;
            font-weight: 700;
            font-size: 2.8rem;
            line-height: 3.2rem;

            color: #FFFFFF;
            margin-bottom: 1.5rem;
            //text-transform: uppercase;
          }

          & .paragraph{
            font-style: normal;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.5rem;

            color: #FFFFFF;
          }

          & .paragraphList{
            display: grid;
            grid-gap: 0.5rem;
            margin-top: 1.5rem;

            &.paragraphListLetter{
              & .paragraphListItem{
                padding-left: 5rem !important;
                & .absMarker{
                  position: absolute;
                  left: 0;
                  top: 0.2rem;
                }
                &:before{
                  display: none !important;
                }
              }
            }

            & .paragraphListItem{
              position: relative;

              padding-left: 2rem;
              font-style: normal;
              font-weight: 400;
              font-size: 1.8rem;
              line-height: 2.5rem;

              color: #FFFFFF;

              &:before{
                position: absolute;
                left: 0;
                top: 0.1rem;

                content: '•';
                font-style: normal;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.5rem;

                color: #FFFFFF;
              }
            }
          }

          & .imgWrap{
            display: grid;
            width: 100%;
            //border: 1px solid #58C1EF;

            & img{
              width: 100%;
            }
          }

          & .paragraphTable{
            border: 1px solid #FFFFFF;

            & .paragraphTableTitleRow{
              display: grid;
              grid-template-columns: 1fr 1.5fr;
              border-bottom: 1px solid #FFFFFF;

              @include media(sm) {
                grid-template-columns: repeat(2, 1fr);
              }

              @include media(xl) {
                grid-template-columns: repeat(4, 1fr);
              }

              & .paragraphTableTitleRowItem{
                font-style: normal;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 2.5rem;

                padding: 2rem;

                color: #FFFFFF;

                border-right: 1px solid #FFFFFF;

                @include media(fullHD) {
                  font-size: 1.8rem;
                }

                &:nth-child(2), &:nth-child(3){
                  display: none;

                  @include media(xl) {
                    display: initial;
                  }
                }

                &:last-child{
                  border-right: none;
                }

                & .paragraphTableTitleRowItemM{
                  @include media(sm) {
                    display: none;
                  }
                }

                & .paragraphTableTitleRowItemD{
                  display: none;

                  @include media(sm) {
                    display: initial;
                  }
                }
              }
            }

            & .paragraphTableBox{
              & .paragraphTableBoxRow{
                display: grid;
                grid-template-columns: 1fr 1.5fr;
                border-bottom: 1px solid #FFFFFF;

                @include media(sm) {
                  grid-template-columns: repeat(2, 1fr);
                }

                @include media(xl) {
                  grid-template-columns: repeat(4, 1fr);
                }

                &:last-child{
                  border-bottom: none;
                }

                & .paragraphTableBoxRowItem{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.5rem;
                  line-height: 2.5rem;

                  padding: 1rem 2rem;

                  color: #FFFFFF;

                  border-right: 1px solid #FFFFFF;

                  @include media(fullHD) {
                    font-size: 1.8rem;
                  }

                  &:nth-child(2), &:nth-child(3){
                    display: none;

                    @include media(xl) {
                      display: initial;
                    }
                  }

                  &:last-child{
                    border-right: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}