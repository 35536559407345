@import "../../theme/scss/resources";

.container{
  border: 1px solid #FFD600 !important;

  @include media(xl) {
    display: grid;
    grid-row: 1;
    grid-column: 1 / 3;
  }

  & .honeeCardBody {

    & .contentWrap{
      text-align: center;

      color: #FF0;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem;
      text-transform: uppercase;

      @include media(xl) {
        font-size: 2rem;
      }
    }
  }
}