@import "../../theme/scss/resources";

.container{
  height: 28.5rem;
  overflow: hidden;
  grid-column: 1 / 3;

  @include media(md) {
    grid-column: 2;
  }

  @include media(xl) {
    display: grid;
    grid-column: auto;
    order: 6;
    align-self: end;
  }

  @include media(xxl) {
    align-self: end;
  }

  & .honeeCardBody{
    height: 100%;
    & .cardBody{
      & .titleWSF{
        margin-bottom: 2rem;
      }

      & .rowWrap{
        display: grid;
        grid-gap: 2rem;

        & .rowItem{
          & .rowItemTitle{
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.1rem;

            color: #F5F5F5;
            margin-bottom: 0.5rem;
          }

          & .rowItemFakeInputWrap{
            display: grid;
            grid-gap: 1.5rem;
            grid-template-columns: 1fr 12rem;

            & .rowItemFakeInput{
              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 1rem;
              height: 4.8rem;
              display: grid;
              align-items: center;

              position: relative;
              padding: 1.25rem 5rem 1.25rem 1.25rem;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 2.1rem;

              color: #F5F5F5;


              & .rowItemFakeInputAbsInfo{
                position: absolute;
                right: 1.25rem;
                top: 1.25rem;

                color: #F7931A !important;
              }
              & .rowItemFakeInputAbsInfo.rowItemFakeInputAbsInfoInfluencer {
                color: #FD00D5 !important;
              }
            }
            & .rowItemFakeInputCopyBtnInfluencer{
              background: #FD00D5!important;
            }
            & .rowItemFakeInputCopyBtn{
              cursor: pointer;

              display: grid;
              align-items: center;
              text-align: center;

              width: 100%;
              height: 4.8rem;

              background: #F7931A;
              border: 1px solid transparent;
              border-radius: 1rem;

              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.7rem;

              color: #F5F5F5;

              &.rowItemFakeInputCopyBtnCopied{
                border: 1px solid #F7931A !important;
                background: transparent !important;
                color: #F7931A !important
              }
              &.rowItemFakeInputCopyBtnCopied.rowItemFakeInputCopyBtnInfluencer{
                border: 1px solid #FD00D5 !important;
                background: transparent !important;
                color: #FD00D5 !important
              }
            }
          }
        }
      }
    }
  }
}