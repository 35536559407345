@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    display: grid;
    grid-row: 5;
    grid-column: 1;
  }

  & .honeeCardBody{
    display: grid;

    border: 1px solid #FFD600;
    border-radius: 1rem;

    & .contentWrap{
      display: grid;
      grid-gap: 2.75rem;

      & .contentWrapItem{
        display: grid;
        grid-gap: 1rem;

        @include media(xs) {
          grid-template-columns: max-content 1fr;
          align-items: center;
        }

        & .contentWrapItemName{
          color: #FFFFFF;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
        }

        & .contentWrapItemValue{
          display: grid;
          align-items: center;
          text-align: center;

          height: 3.4rem;

          border-radius: 0.8rem;
          border: 1px solid #FFD600;

          background: rgba(88, 193, 239, 0.05);

          color: #FFD600;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
        }
      }
    }
  }
}