@import "../../theme/scss/resources";

.container{
  & .contentWrap{
    & .botLogoWrap{
      display: grid;
      margin-bottom: 2rem;

      @include media(lg) {
        margin-bottom: 0;
      }
    }

    & .contentBox{
      display: grid;
      grid-gap: 3rem;

      @include media(lg) {
        grid-template-columns: 1.25fr 1fr;
      }

      @include media(xl) {
        grid-template-columns: 1.5fr 1fr;
      }

      & .infoRow{
        align-self: end;

        & .infoRowNote{
          color: #FFFFFF;

          font-size: 1.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        & .infoRowSubscription{
          display: grid;
          grid-gap: 1rem;

          margin-top: 1.6rem;

          @include media(xs) {
            grid-template-columns: max-content 1fr;
            grid-auto-flow: column;
            justify-content: space-between;
            align-items: center;
          }

          & .infoRowSubscriptionName{
            color: #FFFFFF;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & .infoRowSubscriptionValue{
            display: grid;
            grid-template-columns: max-content max-content;
            justify-content: center;
            align-content: center;
            text-align: center;

            width: 100%;
            min-width: 5rem;
            height: 3.2rem;

            border-radius: 1rem;
            border: 1px solid #FF8C00;
            background: rgba(255, 140, 0, 0.10);

            color: #FF8C00;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0 1rem;

            & span{
              font-size: 1.4rem;
              padding-left: 0.2rem;
              position: relative;
              top: 0.1rem;
            }
          }
        }
      }

      & .earnedRow{
        align-self: end;

        & .titleWSF{
          color: #00FF7A;
          margin-bottom: 0.75rem;
        }

        & .earnedRowTotal{
          display: grid;
          grid-template-columns: max-content max-content;
          align-items: center;
          grid-gap: 1rem;

          & .imgWrap{
            display: grid;
          }

          & .total{
            color: #00FF7A;

            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        & .earnedRowStatus{
          display: grid;
          grid-gap: 1rem;

          margin-top: 1.6rem;

          @include media(xs) {
            grid-template-columns: max-content 1fr;
            grid-auto-flow: column;
            justify-content: space-between;
            align-items: center;
          }

          & .earnedRowStatusName{
            color: #FFFFFF;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & .earnedRowStatusValue{
            display: grid;
            justify-content: center;
            align-content: center;
            text-align: center;

            width: 100%;
            height: 3.2rem;

            border-radius: 1rem;
            border: 1px solid #00FF7A;

            background: rgba(0, 255, 122, 0.10);

            color: #00FF7A;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0 1rem;
          }
        }
      }
    }
  }
}