@import "../../theme/scss/resources";

.container{
  grid-column: 1 / 3;
  & .honeeCardBody{
    display: grid;
    grid-gap: 3rem;

    @include media(md) {
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    }

    @include media(xl) {
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
    }

    & .contentWrapItem{
      display: grid;
      align-items: start;
      grid-template-rows: max-content max-content 1fr;


      & .titleWSF{
        margin-bottom: 0.75rem;

        & span{
          border-radius: 1rem;
          padding: 0.5rem 2rem;
          margin-left: 0.5rem;
        }
      }

      & .contentWrapItemColor{
        font-style: normal;
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;

        &::first-letter{
          text-transform: uppercase;
        }
      }

      & .contentWrapItemDetailsBtn{
        cursor: pointer;
        margin-bottom: 0.75rem;

        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: 0.5rem;
        align-items: center;

        & .text{
          font-style: normal;
          font-weight: 400;
          font-size: 1.25rem;
          line-height: 1.5rem;

          text-decoration-line: underline;

          color: #F7931A;
        }

        & img{
          display: grid;
          width: 1.75rem;

          position: relative;
          top: 0.15rem;
        }
      }

      & .title3{
        margin-bottom: 0.5rem;
        position: relative;
        font-size: 1.5rem;

        & img{
          position: relative;
          top: 0.55rem;
          left: 0.25rem;
          width: 1.75rem;
        }

        &.customMargin{
          margin-top: 2rem;
        }

        & span{
          cursor: pointer;

          font-style: normal;
          font-weight: 400;
          font-size: 1.25rem;
          line-height: 1.5rem;

          text-decoration-line: underline;

          color: #F7931A;
        }
      }

      & .botLevel{
        display: grid;
        align-items: center;
        justify-items: center;

        position: relative;

        height: 3.2rem;
        width: 100%;
        border: 1px solid #03B759;
        border-radius: 0.8rem;
        background: rgba(3, 183, 89, 0.1);

        @include media(sm) {
          width: 27rem;
        }

        & .botLevelBar{
          position: absolute;
          left: -0.1rem;
          top: -0.1rem;
          height: 3.2rem;
          background: #03B759;
          border-radius: 0.8rem;
          z-index: 10;
        }

        & .botLevelText{
          position: relative;
          z-index: 20;

          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.2rem;
          text-align: center;

          color: #FFFFFF;
        }
      }

      & .contentWrapItemValue{
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap: 1rem;

        font-style: normal;
        font-weight: 400;
        font-size: 3.8rem;
        line-height: 5.2rem;

        color: #F7931A;

        margin-bottom: 2rem;

        @include media(md) {
          margin-bottom: 3rem;
        }
        & .contentWrapItemValueIconWrap{
          display: grid;
        }
      }

      & .contentWrapItemDetailsWrap{
        display: grid;
        grid-gap: 0.5rem;

        margin-bottom: 0.5rem;

        //@include media(xl) {
        //  grid-gap: 3.5rem;
        //}

        &.contentWrapItemDetailsWrapRePos{
          grid-gap: 4.25rem;
        }

        & .contentWrapItemDetailsItem{

          & .title3{
            color: #E80000;
            margin-bottom: 0;
          }

          & .contentWrapItemDetailsItemValue{
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.1rem;


            color: #FFFFFF;
          }
        }
      }

      & .contentWrapItemRewardsWrap{
        display: grid;
        grid-gap: 0.5rem;

        & .contentWrapItemRewardsItem{
          &:last-child{
            & .contentWrapItemRewardsItemBox{
              color: #F5F5F5 !important;
            }
          }
          & .contentWrapItemRewardsItemBox{
            display: grid;
            grid-template-columns: max-content 1fr;
            align-items: center;
            grid-gap: 1rem;

            font-style: normal;
            font-weight: 400;
            font-size: 3.8rem;
            line-height: 5.2rem;

            color: #F7931A;

            & .contentWrapItemRewardsItemIconWrap{

            }
          }
        }
      }
    }
  }
}