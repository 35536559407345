@import "../../theme/scss/resources";

.container{
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 2rem;

  @include media(xl) {
    grid-template-columns: 1fr 35rem;
  }

  @include media(fullHD) {
    grid-template-columns: 1.5fr 1fr;
  }
}