@import 'palette.scss';

@import 'mediaQueryManager.scss';

@import 'mixins.scss';

@import 'fonts.scss';

.pageContainer{
  width: 30rem;
  margin: 0 auto;

  @include media(xs) {
    width: 35rem;
  }

  @include media(xs) {
    width: 35rem;
  }

  @include media(sm) {
    width: 50rem;
  }

  @include media(md) {
    width: 70rem;
  }

  @include media(lg) {
    width: 90rem;
  }

  @include media(xl) {
    width: 130rem;
  }

  @include media(fullHD) {
    width: 168rem;
  }
}

.pageContainerWS3{
  width: calc(30rem + 2rem);
  margin: 0 auto;

  @include media(xs) {
    width: calc(35rem + 2rem);
  }

  @include media(xs) {
    width: calc(35rem + 2rem);
  }

  @include media(sm) {
    width: calc(50rem + 2rem);
  }

  @include media(md) {
    width: calc(70rem + 2rem);
  }

  @include media(lg) {
    width: calc(90rem + 2rem);
  }

  @include media(xl) {
    width: calc(125rem + 2rem);
  }

  @include media(fullHD) {
    width: calc(168rem + 6.5rem);
  }
}

.pageContainerWS4{
  width: calc(30rem + 3rem);
  margin: 0 auto;

  @include media(xs) {
    width: calc(35rem + 3rem);
  }

  @include media(xs) {
    width: calc(35rem + 3rem);
  }

  @include media(sm) {
    width: calc(50rem + 3rem);
  }

  @include media(md) {
    width: calc(70rem + 3rem);
  }

  @include media(lg) {
    width: calc(90rem + 2rem);
  }

  @include media(xl) {
    width: calc(125rem + 2rem);
  }

  @include media(fullHD) {
    width: calc(168rem + 3rem);
  }
}

.cabinetContainer{
  width: 30rem;
  margin: 0 auto;

  @include media(xs) {
    width: 35rem;
  }

  @include media(xs) {
    width: 35rem;
  }

  @include media(sm) {
    width: 50rem;
  }

  @include media(md) {
    width: 70rem;
  }

  @include media(lg) {
    width: 67rem;
    margin: initial;
  }

  @include media(xl) {
    width: 84rem;
  }

  @include media(fullHD) {
    width: 139rem;
  }
}

.pageVerticalPadding{
  padding: 8rem 0;
}

.cabinetVerticalPadding{
  padding: 5.5rem 0;
}

.cabinetCard{
  padding: 2rem;
  background: #FFFFFF;

  border-radius: 12px;
  border: 0 solid #B3E3FF;

  backdrop-filter: blur(27px);
}

.cabinetLargeCard{
  padding: 2rem;
  background: #FFFFFF;

  border-radius: 1.2rem;
  border: 0 solid #B3E3FF;

  backdrop-filter: blur(27px);

  @include media(md) {
    padding: 3.4rem;
  }
}

.cabinetLargeCardWS{
  padding: 2rem 1rem 2rem 2rem;
  background: #FFFFFF;

  border-radius: 1.2rem;
  border: 0 solid #B3E3FF;

  backdrop-filter: blur(27px);

  @include media(md) {
    padding: 3.4rem 1.7rem 3.4rem 3.4rem;
  }
}

.cabinetLargeCardMS{
  padding: 2rem 1rem 2rem 2rem;
  background: #FFFFFF;

  border-radius: 1.2rem;
  border: 0 solid #B3E3FF;

  backdrop-filter: blur(27px);

  @include media(md) {
    padding: 3.4rem;
  }
}

.cabinetBtn{
  cursor: pointer;

  padding: 1.3rem 0.1rem;
  text-transform: uppercase;
  width: 100%;

  color: #F6F6F6;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-style: normal;
  font-weight: 500;

  border-radius: 1.2rem;
  background: #00A3FF;
  border: 1px solid #00A3FF;

  &:hover{
    background: transparent;
    color: #00A3FF;
  }
}

.cabinetBtnDisable{

  padding: 1.3rem 0.1rem;
  text-transform: uppercase;
  width: 100%;

  color: #F6F6F6;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-style: normal;
  font-weight: 500;

  border-radius: 1.2rem;
  background: #9A9A9A;
  border: 1px solid #9A9A9A;
}

.customBtnBlue,
.customBtnBlueT,
.customBtnBlueW{
  cursor: pointer;

  font-size: 1.8rem;
  line-height: 1.8rem;
  font-style: normal;
  font-weight: 700;

  padding: 1.2rem 7.4rem;
  text-align: center;
  text-transform: uppercase;
  border-radius: 1.2rem;
  width: 100%;


  @include media(md) {
    width: max-content;
  }
}

.customBtnBlue{
  background-color: #00A3FF;
  border: 1px solid #00A3FF;
  color: #0F0F0F;

  &:hover{
    background-color: transparent;
    color: #00A3FF;
  }
}

.customBtnBlueW{
  background-color: #00A3FF;
  border: 1px solid #00A3FF;
  color: #F5F7F9;

  &:hover{
    background-color: transparent;
    color: #00A3FF;
  }
}

.customBtnBlueT{
  background: transparent;
  border: 1px solid #00A3FF;
  color: #00A3FF;

  &:hover{
    background-color: #00A3FF;
    color: #0F0F0F;
  }
}

.title, .h1, .h2, .h3, .h4 {
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: #F5F5F5;
}
.h1 {
  font-size: 2.4rem!important;
  line-height: 3.3rem;
  //margin-bottom: 0.5rem;
}

.h2 {
  font-size: 2rem!important;
  line-height: 3rem;
}
.h3 {
  font-size: 1.75rem!important;
}
.h4 {
  font-size: 1.5rem!important;
}

.btn {
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #F5F5F5;
  background: #F7931A;
  border-radius: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
  height: 4.8rem;
  margin: 0.25rem 0.125rem;
}

//.btnoutlinetheme {

//}
.btnsm {
  display: grid;
  align-items: center;
  justify-items: center;

  height: 3rem;
  width: 6.5rem;
  padding-left: 0;
  padding-right: 0;
  font-size: 2rem;
  border-radius: 5px;
}
.btnoutlinetheme.disabled {
  color: #848b8d;
  background: #202e33;
  border: 1px solid #848b8d;
  cursor: initial !important;
}
.btnoutlinetheme {

  color: #F7931A;
  background: #2a2e27;
  border: 1px solid #f7931a;
}
.btn-theme {
  color: #F5F5F5;
  background: #F7931A;
  border: 1px solid #F7931A;
}
.btn-theme:hover {
  background-color: #f48601;
  border-color: #f48601;
}

.card {
  background: #213034;
  border: 1px solid #8C9497;
  border-radius: 1rem;
  grid-column: auto;

  & .cardbody{
    display: grid;
    grid-template-rows: max-content 1fr;
    height: 100%;
    padding: 3.5rem;

  }
}
.colorgreen {
  color: #03B759;
}
.colorred {
  color: #E80000;
}

//effects

.effectPointer{
  cursor: pointer;
}

//effects

// colors

.colorOrange{
  color: #F7931A !important;
}

.colorBlue{
  color: #58C1EF !important;
}

.colorGray{
  color: #8C9497 !important;
}

.colorGreen{
  color: #00FF7A !important;
}

.colorYellow{
  color: #FFC200 !important;
}

.colorRed{
  color: #E36139 !important;
}

// colors

// titles

.cabinetTitle1, .cabinetTitle2, .cabinetTitle3{
  color: #222222;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cabinetTitle1{
  font-size: 2.6rem;
}

.cabinetTitle2{
  font-size: 2rem;
}

.cabinetTitle3{
  font-size: 1.6rem;
}

.title1, .title2, .title3, .title4, .title5, .title6{
  font-style: normal;
  font-weight: 700;
  color: #FFFFFF;

  //margin-bottom: 1rem;
  //
  //@include media(lg) {
  //  margin-bottom: 2rem;
  //}
}

.title1{
  font-size: 3.6rem;
  line-height: 4rem;

  @include media(lg) {
    font-size: 7rem;
    line-height: 7.9rem;
  }
}

.title2{
  font-size: 2.4rem;
  line-height: 2.9rem;

  @include media(lg) {
    font-size: 4.8rem;
    line-height: 5.7rem;
  }
}

.title3{
  font-size: 2rem;
  line-height: 2.4rem;
}

.title4{
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.title5{
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.title6{
  font-size: 1.2rem;
  line-height: 1.6rem;
}

// titles

// card

.card{
  & .cardHeader{
    @include cardPadding;
  }

  & .cardBody{
    @include cardPadding;
  }

  & .cardBodyWithScroll{
    @include cardPaddingWithScroll;
  }

  & .cardFooter{
    @include cardPadding;
  }
}

// card

// buttons

.button{
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #F5F5F5;
  background: #F7931A;
  border-radius: 1rem;
  height: 4.8rem;
  width: 100%;
  border: 1px solid transparent;
  padding: 0 1.75rem;

  &:hover{
    background-color: #f48601;
    border-color: #f48601;
  }
}

.buttonGreen{
  border-radius: 1rem;
  border: 1px solid #0abe5a;

  background: rgba(0, 255, 122, 0.10);

  color: #0abe5a;

  &:hover{
    background: #0abe5a;
    border: 1px solid #0abe5a;
    color: #F5F5F5;
  }
}

.buttonOrange{
  border-radius: 1rem;
  border: 1px solid #FF8C00;

  background: #23190D;

  color: #FF8C00;

  &:hover{
    background: #FF8C00;
    border: 1px solid #FF8C00;
    color: #F5F5F5;
  }
}

.buttonGray{
  border-radius: 1rem;
  border: 1px solid #CBBDA0;

  background: rgba(203, 189, 160, 0.10);

  color: #CBBDA0;

  &:hover{
    background: #CBBDA0;
    border: 1px solid #CBBDA0;
    color: #F5F5F5;
  }
}

.buttonWB{
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #F5F5F5;
  background: #0d0a07;
  border-radius: 1rem;
  height: 4.8rem;
  width: 100%;
  border: 1px solid #F5F5F5;
  padding: 0 1.75rem;

  &:hover{
    color: #f48601;
    border-color: #f48601;
  }
}

.buttonType1{
  color: #F7931A;
  background: transparent;
  border: 1px solid #f7931a;

  &:hover{
    color: #f48601;
    background-color: transparent;
    border-color: #f48601;
  }
}

.buttonType2{
  color: #F5F5F5;
  background: #58C1EF;
  border: 1px solid #58C1EF;

  &:hover{
    color: #F5F5F5;
    background-color: #58AEEF;
    border-color: #58AEEF;
  }
}

.buttonType3{
  color: #F5F5F5;
  background: #03B759;
  border: 1px solid #03B759;

  &:hover{
    color: #F5F5F5;
    background-color: #03A259;
    border-color: #03A259;
  }
}

.buttonDisabled{
  cursor: initial !important;
  color: #8C9497;
  background-color: transparent;
  border: 1px solid #8C9497;

  &:hover{
    color: #8C9497;
    background-color: transparent;
    border-color: #8C9497;
  }
}

// buttons

.honeeCard{
  border-radius: 1rem;
  border: 1px solid #915207;

  background: linear-gradient(138deg, rgba(201, 110, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%);

  backdrop-filter: blur(27px);
}

.honeeCardBody{
  padding: 3.5rem 2rem;

  @include media(xs) {
    padding: 3.5rem;
  }
}

.titleWSF{
  font-family: 'Phage Regular KG', sans-serif;
  text-transform: uppercase;
  color: #F5F5F5;

  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cabinetInput{
  border-radius: 1rem;
  background: #23190D;

  width: 100%;

  height: 4.8rem;

  color: #FFFFFF;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 1.4rem;

  background: rgba(15, 32, 52, 0.1);
  border: 1px solid #FF8C00;
  backdrop-filter: blur(7px);

}