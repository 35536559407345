@import "../../theme/scss/resources";

.container{
  border: 1px solid #FFD600 !important;

  @include media(xl) {
    display: grid;
    grid-row: 2;
    grid-column: 1 / 3;
  }

  & .honeeCardBody {

    & .contentWrap{
      display: grid;
      grid-gap: 2rem;

      @include media(xl) {
        grid-template-columns: initial;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
      }

      & .note{
        color: #FFFFFF;

        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;

        @include media(md) {
          text-align: center;
        }

        @include media(xl) {
          text-align: left;
          grid-column: initial;
          grid-column: 1 / 3;
        }

        @include media(fullHD) {
          max-width: 40rem;
        }
      }

      & .lngWrap{
        & .lngTitle{
          color: #FFFFFF;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-bottom: 0.5rem;

          @include media(xl) {
            margin-bottom: 1rem;
          }
        }

        & .lngBox{
          display: grid;
          grid-template-columns: repeat(5, max-content);
          align-items: center;
          grid-gap: 1rem;

          & .lngBoxItem{
            cursor: pointer;

            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            width: 4rem;
            height: 3rem;


            color: #FFFFFF;

            font-family: "Space Mono";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;

            border-radius: 0.8rem;
            border: 1px solid #FFF;

            opacity: 0.8;
            background: rgba(255, 255, 255, 0.05);

            &.lngBoxItemActive{
              border: 1px solid #FFFF00;
              background: rgba(255, 255, 0, 0.05);
              color: #FFFF00;
            }
          }
        }
      }

      & .button{
        align-self: end;

        @include media(xl) {
          align-self: initial;
        }
      }
    }
  }
}