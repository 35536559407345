@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    display: grid;
    grid-gap: 3rem;

    @include media(xl) {
      grid-template-columns: max-content max-content;
      justify-content: space-between;
    }

    & .contentWrapItem{
      display: grid;
      align-items: start;
      grid-template-rows: max-content max-content 1fr;

      &:last-child{
        & .contentWrapItemValue{
          color: #03B759;

          border-radius: 1rem;
          border: 1px solid #00FF7A;

          background: rgba(0, 255, 122, 0.10);


        }
      }
      & .titleWSF{
        margin-bottom: 1.5rem;
      }

      & .contentWrapItemValue{
        font-style: normal;
        font-weight: 400;
        font-size: 3.8rem;
        line-height: 5.2rem;

        color: #F7931A;

        border-radius: 1rem;
        border: 1px solid #FF8C00;
        background: rgba(255, 140, 0, 0.10);

        padding: 1rem 2rem;


        margin-bottom: 2rem;

        @include media(md) {
          margin-bottom: 3rem;
        }

      }

      & .contentWrapItemTextWrap{
        display: grid;
        grid-gap: 1rem;

        @include media(md) {
          grid-gap: 1.6rem;
        }

        & .contentWrapItemTextWrapRow{
          font-style: normal;
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 2rem;

          color: #8C9497;

          & br{
            @include media(sm) {
              display: none;
            }
          }

          @include media(md) {
            font-size: 1.8rem;
            line-height: 2.5rem;
          }
        }
      }

      & .contentWrapItemBtnWrap{
        display: grid;
        align-self: end;
        justify-items: start;
        grid-gap: 2rem;

        @include media(xl) {
          justify-items: end;
        }

        & .contentWrapItemBtn{
          cursor: pointer;

          width: 100%;
          padding: 1.1rem 0;
          text-align: center;

          background: #F7931A;
          border: 1px solid #F7931A;
          border-radius: 1rem;

          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.5rem;

          color: #F5F5F5;

          @include media(md) {
            width: 19rem;
          }

          &:last-child{
            background: transparent;
            color: #F7931A;
          }
        }
      }
    }
  }
}