@import "../../theme/scss/resources";

.container{
  //background: #0d0a07;
  padding: 1.2rem 0;

  //border-bottom: 1px solid #FF8C00;

  @include media(lg) {
    padding-top: 7.2rem;
  }

  & .contentWrap{
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    //position: relative;

    @include media(lg) {
      grid-auto-flow: initial;
      grid-template-columns: max-content max-content 1fr;
      justify-content: initial;
      grid-gap: 3.5rem;
    }
  }
}