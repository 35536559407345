@import "../../theme/scss/resources";

.container{
  padding-top: 10rem;
  background: #0d0a07;

  & .contentWrap{
    & .title2{
      cursor: pointer;
      margin: 0 auto;
      margin-bottom: 6.5rem;
      text-align: center;

      & span{
        color: #FF8C00;
      }
    }

    & .contentBox{
      & .newsBox{
        display: grid;
        grid-gap: 4rem;

        @include media(lg) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include media(xl) {
          grid-template-columns: auto;
          grid-auto-flow: column;
          justify-content: space-between;
        }

        & .newsBoxItem{
          cursor: pointer;
          max-width: 34.7rem;
          margin: 0 auto;

          & .newsBoxItemImgWrap{
            display: grid;
            border-radius: 1rem;
            border: 1px solid #FF8C00;
            overflow: hidden;

            & img{
              width: 100%;
            }
          }

          & .dateWrap{
            @include dateWrap;
            margin-top: 3rem;
          }

          & .title2{

            height: 6rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: 700;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1.5rem;

            @include media(lg) {
              text-align: left;
            }
          }

          & .newsBoxItemDescription{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 6rem;

            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2rem;

            color: #F5F5F5;

            margin-bottom: 1.5rem;
          }

          & .newsBoxItemCategory{
            font-style: normal;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.5rem;

            text-align: center;

            color: #F7931A;
            display: none;

            @include media(lg) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}