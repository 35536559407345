@import "../../../theme/scss/resources";

.container{
  & .contentWrapLoader{
    height: 35.3rem;
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;

    font-style: normal;
    font-weight: 400;
    color: #F5F5F5;

    font-size: 2.5rem;
    line-height: 3.5rem;

    @include media(md) {
      height: 34.5rem;
    }

    @include media(lg) {
      font-size: 4.2rem;
      line-height: 5.7rem;
    }
  }
  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #F5F5F5;
      margin-bottom: 2rem;
    }

    & .listWrap{

      &.listWrapN{
        display: none;
      }
      & .listPosWrap{
        height: 6.2rem;
        margin-bottom: 5rem;

        &.listPosWrapToken{
          position: relative;
          z-index: 500;
        }

        &.listPosWrapNet{
          position: relative;
          z-index: 400;
        }

        & .listPosWrapTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: #F5F5F5;

          margin-bottom: 0.5rem;
        }

        & .listBox{
          cursor: pointer;
          position: relative;

          display: grid;
          grid-gap: 2.25rem;

          background: #1C343E;
          border: 1px solid #58C1EF;
          border-radius: 1rem;

          padding: 1.4rem 2.4rem;

          height: 6.2rem;
          //overflow-y: scroll;
          //


          & .listBtn{
            cursor: pointer;

            position: absolute;
            right: 2.4rem;
            top: 2.25rem;

            width: 2rem;
            height: 2rem;

            display: grid;
            justify-items: center;
            align-items: center;

            transition: all 0.5s ease-out;

            &.listBtnActive{
              transform: rotate(180deg);
            }
          }

          & .listResult{
            display: grid;
            grid-template-columns: max-content 1fr;
            align-items: center;
            grid-gap: 0.75rem;

            & .listResultImgWrap{
              display: grid;
            }

            & .listResultText{
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F5F5F5;
            }
          }
        }

        & .abslistWrap{
          //overflow-y: hidden;
          //max-height: 0;
          overflow: hidden;
          position: relative;

          max-height: 0;

          -ms-overflow-style: none;
          scrollbar-width: none;

          transition: all 0.5s ease-out;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          &.abslistWrapActive{
            max-height: 25.3rem;

            & .abslistBorderWrap{
              max-height: 23.3rem;
            }
          }



          & .abslistBorderWrap{
            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            border-radius: 1rem;

            max-height: 0;
            margin-top: 2rem;
            overflow: scroll;

            -ms-overflow-style: none;
            scrollbar-width: none;

            transition: all 0.5s ease-out;

            &::-webkit-scrollbar {
              width: 0;
              height: 0;
            }

            & .abslistBox{
              overflow-y: scroll;
              position: relative;

              display: grid;
              grid-gap: 2.25rem;



              padding: 1.4rem 2.4rem;

              -ms-overflow-style: none;
              scrollbar-width: none;

              transition: all 0.5s ease-out;

              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }

              //height: 6.2rem;

              & .listBoxItem{
                cursor: pointer;

                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
                grid-gap: 0.75rem;

                & .listBoxItemImgWrap{
                  display: grid;
                }

                & .listBoxItemText{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;
                }
              }
            }
          }
        }
      }
    }

    & .addressWrap{
      display: grid;

      margin-bottom: 2rem;

      @include media(md) {
        grid-template-columns: 1fr 13.6rem;
        grid-gap: 3rem;
      }

      & .addressBox{
        & .addressTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;
          margin-bottom: 0.5rem;
        }

        & .address{
          position: relative;
          display: grid;
          align-items: center;
          grid-gap: 2.25rem;
          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #FF8C00;
          backdrop-filter: blur(7px);
          border-radius: 1rem;
          padding: 1.4rem 2.3rem;

          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.9rem;
          color: #F5F5F5;

          & span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        & .addressNote{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F7931A;
          margin-top: 0.5rem;
          margin-bottom: 1.5rem;
        }

        & .button{
          cursor: pointer;

          border-radius: 1rem;
          padding: 1.2rem 0;
          text-align: center;

          font-style: normal;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }

      & .qrBox{
        display: none;
        border: 0.4rem solid #ffffff;

        @include media(md) {
          display: grid;
          align-self: end;
        }
      }
    }

    & .noteWrap{
      background: rgba(140, 148, 151, 0.2);
      border-width: 1px 0;
      border-style: solid;
      border-color: #8C9497;

      padding: 1.4rem 2.4rem;

      & :nth-child(2){
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }

      & :nth-child(3){
        display: none;
        @include media(md) {
          display: block;
        }
      }

      & .text{
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.6rem;

        color: #F5F5F5;
      }

      & .colored{
        color: #F7931A;
      }
    }
  }
}