@import "../../theme/scss/resources";


:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .formWrap{
      & .formTitle{
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #F5F5F5;
        margin-bottom: 1.5rem;
      }

      & .exchangeWrap{
        height: 16.3rem;
        position: relative;
        z-index: 100;

        display: grid;
        grid-template-columns: 4.5rem 1fr 4rem;
        align-items: center;
        grid-gap: 2rem;

        padding: 2.4rem;
        background: #1C343E;
        border: 1px solid #58C1EF;
        border-radius: 1rem;
        margin-bottom: 1.5rem;

        @include media(md) {
          grid-gap: 3.5rem;
        }

        & .infoWrap{
          & .infoWrapText{
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #F5F5F5;
          }

          & .infoWrapLineBox{
            display: grid;
            justify-items: center;
            grid-gap: 0.3rem;
            padding: 0.7rem 0;

            width: 0.5rem;

            & .infoWrapLineBoxItem{
              height: 0.5rem;
              width: 0.1rem;

              background: white;

              &:first-child{
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 5rem;
                background: #58c1ef;
              }

              &:nth-child(2){
                background: #76b8c7;
              }

              &:nth-child(3){
                background: #96af9b;
              }

              &:nth-child(4){
                background: #b9a56d;
              }

              &:nth-child(5){
                background: #d89b42;
              }

              &:last-child{
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 5rem;
                background: #f5941d;
              }
            }
          }
        }

        & .valuesWrap{
          display: grid;

          & .listPosWrap{
            height: 3.2rem;
            margin-bottom: 2.1rem;

            & .listBox{
              position: relative;

              display: grid;
              grid-gap: 2.25rem;

              //background: #1C343E;
              //border: 1px solid #58C1EF;
              //border-radius: 1rem;

              //padding: 1.4rem 2.4rem;

              height: 3.2rem;
              //overflow-y: scroll;
              //


              & .listBtn{
                cursor: pointer;

                position: absolute;
                right: 0;
                top: 0.5rem;

                width: 2rem;
                height: 2rem;

                display: grid;
                justify-items: center;
                align-items: center;

                transition: all 0.5s ease-out;

                &.listBtnActive{
                  transform: rotate(180deg);
                }
              }

              & .listResult{
                display: grid;
                grid-template-columns: max-content max-content 1fr;
                align-items: center;
                grid-gap: 0.75rem;

                //& :nth-child(2){
                //  display: none;
                //}


                & .listResultImgWrap{
                  display: grid;
                }

                & .listResultText{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;
                }

                & .listResultTextBalance{
                  justify-self: end;
                  padding-right: 3rem;
                }
              }
            }

            & .abslistWrap{
              //overflow-y: hidden;
              //max-height: 0;
              overflow: hidden;
              position: relative;

              max-height: 0;

              -ms-overflow-style: none;
              scrollbar-width: none;

              transition: all 0.5s ease-out;

              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }

              &.abslistWrapActive{
                max-height: 25.3rem;

                & .abslistBorderWrap{
                  max-height: 23.3rem;
                }
              }



              & .abslistBorderWrap{
                background: #1C343E;
                border: 1px solid #58C1EF;
                border-radius: 1rem;

                max-height: 0;
                margin-top: 2rem;
                overflow: scroll;

                -ms-overflow-style: none;
                scrollbar-width: none;

                transition: all 0.5s ease-out;

                &::-webkit-scrollbar {
                  width: 0;
                  height: 0;
                }

                & .abslistBox{
                  overflow-y: scroll;
                  position: relative;

                  display: grid;
                  grid-gap: 2.25rem;



                  padding: 1.4rem 2.4rem;

                  -ms-overflow-style: none;
                  scrollbar-width: none;

                  transition: all 0.5s ease-out;

                  &::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                  }

                  //height: 6.2rem;

                  & .listBoxItem{
                    cursor: pointer;

                    display: grid;
                    grid-template-columns: max-content max-content 1fr;
                    align-items: center;
                    grid-gap: 0.75rem;

                    & .listBoxItemImgWrap{
                      display: grid;
                    }

                    & .listBoxItemText{
                      font-style: normal;
                      font-weight: 400;
                      font-size: 1.4rem;
                      line-height: 1.9rem;

                      color: #F5F5F5;
                    }

                    & .listBoxItemTextBalance{
                      justify-self: end;
                    }
                  }
                }
              }
            }
          }

          & .valuesItem{
            display: grid;
            align-items: center;

            height: 5.3rem;

            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.1rem;

            color: #F5F5F5;

            -ms-user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;

            //position: relative;
            //display: grid;
            //grid-gap: 2.25rem;
            //background: #1C343E;
            //border: 1px solid #58C1EF;
            //border-radius: 1rem;
            //padding: 1.4rem 2.4rem;
            //height: 6.2rem;

            &:last-child{
              padding-top: 2.1rem;
              border-top: 1px solid #58C1EF;
            }
          }
        }

        & .btnWrap{
          & .btn{
            cursor: pointer;

            display: grid;
            justify-items: center;
            align-items: center;
            width: 4rem;
            height: 4rem;

            border-radius: 5rem;
            background: rgba(247, 147, 26, 0.1);
            border: 1px solid #F7931A;
            & img{

            }
          }
        }
      }

      & .inputWrap{
        position: relative;
        z-index: 50;

        & .inputBox{
          position: relative;

          & input{
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #F5F5F5;
            width: 100%;

            background: #1E363F;
            border: 1px solid #58C1EF;
            border-radius: 1rem;

            padding: 1.4rem 2.4rem;
          }

          & .inputSum{
            padding: 1.4rem 6.4rem 1.4rem 2.4rem;
            -moz-appearance: textfield;

            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          & .inputAbs{
            position: absolute;
            right: 2rem;
            top: 1.35rem;
            height: 2rem;

            & .inputAbsCur{
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F5F5F5;
              padding-right: 1rem;
            }

            & .inputAbsBtn{
              cursor: pointer;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F7931A;
              padding-left: 1rem;
              border-left: 1px solid #D9D9D9;
            }
          }

          & .inputNote{
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #F5F5F5;
            margin-top: 0.5rem;

            & span{
              color: #F7931A;
            }
          }
        }
      }

      & .daysWrap{
        margin-top: 1.5rem;
        margin-bottom: 2rem;

        & .daysTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.9rem;

          margin-bottom: 1rem;
          color: #F5F5F5;
        }

        & .daysBox{
          display: grid;
          grid-auto-flow: column;
          grid-gap: 1.8rem;
          align-items: center;

          @include media(md) {
            justify-content: left;
          }

          &.daysBoxInitial{
            & .daysBoxItem{
              &:first-child{
                color: #F7931A;
                background: rgba(247, 147, 26, 0.1);
                border: 1px solid #F7931A;
              }
            }
          }

          & .daysBoxItem{
            cursor: pointer;

            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 1.8rem;

            color: #F5F5F5;
            border: 1px solid #F5F5F5;
            border-radius: 1rem;
            max-width: 8.3rem;
            text-align: center;
            padding: 0.7rem 0;

            @include media(md) {
              max-width: initial;
              width: 8.3rem;
            }

            &.daysBoxItemActive{
              color: #F7931A;
              background: rgba(247, 147, 26, 0.1);
              border: 1px solid #F7931A;
            }
          }
        }
      }

      & .profitInvestWrap{
        display: grid;
        grid-template-columns: 4.919rem max-content;
        grid-gap: 4rem;

        margin-bottom: 2.5rem;

        @include media(md) {
          grid-template-columns: 8.3rem max-content;
        }

        & .profitInvestWrapItem{
          & .profitInvestItemTitle{
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.9rem;

            color: #F5F5F5;
            margin-bottom: 1rem;
          }

          & .profitBox{
            background: rgba(3, 183, 89, 0.1);
            border: 1px solid #03B759;
            border-radius: 1rem;

            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 1.8rem;

            color: #F5F5F5;
            max-width: 4.919rem;
            text-align: center;
            padding: 0.7rem 0;

            @include media(md) {
              max-width: initial;
              width: 8.3rem;
            }
          }

          & .investBox{
            & input{
              position: relative;
              width: 6.9rem;
              height: 3.4rem;
              -webkit-appearance: none;
              outline: none;
              background: #D9D9D9;
              border-radius: 2rem;
              transition: 0.5s;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

              &:checked{
                background: #F7931A;

                &:before{
                  left: 4rem;
                }
              }

              &:before{
                content: '';
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 5rem;
                background: #ffffff;
                transition: 0.5s;
              }
            }
          }
        }
      }

      & .noteWrap{
        & .noteWrapRow{
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.9rem;

          color: #F5F5F5;
          margin-bottom: 0.5rem;

          & span{
            color: #F7931A;
          }
        }

        & .noteWrapText{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #8C9497;
        }
      }

      & .mainBtn{
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        text-align: center;
        padding: 1rem 0;

        color: #8C9497;

        border: 1px solid #8C9497;
        border-radius: 1rem;

        margin-top: 3rem;

        @include media(md) {
          max-width: 22.7rem;
          margin: 3rem auto 0;
        }

        &.mainBtnMActive{
          cursor: pointer;

          background: #F7931A;
          color: #F5F5F5;
          border: 1px solid #F7931A;
        }
      }
    }
  }
}