@import "../../theme/scss/resources";

.container{
  @include pagePadding;
  padding-top: 2rem;
  padding-bottom: 2rem;

  background: linear-gradient(138deg, rgba(201, 110, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%);
  backdrop-filter: blur(27px);
  border-bottom: 1px solid #915207;

  @include media(lg) {
    display: none !important;
  }

  & .contentWrap{
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    & .navBtn{
      display: grid;
      align-items: center;
      justify-items: center;

      width: 2.5rem;
      height: 2.8rem;

      &.navBtnActive{
        & .navBtnItem{
          &:first-child{
            top: 0.9rem;
            transform: rotate(45deg);
          }

          &:nth-child(2){
            opacity: 0;
          }

          &:last-child{
            top: -0.9rem;
            transform: rotate(-45deg);
          }
        }
      }

      & .navBtnItem{
        position: relative;
        width: 2.5rem;
        height: 2px;
        background: #F5F5F5;

        transition: all 0.5s ease-out;
      }
    }

    & .logoWrap{
      display: grid;
    }
  }
}