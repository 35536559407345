@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    height: 100%;
    overflow: hidden;

    @include media(xl) {
      height: 28.3rem;
    }

    & .cardBodyWithScroll{
      & .titleWrap{
        display: grid;

        align-items: center;
        grid-gap: 2rem;

        margin-bottom: 2rem;

        //@include media(xl) {
        //  grid-template-columns: max-content max-content;
        //}

        @include media(lg) {
          grid-template-columns: auto;
          grid-auto-flow: column;
          justify-content: space-between;
        }

        & .title3{
          margin-bottom: 0;
          font-size: 1.5rem;

          @include media(sm) {
            font-size: 1.8rem;
          }
        }

        & .filterWrap{
          display: grid;
          grid-template-columns: repeat(4, max-content);
          grid-gap: 1rem;

          @include media(sm) {
            grid-gap: 1.5rem;
          }


          & .filterWrapItem{
            cursor: pointer;

            display: grid;
            align-items: center;
            text-align: center;

            width: 7rem;
            height: 3.2rem;

            border: 1px solid #8C9497;
            border-radius: 1rem;

            color: #FFFFFF;

            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @include media(sm) {
              width: 12rem;
            }

            &.filterWrapItemActive{
              background: rgba(3, 183, 89, 0.10);
              border: 1px solid #03B759;
            }
          }
        }
      }


      & .titleRow{
        display: grid;
        grid-template-columns: 30% 30% 33%;
        grid-gap: 1rem;
        justify-content: space-between;

        margin-bottom: 2rem;
        padding-right: 2rem;

        @include media(sm) {
          grid-template-columns: repeat(3, calc(33.333% - 0.33rem));
        }

        @include media(md) {
          grid-template-columns: calc(20% - 0.8rem) calc(14% - 0.8rem) calc(22% - 0.8rem) calc(22% - 0.8rem) calc(22% - 0.8rem);
        }

        @include media(lg) {
          grid-template-columns: repeat(5, calc(20% - 0.8rem));
        }

        @include media(fullHD) {
          padding-right: 3rem;
        }

        & .title6{
          display: grid;
          align-content: center;
          margin-bottom: 0;
          text-align: left;

          @include media(md) {
            text-align: center;
          }

          &:first-child{
            @include media(md) {
              text-align: left;
            }
          }

          &:last-child{
            @include media(md) {
              text-align: right;
            }
          }
        }

        & .desktop{
          display: none;

          @include media(md) {
            display: initial;
          }
        }

        & .mobileWrap{
          @include media(md) {
            display: none;
          }

          & .mobileItem{
            height: 3.3rem;

            &.title6{
              display: grid;
              text-align: center !important;
            }

            &:first-child{
              border-bottom: 1px solid #F7931A;
              align-content: end;

            }
            &:last-child{
              align-content: start;
            }
          }
        }
      }

      & .contentBox{
        display: grid;
        align-content: start;
        grid-gap: 1.5rem;

        overflow-y: scroll;
        height: 13.6rem;
        scroll-padding-bottom: 0;

        &::-webkit-scrollbar-track {border-radius: 0.6rem; background: #737b7e}
        &::-webkit-scrollbar {width: 0.5rem;}
        &::-webkit-scrollbar-thumb {border-radius: 4px;background: #d9d9d9;}
        //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}


        & .contentBoxItem{
          display: grid;
          grid-auto-rows: max-content;
          grid-template-columns: 30% 30% 33%;
          align-items: center;
          justify-content: space-between;
          grid-gap: 1rem;

          padding-right: 1.6rem;

          @include media(sm) {
            grid-template-columns: repeat(3, calc(33.333% - 0.33rem));
          }

          @include media(md) {
            grid-template-columns: calc(20% - 0.8rem) calc(14% - 0.8rem) calc(22% - 0.8rem) calc(22% - 0.8rem) calc(22% - 0.8rem);
          }

          @include media(lg) {
            grid-template-columns: repeat(5, calc(20% - 0.8rem));
          }

          @include media(fullHD) {
            padding-right: 2.6rem;
          }

          &.contentBoxItemZero{
            & .contentBoxItemValues{
              color: #F7931A !important;

              &.level{
                border-color: #F7931A !important;
              }

              &:first-child{
                color: #F5F5F5 !important;
              }
            }
          }


          & .mobileWrap{
            @include media(md) {
              display: none;
            }
            & .contentBoxItemValues{
              text-align: center !important;

              &:first-child{
                border-bottom: 1px solid #F7931A;
              }
            }
          }

          & .contentBoxItemValues{
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #03B759;

            text-align: center;

            &.desktop{
              display: none;

              @include media(md) {
                display: initial;
              }
            }

            &.level{

              align-items: center;
              justify-items: center;
              text-align: center;

              border: 1px solid #03B759;
              border-radius: 1rem;

              width: 4rem;
              height: 4rem;

              margin: 0 auto;

              display: none;

              @include media(md) {
                display: grid;
              }
            }



            &:first-child{
              text-align: left;
              color: #F5F5F5;

              overflow: hidden;
              text-overflow: ellipsis;
            }

            &:last-child{
              @include media(md) {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}