@import "../../theme/scss/resources";

.container{
  background: #0d0a07;
  padding: 10rem 0;

  & .contentWrap{
    & .title2{
      text-align: center;
      margin-bottom: 6.5rem;

      & span{
        color: #FF8C00;
      }
    }

    & .contentBox{
      display: grid;
      grid-gap: 2rem;

      @include media(lg) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      & .contentBoxItem{
        display: grid;
        align-content: end;

        border: 1px solid #FF8C00;
        border-radius: 1rem;
        padding: 3rem;

        &:first-child{
          grid-row: 1 / 3;
          background: #0d0a07 url("../../theme/assets/other/honee_main_future_bg1.png") no-repeat;
          background-size: cover;

          & .contentBoxItemNote{
            max-width: 45rem;
          }
        }

        &:nth-child(2){
          background: #0d0a07 url("../../theme/assets/other/honee_main_future_bg2.png") no-repeat;
          background-size: cover;
        }

        &:last-child{
          background: #0d0a07 url("../../theme/assets/other/honee_main_future_bg3.png") no-repeat;
          background-size: cover;
        }

        & .contentBoxItemTitle{
          color: #F5F5F5;

          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 2.9rem;

          margin-bottom: 2.5rem;
        }

        & .contentBoxItemNote{
          color: #F5F5F5;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.4rem;
          max-width: 30rem;
        }
      }
    }
  }
}