@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    grid-column: 1 / 3;
  }

  & .contentWrap{
    & .contentBox{
      display: grid;
      grid-gap: 2rem;

      @include media(xl) {
        grid-template-columns: 1fr 31.5rem;
      }

      @include media(fullHD) {
        grid-template-columns: 1.5fr calc(40% - 1.5rem);
      }

      & .infoWrap{
        display: grid;

        grid-gap: 2rem;

        @include media(sm) {
          grid-template-columns: 1fr 1fr;
        }

        & .infoWrapItem{
          &.point{
            cursor: pointer !important;

            &:hover{
              & .infoWrapItemValue{
                border: 1px solid #FF8C00 !important;
                color: #FF8C00 !important;
              }
            }
          }
          & .titleWSF{
            margin-bottom: 1.75rem;
          }

          & .infoWrapItemValue{
            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            border-radius: 1rem;
            border: 1px solid #00FF7A;
            background: rgba(0, 255, 122, 0.10);

            height: 5.2rem;
            width: 100%;

            color: #FFFFFF;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      & .noteWrap{
        display: grid;
        grid-template-columns: 1fr 4.5rem;
        align-items: center;
        grid-gap: 1rem;

        @include media(sm) {
          grid-gap: 2rem;
        }

        @include media(xl) {
          align-items: end;
        }

        & .note{
          color: #FFFFFF;

          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;

          @include media(fullHD) {
            font-size: 1.8rem;
          }
        }

        & .noteImgWrap{
          display: grid;
        }
      }
    }
  }
}