@import 'fonts.scss';

@import 'palette.scss';

@import 'mediaQueryManager.scss';

@mixin fullHeight {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);

  overflow: scroll;
}




@mixin pagePadding{
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @include media(sm) {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    @include media(md) {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    @include media(lg) {
        padding-left: 7.5rem;
        padding-right: 7.5rem;
    }

    @include media(xl) {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    @include media(xxl) {
        padding-left: 10rem;
        padding-right: 10rem;
    }
    @include media(fullHD) {
        padding-left: 20rem;
        padding-right: 20rem;
    }
}


@mixin walletPadding{
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @include media(sm) {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    @include media(md) {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    @include media(lg) {
        padding-left: 31.5rem;
        padding-right: 3.5rem;
    }

    @include media(xl) {
        padding-left: 31.5rem;
        padding-right: 3.5rem;
    }

    @include media(xxl) {
        padding-left: 31.5rem;
        padding-right: 3.5rem;
    }
    @include media(fullHD) {
        padding-left: 46.7rem;
        padding-right: 12.7rem;
    }
}

@mixin modalRootContainer{
    z-index: 1000;
    position: relative;
}

@mixin modalPopupContainer{
    border-radius: 1rem;
    border: 1px solid #915207;

    background: linear-gradient(138deg, rgba(201, 110, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%);

    backdrop-filter: blur(27px);

    padding: 3.5rem 1.5rem;
    width: 35rem;

    @include media(xs) {
        width: 40rem;
        padding: 3.5rem;
    }

    @include media(md) {
        width: 64.4rem;
    }
}

@mixin modalCloseBtn{
    cursor: pointer;

    position: absolute;
    top: 1rem;
    right: 1rem;

    width: 2rem;
    height: 2rem;

    display: grid;
    align-items: center;
    justify-items: center;

    & :first-child{
        position: absolute;

        height: 0.15rem;
        width: 1.529rem;

        background: #F7931A;
        border-radius: 1px;
        transform: rotate(-45deg);
    }

    & :last-child{
        position: absolute;

        height: 0.15rem;
        width: 1.529rem;

        background: #F7931A;
        border-radius: 1px;
        transform: rotate(45deg);
    }
}

@mixin mainTitle{
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3.5rem;
    color: #F5F5F5;
    margin-bottom: 5rem !important;
    text-align: center;

    @include media(lg) {
        text-align: left;
        font-size: 4.2rem;
        line-height: 5.7rem;
    }

    & span{
        color: #58C1EF;
    }
}

@mixin subTitle{
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    color: #58C1EF;

    margin-bottom: 3.2rem !important;
    text-align: center;

    @include media(lg) {
        text-align: left;
        font-size: 2.4rem;
        line-height: 3.2rem;
    }
}

@mixin dateWrap{
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    justify-content: center;
    grid-gap: 3rem;

    @include media(lg) {
        justify-content: left;
    }

    & .date{
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;

        color: #FF8C00;
        position: relative;

        &:after{
            content: '.';
            position: absolute;
            color: #FF8C00;
            font-size: 3rem;
            top: -0.8rem;
            right: -2.4rem;
        }
    }

    & .read{
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 0.5rem;

        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;

        color: #FF8C00;

        & .readImgWrap{
            display: grid;
        }
    }
}

@mixin newsTitle{
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.8rem;

    color: #F5F5F5;

    height: 6rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin newsDescription{
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;

    color: #F5F5F5;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 6rem;
}

@mixin newsCategory{
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;

    color: #F7931A;
    text-align: center;

    @include media(lg) {
        text-align: left;
    }
}

@mixin mainNote{
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;

    color: #F5F5F5;

    @include media(lg) {
        text-align: left;
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
}

@mixin mainBtn{
    display: grid;
    align-items: center;
    cursor: pointer;
    background: #58C1EF;
    border-radius: 1rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;

    text-align: center;

    color: #F5F5F5;
    height: 4.9rem;
    max-width: 25.7rem;
    margin: 0 auto;

    &:hover{
        border: 1px solid #58C1EF;
        color: #58C1EF;
        background: transparent;
    }

    @include media(lg) {
        margin: initial;
    }
}

@mixin cardPadding{
    padding: 2rem;

    @include media(sm) {
        padding: 3.5rem;
    }

    @include media(fullHD) {
        padding: 3.5rem 6.5rem;
    }
}

@mixin cardPaddingWithScroll{

    padding: 2rem 1rem 2rem 2rem;

    @include media(sm) {
        padding: 3.5rem 1.5rem 3.5rem 3.5rem;
    }

    @include media(fullHD) {
        padding: 3.5rem 3.5rem 3.5rem 6.5rem;
    }
}





