@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    grid-column: 1 / 3;
  }

  & .contentWrap{
    height: 60.5rem;

    @include media(xl) {
      height: 62.5rem;
    }

    & .cardBodyWithScroll{
      height: 100%;

      & .titleWSF{
        margin-bottom: 2.5rem;

        & span{
          color: #FFD600;
        }
      }

      & .titleRow{
        display: none;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;

        margin-bottom: 2rem;
        padding-right: 2rem;

        @include media(sm) {
          grid-template-columns: 1.25fr 1fr 1fr 1fr;
        }

        @include media(md) {
          grid-template-columns: repeat(4, 1fr);
        }

        @include media(xl) {
          display: grid;
          grid-template-columns: 1.5fr 1fr 3.5fr 1fr 1fr 1fr 1fr 0.75fr;
        }

        @include media(fullHD) {
          padding-right: 6rem;
        }

        & .title6{
          margin-bottom: 0;
          text-align: center;

          &:first-child{
            @include media(xl) {
              text-align: left;
            }
          }

          &:last-child{
            @include media(xl) {
              text-align: right;
            }
          }

          &:nth-child(-n+6){
            border-bottom: 1px solid #FF8C00;

            @include media(sm) {
              border-bottom: none;
            }
          }

          &:nth-child(-n+4){
            border-bottom: 1px solid #FF8C00;

            @include media(xl) {
              border-bottom: none;
            }
          }
        }
      }

      & .contentBox{
        display: grid;


        overflow-y: scroll;
        height: 50.5rem;
        scroll-padding-bottom: 0;
        grid-auto-rows: max-content;

        &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
        &::-webkit-scrollbar {width: 0.5rem;}
        &::-webkit-scrollbar-thumb {border-radius: 4px; background: #FF8C00;}
        //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}

        @include media(xl) {
          grid-gap: 1.5rem;
          height: 47.5rem;
        }


        & .contentBoxItem{
          display: grid;
          grid-auto-rows: max-content;
          grid-template-columns: 1fr;
          align-items: normal;
          justify-content: space-between;
          height: max-content;

          margin-right: 1.6rem;
          grid-gap: 1rem;

          padding: 2rem 0;
          border-bottom: 1px solid #F7931A;

          @include media(xl) {
            align-items: center;
            grid-template-columns: 1.5fr 1fr 3.5fr 1fr 1fr 1fr 1fr 0.75fr;
            grid-gap: 0;
            padding: 0;
            border: none;
          }

          @include media(fullHD) {
            padding-right: 4rem;
          }

          &:first-child{
            padding-top: 0;
          }

          &:last-child{
            padding-bottom: 0;
            border: none;
          }

          &.colorGreen{
            & .contentBoxItemNameWrap{
              & .contentBoxItemNameBox{
                color: #03B759 !important;
              }
            }

            & .contentBoxItemValues{
              color: #03B759 !important;
            }
          }

          &.colorGreen{
            & .contentBoxItemNameWrap{
              & .contentBoxItemNameBox{
                color: #03B759 !important;
              }
            }

            & .contentBoxItemValues{
              color: #03B759 !important;
            }
          }

          &.colorOrange{
            & .contentBoxItemNameWrap{
              & .contentBoxItemNameBox{
                color: #F7931A !important;
              }
            }

            & .contentBoxItemValues{
              color: #F7931A !important;
            }
          }

          &.colorViolet{
            & .contentBoxItemNameWrap{
              & .contentBoxItemNameBox{
                color: #b57edc !important;
              }
            }

            & .contentBoxItemValues{
              color: #b57edc !important;
            }
          }

          &.colorBlue{
            & .contentBoxItemNameWrap{
              & .contentBoxItemNameBox{
                color: #58c1ef !important;
              }
            }

            & .contentBoxItemValues{
              color: #58c1ef !important;
            }
          }




          & .contentBoxItemNameWrap{
            display: grid;
            grid-template-columns: repeat(2, max-content);
            align-items: center;
            grid-gap: 1rem;

            & .contentBoxItemNameImgWrap{
              display: grid;
            }

            & .contentBoxItemNameBox{
              font-style: normal;
              font-weight: 400;
              font-size: 1.1rem;
              line-height: 1.9rem;

              color: #F5F5F5;

              @include media(md) {
                font-size: 1.4rem;
              }
            }
          }

          & .contentBoxItemWrap{
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            grid-gap: 1rem;

            @include media(xl) {
              grid-template-columns: initial;
              grid-gap: 0;
            }

            &:first-child{
              & .contentBoxItemWrapValue{
                @include media(xl) {
                  text-align: left !important;
                }
              }
            }

            &:last-child{
              & .contentBoxItemWrapValue{
                @include media(xl) {
                  text-align: right !important;
                }
              }
            }

            & .contentBoxItemWrapName{
              @include media(xl) {
                display: none;
              }
            }

            & .contentBoxItemWrapValue{
              font-style: normal;
              font-weight: 500;
              font-size: 1.1rem;
              line-height: 1.6rem;
              text-align: right;

              color: #F5F5F5;

              @include media(xl) {
                text-align: center;
              }

              &.contentBoxItemWrapValueSplit{
                display: grid;
                grid-template-columns: max-content max-content;
                align-items: center;
                justify-self: end;
                grid-gap: 0.5rem;

                @include media(xl) {
                  justify-self: center;
                }

                & .imgWrap{
                  display: grid;
                  width: 2rem;

                  & img{
                    width: 2rem;
                  }
                }
              }
            }
          }

          & .contentBoxItemValues{
            font-style: normal;
            font-weight: 500;
            font-size: 1.1rem;
            line-height: 1.6rem;
            text-align: center;

            color: #F5F5F5;

            &:first-child{
              @include media(xl) {
                text-align: left;
              }
            }

            &:last-child{
              @include media(xl) {
                text-align: right;
              }
            }

            &:nth-child(-n+6){
              border-bottom: 1px solid #FF8C00;

              @include media(sm) {
                border-bottom: none;
              }
            }

            &:nth-child(-n+4){
              border-bottom: 1px solid #FF8C00;

              @include media(xl) {
                border-bottom: none;
              }
            }



            &.contentBoxItemValuesSpecial{
              font-style: normal;
              font-weight: 400;
              font-size: 1.1rem;
              line-height: 2.2rem;

              color: #03B759;

              @include media(md) {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}