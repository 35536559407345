@import "../../theme/scss/resources";

.container{
  //@include pagePadding;
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;

  @include media(lg) {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  & .contentWrap{
    & .title1{
      font-weight: 600;
      margin-bottom: 5rem;
      text-align: center;

      @include media(lg) {
        text-align: left;
      }
    }

    & .title2{
      font-weight: 600;
      font-size: 2.5rem;
      margin-bottom: 3.2rem;
      text-align: center;

      @include media(lg) {
        text-align: left;
      }
    }

    & .contentBox{
      display: grid;
      grid-gap: 5rem;

      @include media(lg) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content 1fr;
        grid-gap: 3.2rem;
      }

      & .contentBoxItem{
        cursor: pointer;

        @include media(xl) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 2.4rem;
        }

        & .contentBoxItemImgWrap{
          border-radius: 1rem;


          overflow: hidden;

          display: grid;
          justify-items: center;

          background-size: 0 !important;
          max-width: 40rem;
          margin: 0 auto;

          @include media(lg) {
            justify-items: left;
            max-width: initial;
            margin: initial;
          }

          & img{
            width: 100%;
            max-width: 40rem;
            border: 1px solid #FF8C00;
            border-radius: 1rem;

            @include media(lg) {
              max-width: initial;
            }
          }
        }

        & .contentBoxItemData{
          @include media(xl) {
            display: grid;
            align-content: space-between;
          }

          & .dateWrap{
            @include dateWrap;
            margin-top: 4rem;
            margin-bottom: 2rem;

            @include media(xl) {
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          & .title3{
            font-weight: 600;
            height: 5.75rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 1.2rem;

            @include media(xl) {
              margin-bottom: 0;
            }
          }

          & .contentBoxItemDescription{
            @include newsDescription;
            margin-bottom: 2.4rem;

            @include media(xl) {
              margin-bottom: 0;
            }
          }

          & .contentBoxItemCategory{
            @include newsCategory;
            display: none;
          }
        }
      }
    }
  }
}