@import "../../theme/scss/resources";

.container{
  background: #0d0a07 url("../../theme/assets/other/honee_main_register_bg.png") no-repeat;
  background-size: cover;
  padding: 8.5rem 0;

  @include media(lg) {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .contentWrap{
    @include media(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 3rem;
    }

    & .textWrap{
      @include media(lg) {
        grid-column: 1;
        grid-row: 1 / 3;
      }

      & .title2{
        margin-bottom: 2rem;

        @include media(lg) {
          margin-bottom: 6rem;
        }
        & span{
          color: #FF8C00;
        }
      }

      & .note{
        color: #F5F5F5;

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.3rem;
      }
    }

    & .stepWrap{
      display: grid;
      grid-gap: 2.5rem;
      margin-top: 3rem;

      @include media(lg) {
        margin-top: 0;
        grid-column: 2;
        grid-row: 1 / 4;
      }

      & .stepWrapItem{
        border: 1px solid #FF8C00;
        border-radius: 1rem;
        padding: 2.5rem;


        & .imgWrap{
          display: grid;
        }

        & .stepWrapItemContent{
          color: #F5F5F5;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.4rem;

          padding-top: 1.5rem;
        }
      }
    }

    & .btnWrap{
      margin-top: 3rem;

      @include media(lg) {
        display: grid;
        align-self: end;
        max-width: 35rem;
      }
    }
  }
}