@import "../../theme/scss/resources";

.container{
  display: grid;
  grid-auto-rows: max-content;

  & .contentWrap{
    display: grid;
    grid-gap: 2rem;
  }
}