@import "../../theme/scss/resources";

.container{
  border: 1px solid #00FFFF;
  @include media(xl) {
    display: grid;
    grid-row: 7;
    grid-column: 1 / 3;
  }

  & .honeeCardBody{
    display: grid;
    overflow: hidden;

    height: 100%;

    border-radius: 1rem;

    & .contentWrap {
      display: grid;
      //grid-template-rows: max-content max-content 1fr;
      //height: 100%;

      & .titleRow{
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap: 0.25rem;

        padding-right: 2rem;
        margin-bottom: 1.25rem;

        @include media(sm) {
          padding-right: 3.5rem;
        }

        @include media(fullHD) {
          padding-right: 6.5rem;
        }

        & .titleWSF{
          margin-bottom: 0;

          & span{
            color: #00FFFF;
          }
        }

        & .imgWrap{
          display: grid;
          //transform: rotate(-30deg);

          & img{
            width: 4rem;
          }
        }
      }

      & .contentBox{
        display: grid;
        grid-gap: 2rem;

        @include media(xl) {
          grid-template-columns: 40rem 1fr;
        }

        & .freezeRow{
          & .freezeWrap{
            display: grid;
            grid-gap: 1.75rem;

            @include media(xl) {
              height: 100%;
              align-content: space-between;
            }

            & .freezeNote{
              color: #F5F5F5;

              font-size: 1.4rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            & .freezeBtn{
              color: #14140A;
              border: 1px solid #0FF;

              background: #0FF;
            }
          }
        }

        & .dataRow{
          display: grid;

          @include media(fullHD) {
            justify-self: end;
            width: 70rem;
          }

          & .dataTitle{
            margin-bottom: 2.5rem;
          }

          & .dataWrap{
            display: grid;
            align-items: center;
            grid-gap: 1.5rem;

            @include media(md) {
              grid-template-columns: 1fr 1fr;
              grid-gap: 2.5rem;
            }

            & .dataWrapItem{
              & .dataWrapItemName{
                color: #0FF;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                margin-bottom: 1rem;
              }

              & .dataWrapItemValue{
                display: grid;
                align-items: center;
                justify-items: center;
                text-align: center;

                height: 5.3rem;

                border-radius: 1rem;
                border: 1px solid #0FF;

                color: #F5F5F5;

                font-size: 1.8rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }

      & .stakingWrap{
        margin-top: 4rem;

        & .stakingTitleRow{
          display: none;
          grid-template-columns: 38% 38% 24%;
          justify-content: space-between;

          margin-bottom: 2rem;
          padding-right: 2rem;

          @include media(md) {
            grid-template-columns: 1fr 1.5fr 0.5fr 0.5fr 1fr 1fr 1fr 1fr;
          }

          @include media(xl) {
            display: grid;
          }

          @include media(fullHD) {
            padding-right: 6rem;
            grid-template-columns: repeat(8, 1fr);
          }

          & .title6{
            margin-bottom: 0;
            text-align: center;

            &:first-child{
              text-align: left;
            }

            &:last-child{
              text-align: right;
            }
          }
        }

        & .stakingBox{
          display: grid;


          overflow-y: scroll;
          height: 70rem;
          scroll-padding-bottom: 0;
          grid-auto-rows: max-content;

          &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #0FF}
          &::-webkit-scrollbar {width: 0.5rem;}
          &::-webkit-scrollbar-thumb {border-radius: 4px; background: #0FF;}
          //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}

          @include media(xl) {
            grid-gap: 1.5rem;
            height: 23rem;
          }


          & .stakingBoxItem{
            display: grid;
            height: max-content;
            margin-right: 1.6rem;

            grid-gap: 1rem;

            padding: 2rem 0;
            border-bottom: 1px solid #0FF;

            &:first-child{
              padding-top: 0;
            }

            &:last-child{
              padding-bottom: 0;
              border: none;
            }

            @include media(xl) {
              grid-auto-rows: max-content;
              align-items: center;
              justify-content: space-between;
              grid-template-columns: 1fr 1.5fr 0.5fr 0.5fr 1fr 1fr 1fr 1fr;

              grid-gap: 0;
              padding: 0;
              border: none;
            }

            @include media(fullHD) {
              margin-right: 5.6rem;
              grid-template-columns: repeat(8, 1fr);
            }


            & .stakingBoxItemWrap{
              display: grid;
              grid-auto-flow: column;
              align-items: center;
              justify-content: space-between;

              &:first-child{
                @include media(xl) {
                  justify-content: left;
                }
              }

              &:last-child{
                @include media(xl) {
                  justify-content: right;
                }
              }

              @include media(xl) {
                justify-content: center;
              }

              & .stakingBoxItemWrapName{
                @include media(xl) {
                  display: none;
                }
              }

              & .stakingBoxItemWrapValue{
                display: grid;
                align-items: center;
                justify-items: center;
                text-align: center;

                font-size: 1.1rem;

                font-style: normal;
                font-weight: 500;
                font-size: 1.1rem;
                line-height: 1.6rem;

                color: #F5F5F5;

                border-radius: 1rem;
                border: 1px solid #FFF;

                background: rgba(255, 255, 255, 0.10);

                height: 3.2rem;
                padding: 0 0.75rem;

                @include media(xl) {
                  padding: 0 1.5rem;
                }

                &.stakingBoxItemWrapValueOrange{
                  border: 1px solid #FF8C00 !important;
                  background: rgba(255, 140, 0, 0.10) !important;
                }

                &.stakingBoxItemWrapValueBlue{
                  border: 1px solid #0FF !important;
                  background: rgba(0, 255, 255, 0.10) !important;
                }

                &.stakingBoxItemWrapValueYellow{
                  cursor: pointer;

                  border: 1px solid #FF0 !important;
                  background: rgba(255, 255, 0, 0.05) !important;
                  color: #FF0 !important;
                }

                &.stakingBoxItemWrapValueWB{
                  border: none;
                  background: initial;
                }

                &.stakingBoxItemWrapValueDisable{
                  cursor: initial !important;

                  color: #8C9497 !important;
                  background-color: transparent !important;
                  border: 1px solid #8C9497 !important;
                }

                & .investBox{

                  & input{
                    cursor: pointer;
                    position: relative;
                    width: 6.9rem;
                    height: 3.2rem;
                    -webkit-appearance: none;
                    outline: none;
                    background: #211406;
                    border-radius: 2rem;
                    transition: 0.5s;
                    border: 1px solid #8C9497;

                    &:checked{
                      border: 1px solid #00FF7A;

                      &:before{
                        left: 4rem;
                        background: #00FF7A;
                      }
                    }

                    &:before{
                      content: '';
                      position: absolute;
                      top: 0.3rem;
                      left: 0.5rem;
                      width: 2.4rem;
                      height: 2.4rem;
                      border-radius: 5rem;
                      background: #8C9497;
                      transition: 0.5s;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}