@import "../../theme/scss/resources";

.container{

  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 4rem;

      color: #FF8C00;

      margin-bottom: 1.75rem;

      text-align: center;

      @include media(md) {
        text-align: left;

        font-size: 3.8rem;
        line-height: 5.2rem;

        margin-bottom: 3rem;
      }
    }

    & .note{
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2rem;

      color: #F5F5F5;

      max-width: 66rem;
      margin-bottom: 1.5rem;


      @include media(md) {
        font-size: 1.8rem;
        line-height: 2.5rem;

        margin-bottom: 3rem;
      }
    }

    & .listWrap{
      display: grid;
      grid-gap: 2rem;

      @include media(md) {
        grid-gap: 3rem;
      }

      & .listItem{

        &:first-child{
          & .listBox{
            & .listItemBox{
              & .list{
                & img{
                  top: 0.1rem !important;
                }
              }
            }
          }
        }

        & .listTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.9rem;
          line-height: 2.5rem;

          color: #F5F5F5;
          margin-bottom: 1rem;
          text-align: center;

          @include media(md) {
            font-size: 2.4rem;
            line-height: 3.3rem;

            margin-bottom: 1.5rem;
            text-align: left;
          }
        }

        & .listBox{
          display: grid;
          grid-gap: 1rem;

          @include media(md) {
            grid-gap: 1.5rem;
          }

          & .listItemBox{
            & .list{
              display: grid;
              grid-template-columns: max-content 1fr;
              align-items: baseline;
              grid-gap: 1.5rem;

              @include media(md) {
                grid-gap: 2.3rem;
              }

              & img{
                display: grid;

                position: relative;
                top: 0.525rem;
              }

              & .listContent{
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 2rem;

                color: #F5F5F5;

                @include media(md) {
                  font-size: 1.8rem;
                  line-height: 2.5rem;
                }
              }
            }

            & .listWarning{
              font-style: normal;
              font-weight: 300;
              font-size: 1.25rem;
              line-height: 1.75rem;

              color: #FF8C00;

              margin-top: 0.5rem;

              @include media(md) {
                margin-top: 1rem;

                font-size: 1.4rem;
                line-height: 1.9rem;
              }
            }
          }
        }
      }
    }

    & .btnWrap{
      display: grid;
      grid-gap: 3rem;

      padding-top: 3rem;
      padding-bottom: 1rem;

      @include media(md) {
        grid-template-columns: 30rem 30rem;
        padding-top: 6rem;
        padding-bottom: 1.5rem;
      }

      @include media(lg) {
        grid-gap: 6rem;
      }

      & :first-child{
        background: transparent !important;
        border: 1px solid #FF8C00 !important;
        color: #FF8C00 !important;
      }

      & .btn{
        cursor: pointer;

        background: #FF8C00;
        border: 1px solid transparent;
        border-radius: 1rem;

        text-align: center;

        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #F5F5F5;

        width: 100%;
        max-width: 30rem;
        margin: 0 auto;

        padding: 1.25rem 0;

        @include media(md) {
          margin: initial;
        }
      }
    }

    & .info{
      font-style: normal;
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 1.9rem;

      color: #F5F5F5;

      max-width: 73rem;
      padding-bottom: 3.5rem;

      & span{
        cursor: pointer;
        color: #FF8C00;
        border-bottom: 1px solid #FF8C00;
      }
    }
  }
}