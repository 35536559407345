@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .loadingWrap{
    display: grid;
    align-items: center;
    text-align: center;

    height: 50rem;

    font-style: normal;
    font-weight: 500;
    font-size: 3rem;
    line-height: 3rem;
    color: #F5F5F5;
  }

  & .modalContentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin-bottom: 1.25rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin-bottom: 2.5rem;
      }
    }

    & .subTitle{
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;
      color: #F5F5F5;
      margin-bottom: 2.5rem;

      @include media(md) {
        font-size: 1.8rem;
        line-height: 2.5rem;
        margin-bottom: 5rem;
      }
    }

    & .tableWrap{
      border: 1px solid #F7931A;
      & .titleRow{
        display: grid;
        grid-template-columns: 4.7rem 1fr 1fr 1fr;
        align-items: center;

        @include media(md) {
          grid-template-columns: 6rem 1fr 1fr 1fr;
        }

        & .titleRowItem{
          display: grid;
          align-items: center;
          padding: 0.5rem;

          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.2rem;
          text-align: center;

          color: #F5F5F5;
          height: 6.2rem;
          border-bottom: 1px solid #5b666a;
          border-right: 1px solid #5b666a;

          &:last-child{
            border-right: none;
          }

          @include media(md) {
            font-size: 1.1rem;
            line-height: 1.7rem;
            height: 3.9rem;
            padding: 1rem;
          }
        }
      }

      & .tableBox{
        & .tableBoxRow{
          display: grid;
          grid-template-columns: 4.7rem 1fr 1fr 1fr;
          align-items: center;

          @include media(md) {
            grid-template-columns: 6rem 1fr 1fr 1fr;
          }

          & .tableBoxRowItem{
            display: grid;
            align-items: center;
            padding: 0.5rem;

            font-style: normal;
            font-weight: 600;
            font-size: 1.1rem;
            line-height: 1.1rem;

            text-align: center;

            color: #F5F5F5;
            height: 3.8rem;
            border-bottom: 1px solid #5b666a;
            border-right: 1px solid #5b666a;

            &:last-child{
              border-right: none;
            }

            @include media(md) {
              font-size: 1.25rem;
              line-height: 1.25rem;
              padding: 1rem;
            }

            & .desk{
              display: none;

              @include media(md) {
                display: inline;
              }
            }

            & .pos{
              @include media(md) {
                padding-left: 0.5rem;
              }
            }

            &.tableBoxRowItemColored{
              color: #F7931A;
              justify-content: center;
              grid-template-columns: max-content max-content;
            }
          }
        }
      }
    }

    & .textWrap{
      display: grid;
      grid-gap: 1rem;
      margin-top: 2.5rem;

      @include media(md) {
        margin-top: 5rem;
        grid-gap: 1.5rem;
      }

      & .textBox{
        & .textBoxTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 2rem;

          color: #F7931A;

          @include media(md) {
            font-size: 1.8rem;
            line-height: 2.5rem;
          }
        }

        & .textBoxReqWrap{
          margin-top: 0.25rem;
          margin-bottom: 0.5rem;

          @include media(md) {
            margin-bottom: 1rem;
          }
          & .textBoxReqWrapItem{
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2rem;

            color: #F5F5F5;
            padding-left: 1.75rem;
            position: relative;

            @include media(md) {
              font-size: 1.8rem;
              line-height: 2.5rem;
              padding-left: 2rem;
            }

            &:before{
              content: '.';
              position: absolute;
              top: -1.3rem;
              left: 0;
              color: #F7931A;
              font-size: 5rem;
            }
          }
        }

        & .textBoxSubTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 2rem;

          color: #F5F5F5;
          margin-top: 0.25rem;

          @include media(md) {
            font-size: 1.8rem;
            line-height: 2.5rem;
          }
        }

        & .textBoxList{
          & .textBoxListItem{
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2rem;

            color: #F5F5F5;

            @include media(md) {
              font-size: 1.8rem;
              line-height: 2.5rem;
            }
          }
        }

        & .textBoxNote{
          font-style: normal;
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 2rem;

          color: #8C9497;
          margin-top: 0.25rem;

          @include media(md) {
            font-size: 1.8rem;
            line-height: 2.5rem;
          }
        }
      }
    }
  }
}