@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin-bottom: 1.25rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin-bottom: 2.5rem;
      }
    }

    & .available{
      color: #00FF7A;

      text-align: center;
      font-size: 1.7rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 1rem 1.5rem;

      border: 1px solid #00FF7A;
      border-radius: 1rem;

      margin-bottom: 2.5rem;
    }

    & .inputWrap{
      & input {
        color: #F5F5F5;

        height: 8rem;
        width: 100%;

        text-align: center;
        font-size: 3.4rem;
        line-height: 3.4rem;
        font-style: normal;
        font-weight: 400;

        padding: 1.5rem;
        background: linear-gradient(138deg, rgba(247, 147, 26, 0.15) -23.04%, rgba(247, 147, 26, 0.01) 118.95%);
        border: 1px solid #F7931A;
        border-radius: 1rem;
      }

      & .inputNote{
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.6rem;

        color: #F5F5F5;
        margin-top: 0.5rem;

        & span{
          color: #F7931A;
        }
      }
    }

    & .saveBtn{
      cursor: pointer;
      width: 100%;
      padding: 1.1rem 0;
      text-align: center;
      background: #F7931A;
      border: 1px solid #F7931A;
      border-radius: 1rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin: 0 auto;
      margin-top: 2.5rem;
      max-width: 19rem;
    }

    & .disBtn{
      cursor: initial !important;
      color: #8C9497 !important;
      background-color: transparent !important;
      border-color: #8C9497 !important;
    }
  }
}