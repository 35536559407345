@import "../../theme/scss/resources";

.container{
  @include pagePadding;
  padding-top: 1.5rem !important;
  padding-bottom: 3.5rem !important;

  @include media(md) {
    padding-top: 3rem !important;
    padding-bottom: 7rem !important;
  }

  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 4rem;

      color: #FF8C00;

      margin-bottom: 1.75rem;

      text-align: center;

      @include media(md) {
        text-align: left;

        font-size: 3.8rem;
        line-height: 5.2rem;

        margin-bottom: 3.5rem;
      }
    }

    & .noteWrap{
      display: grid;
      grid-gap: 2.5rem;

      max-width: 62.5rem;

      & .noteItem{
        color: #F5F5F5;
        font-size: 1.4rem;
        line-height: 2rem;
        font-style: normal;
        font-weight: 400;

        text-align: center;

        @include media(md) {
          text-align: left;
        }
      }
    }

    & .checkRow{
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;
      grid-gap: 1.5rem;

      margin-top: 2rem;
      margin-bottom: 2rem;

      @include media(md) {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      & .checkBox{
        cursor: pointer;

        display: grid;
        align-items: center;
        justify-items: center;
        width: 2.5rem;
        height: 2.5rem;

        border: 1px solid #F5F5F5;
        border-radius: 0.5rem;

        & img{
          display: grid;
        }
      }

      & .checkRowContent{
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2rem;

        color: #F5F5F5;

        @include media(md) {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
      }
    }

    & .inputWrap{
      position: relative;
      max-width: 62.5rem;

      &.inputWrapWithErr{
        & input{
          border: 1px solid #CF0000;
        }
      }

      & .inputTitle{
        color: #F5F5F5;
        font-size: 1.4rem;
        line-height: 2rem;
        font-style: normal;
        font-weight: 400;

        margin-bottom: 0.5rem;
      }

      & input{
        background: rgba(15, 32, 52, 0.1);
        border: 1px solid #FF8C00;
        backdrop-filter: blur(7px);

        border-radius: 1rem;

        height: 4.8rem;
        width: 100%;

        font-size: 1.4rem;
        line-height: 1.9rem;

        color: #F5F5F5;
        padding: 0 1.5rem;

        &::placeholder {
          color: #696B6B !important;
          opacity: 1;
          font-size: 1.2rem !important;
          line-height: 1.6rem !important;
          vertical-align: middle !important;
        }
        &:-ms-input-placeholder {
          color: #696B6B !important;
          font-size: 1.2rem !important;
          line-height: 1.6rem !important;
          vertical-align: middle !important;
        }
        &::-ms-input-placeholder {
          color: #696B6B !important;
          font-size: 1.2rem !important;
          line-height: 1.6rem !important;
          vertical-align: middle !important;
        }
      }

      & .inputErr{
        position: absolute;
        right: 0;
        bottom: -1.5rem;

        color: #CF0000;
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
    }

    & .btn{
      cursor: initial !important;

      display: grid;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.5rem;

      text-align: center;

      color: #6E7679 !important;

      max-width: 100%;
      height: 4.8rem;

      border: 1px solid #6E7679 !important;
      background: transparent !important;
      border-radius: 1rem;

      margin: 1rem auto 0;

      @include media(sm) {
        max-width: 30rem;
      }

      @include media(md) {
        margin: initial;
        margin-top: 3rem;
      }

      &.btnActive{
        cursor: pointer !important;
        background: #FF8C00 !important;
        border: 1px solid #FF8C00 !important;
        color: #F5F5F5 !important;
      }
    }
  }
}