@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    display: grid;
    order: 3;
  }

  & .honeeCardBody{
    height: 53rem;
    overflow: hidden;

    @include media(xl) {
      height: 87rem;
    }

    & .cardBodyWithScroll{
      & .titleWSF{
        margin-bottom: 2rem;
      }

      & .titleRow{
        display: none;
        grid-template-columns: 33.333% 40% 26.666%;
        justify-content: space-between;

        margin-bottom: 2rem;
        padding-right: 2rem;

        @include media(sm) {
          grid-template-columns: repeat(4, 25%);
        }

        @include media(xl) {
          display: grid;
          grid-template-columns: repeat(4, 1fr) 0.75fr 1.25fr;
        }

        @include media(fullHD) {
          padding-right: 6rem;
        }

        & .title6{
          text-align: center;
          margin-bottom: 0;

          &:first-child{
            text-align: left;
          }

          &:last-child{
            text-align: right;
          }
        }
      }

      & .contentBox{
        display: grid;
        grid-auto-rows: max-content;

        overflow-y: scroll;
        height: 41.2rem;
        scroll-padding-bottom: 0;

        &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
        &::-webkit-scrollbar {width: 0.5rem;}
        &::-webkit-scrollbar-thumb {border-radius: 4px; background: #FF8C00;}
        //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}

        //@include media(sm) {
        //  height: 20.1rem;
        //}

        @include media(xl) {
          height: 72.1rem;
          grid-gap: 1.5rem;
        }


        & .contentBoxItem{
          display: grid;
          margin-right: 1.6rem;

          grid-gap: 1rem;

          padding: 2rem 0;
          border-bottom: 1px solid #F7931A;

          &:first-child{
            padding-top: 0;
          }

          &:last-child{
            padding-bottom: 0;
            border: none;
          }

          @include media(xl) {
            grid-template-columns: repeat(4, 1fr) 0.75fr 1.25fr;
            grid-template-rows: max-content;
            align-items: center;
            justify-content: space-between;
            grid-gap: 0;
            padding: 0;
            border: none;
          }

          @include media(fullHD) {
            margin-right: 5.6rem;
          }

          & .contentBoxItemWrap{
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: space-between;

            &:first-child{
              @include media(xl) {
                justify-content: left;
              }
            }

            &:last-child{
              @include media(xl) {
                justify-content: right;
              }
            }

            @include media(xl) {
              justify-content: center;
            }

            & .contentBoxItemWrapName{
              @include media(xl) {
                display: none;
              }
            }

            & .contentBoxItemWrapValue{
              font-style: normal;
              font-weight: 500;
              font-size: 1.1rem;
              line-height: 1.6rem;

              color: #F5F5F5;
              text-align: right;

              & .contentBoxItemNameWrap{
                display: grid;

                @include media(xl) {
                  grid-template-columns: repeat(2, max-content);
                  align-items: center;
                  grid-gap: 1rem;
                }

                & .contentBoxItemNameImgWrap{
                  display: grid;
                }

                & .contentBoxItemNameBox{
                  display: none;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.1rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;

                  @include media(md) {
                    font-size: 1.4rem;
                  }

                  @include media(xl) {
                    display: block;
                  }
                }
              }
            }
          }

          & .contentBoxItemStatusRow{
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #FFFF00;
            text-align: center;
          }

          & .contentBoxItemAmountRow{
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;

            text-align: right;

            color: #FFFF00;

            @include media(sm) {
              font-size: 1.6rem;
              line-height: 2.2rem;
            }
          }

          & .contentBoxItemDateRow{
            display: none;

            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #FFFF00;
            text-align: center;

            @include media(sm) {
              display: block;
            }
          }

          & .contentBoxItemPairRow, & .contentBoxItemKursRow{
            display: none;

            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #FFFF00;
            text-align: center;

            @include media(xl) {
              display: block;
            }
          }

          & .contentBoxItemAmount{
            text-align: center;
          }

          & .contentBoxItemTotal{
            cursor: pointer;
            text-align: right;

            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem !important;
            line-height: 1.9rem !important;

            color: #F7931A !important;
            width: max-content;
            justify-self: end;

            @include media(md) {
              font-size: 1.6rem !important;
              line-height: 2.2rem !important;
            }

            & .contentBoxItemTotalRow{
              &:first-child{
                color: #F5F5F5 !important;

                @include media(md) {
                  display: none;
                }
              }
              &:last-child{
                cursor: pointer;
                text-align: right !important;
              }
            }
          }

          & .contentBoxItemNameWrap{
            display: grid;

            @include media(xl) {
              grid-template-columns: repeat(2, max-content);
              align-items: center;
              grid-gap: 1.5rem;
            }

            & .contentBoxItemNameImgWrap{
              display: grid;
            }

            & .contentBoxItemNameBox{
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #FFFF00;

              @include media(xl) {

              }

              & .contentBoxItemFullName{
                font-style: normal;
                font-weight: 500;
                font-size: 0.7rem;
                line-height: 1rem;

                color: #8C9497;
              }

              & .contentBoxItemShortName{
                font-style: normal;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 2rem;

                color: #F5F5F5;
              }
            }
          }

          & .contentBoxItemValues{
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2rem;

            color: #F5F5F5;
            text-align: center;

            &.contentBoxItemSM{
              display: none;
              @include media(sm) {
                display: initial;
              }
            }

            &.contentBoxItemM{
              @include media(md) {
                display: none;
              }
            }

            &.contentBoxItemD{
              display: none;

              @include media(md) {
                display: initial;
                text-align: center;
              }
            }

            & .contentBoxItemValuesRowWrap{

              & .contentBoxItemValuesRow{
                width: max-content;
                margin: 0 auto;

                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 1.9rem;

                color: #F5F5F5;

                //&:first-child{
                //  border-bottom: 1px solid rgba(247, 147, 26, 0.4);
                //}
              }
            }
          }
        }
      }
    }
  }
}