@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

:global(.modalWithoutP-overlay) {
  :global(.popup-content) {
    padding: 0 !important;
  }
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    padding: 3rem;
      & div {
        color: #F5F5F5;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        margin-top: 20px;
      }
      & .address {
        color: #FD00D5;
        text-align: center;
      }
    & .copyblock {
      text-align: center;
    }
    & .rowItemFakeInputCopyBtn {
      cursor: pointer;

      align-items: center;
      text-align: center;

      height: 4.8rem;
      padding: 1rem 4rem;
      background: #FD00D5;
      border: 1px solid transparent;
      border-radius: 1rem;

      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.7rem;

      color: #F5F5F5!important;
    }
    & .rowItemFakeInputCopyBtnCopied {
      border: 1px solid #FD00D5 !important;
      background: transparent !important;
      color: #FD00D5 !important;
    }

  }
}