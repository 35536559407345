@import "../../theme/scss/resources";

.container{
  & .filterWrap{
    margin-bottom: 2.3rem;

    @include media(xl) {
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;
      grid-gap: 2.5rem;
    }

    @include media(xxl) {
      display: grid;
      grid-template-columns: max-content 1fr;
      justify-content: space-between;
      align-items: center;
      grid-gap: 2.5rem;
    }

    @include media(fullHD) {
      grid-template-columns: max-content 50rem;
    }

    & .textBox{
      display: grid;
      grid-gap: 0.5rem;

      margin-bottom: 2rem;

      @include media(xl) {
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 2.5rem;

        margin-bottom: 0;
      }

      & .textBoxItem{
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.5rem;

        color: #F5F5F5;
        margin-bottom: 0.5rem;

        @include media(sm) {
          font-size: 1.8rem;
          margin-bottom: 0.75rem;
        }

        @include media(xl) {
          font-size: 1.8rem;
          margin-bottom: 0;
        }

        &:last-child{
          & span{
            text-align: center;
            height: 3.2rem;
            padding: 0.4rem 2rem;

            border-radius: 1rem;
            border: 1px solid #FF8C00;

            background: rgba(255, 140, 0, 0.10);

            color: #FF8C00 !important;
          }
        }

        & span{
          text-align: center;
          height: 3.2rem;
          padding: 0.4rem 2rem;

          border-radius: 1rem;
          border: 1px solid #FF8C00;

          background: rgba(255, 140, 0, 0.10);

          color: #FF8C00 !important;
        }
      }
    }

    & .filterBox{
      @include media(xl) {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap: 2rem;
      }
      & .filterBoxTitle{
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;

        color: #FFFFFF;
        margin-bottom: 0.5rem;

        @include media(xl) {
          margin-bottom: 0;
        }
      }

      & .filterBoxContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1.5rem;

        & .filterBoxContainerItem{
          cursor: pointer;

          display: grid;
          align-items: center;
          justify-items: center;

          background: rgba(140, 148, 151, 0.1);
          border: 1px solid #8C9497;
          border-radius: 0.8rem;

          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.4rem;

          color: #FFFFFF;
          text-align: center;
          height: 3.2rem;

          &.filterBoxContainerItemActive{
            //background: rgba(3, 183, 89, 0.1);
            //border: 1px solid #03B759;
            border-radius: 8px;

            border: 1px solid #FF8C00;
            background: rgba(255, 140, 0, 0.10);
            color: #FF8C00 !important;
          }
        }
      }
    }
  }

  & .tableWrap{
    height: 37rem;
    overflow: hidden;

    @include media(xl) {
      height: 93.3rem;
    }

    & .honeeCardBody{
      @include media(xl) {
        height: 100%;
      }

      & .cardBodyWithScroll{
        & .title{
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 2.5rem;

          color: #F5F5F5;

          margin-bottom: 2rem;
        }

        & .titleRow{
          display: none;
          grid-template-columns: 35% 40% 25%;
          justify-content: space-between;

          margin-bottom: 2rem;
          padding-right: 2rem;

          @include media(xl) {
            display: grid;
            grid-template-columns: repeat(5, 20%);
          }


          @include media(fullHD) {
            padding-right: 6rem;
          }

          & .title6{
            margin-bottom: 0;

            &.titleRowItemM{
              @include media(md) {
                display: none;
              }
            }

            &.titleRowItemD{
              display: none;

              @include media(md) {
                display: initial;
                text-align: center;
              }
            }
            &:nth-child(2){
              text-align: center;
            }
            &:nth-child(3){
              text-align: center;
            }

            &:nth-child(4){
              text-align: right;

              @include media(md) {
                text-align: center;
              }
            }

            &:last-child{
              text-align: right;
            }
          }
        }

        & .contentBox{
          display: grid;
          grid-auto-rows: max-content;

          overflow-y: scroll;
          height: 30.1rem;
          scroll-padding-bottom: 0;

          &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
          &::-webkit-scrollbar {width: 0.5rem;}
          &::-webkit-scrollbar-thumb {border-radius: 4px; background: #FF8C00;}
          //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}

          @include media(xl) {
            height: 82.2rem;
            grid-gap: 1.5rem;
          }


          & .contentBoxItem {
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: space-between;

            margin-right: 1.6rem;

            grid-gap: 1rem;

            padding: 2rem 0;
            border-bottom: 1px solid #F7931A;

            &:first-child{
              padding-top: 0;
            }

            &:last-child{
              padding-bottom: 0;
              border: none;
            }

            @include media(xl) {
              align-items: center;
              grid-template-columns: repeat(5, 20%);
              grid-gap: 0;
              padding: 0;
              border: none;
            }

            @include media(fullHD) {
              margin-right: 5.6rem;
            }

            & .contentBoxItemWrap{
              display: grid;
              grid-template-columns: 1fr 1fr;
              align-items: center;
              grid-gap: 1rem;

              @include media(xl) {
                grid-template-columns: initial;
                grid-gap: 0;
              }

              &:first-child{
                & .contentBoxItemWrapValue{
                  @include media(xl) {
                    text-align: left !important;
                  }
                }
              }

              &:last-child{
                & .contentBoxItemWrapValue{
                  @include media(xl) {
                    text-align: right !important;
                  }
                }
              }
              & .contentBoxItemWrapName{
                @include media(xl) {
                  display: none;
                }
              }

              & .contentBoxItemWrapValue{
                font-style: normal;
                font-weight: 500;
                font-size: 1.1rem;
                line-height: 1.6rem;
                text-align: right;

                color: #F5F5F5;

                @include media(xl) {
                  text-align: center;
                }

                &.contentBoxItemWrapValueOverfloading{
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                &.contentBoxItemWrapValueAbsDisplayed{
                  position: relative;
                  cursor: pointer;
                  color: #F7931A;

                  &:hover{
                    & .partners{
                      display: grid;
                    }
                  }
                }

                & .partners{
                  display: none;
                  width: 23rem;
                  position: absolute;
                  top: 0;
                  right: 0;

                  border: 1px solid #915207;
                  background: #231604;
                  border-radius: 1rem;

                  z-index: 100;

                  & .partnersRow{
                    display: grid;
                    grid-template-columns: 8rem 15rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.1rem;
                    line-height: 1.6rem;
                    color: #F5F5F5;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;


                    border-bottom: 1px solid #915207;

                    text-align: left;

                    &:last-child{
                      border-bottom: none;
                    }

                    @include media(xl) {
                      grid-template-columns: max-content 1fr;
                    }

                    & .level{
                      color: #F7931A !important;
                      border-right: 1px solid #915207;
                      padding: 1rem;
                    }

                    & .value{
                      padding: 1rem;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }

            &.contentBoxItemWithoutDep{
              & .contentBoxItemValue{
                color: #F7931A !important;
              }
            }

            & .contentBoxItemValue{
              font-style: normal;
              font-weight: 400;
              font-size: 1.1rem;
              line-height: 2.5rem;

              color: #03B759;
              text-align: center;

              @include media(md) {
                font-size: 1.1rem;
              }
            }

            & .contentBoxItemValueD{
              display: none !important;

              @include media(md) {
                display: grid !important;
                //text-align: center;
              }
            }

            & .contentBoxItemName{
              font-style: normal;
              font-weight: 400;
              font-size: 1.1rem;
              line-height: 2.5rem;

              color: #F5F5F5;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            & .contentBoxItemLevel{
              position: relative;
              cursor: pointer;

              &:hover{
                & .partners{
                  display: block;
                }
              }
            }

            & .contentBoxItemProfit{
              text-align: right;

              @include media(md) {
                text-align: center;
              }
            }

            & .contentBoxItemOrigin{
              display: grid;
              align-items: center;
              justify-items: center;
              justify-self: end;

              //background: rgba(140, 148, 151, 0.1);
              //border: 1px solid #8C9497;
              //border-radius: 0.8rem;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.4rem;

              color: #FFFFFF;
              height: 3.2rem;

              width: 100%;
              max-width: 11.7rem;
            }
          }
        }
      }
    }
  }
}