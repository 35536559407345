@import "../../theme/scss/resources";


.container{
  margin-top: 2rem;

  &.containerVertical{
    margin-top: 0;
    position: relative;
    display: none;

    @include media(lg) {
      display: grid;
    }

    & .contentWrap{
      grid-template-columns: initial;
      & .currentLangBox{
        //grid-template-columns: initial;
        margin-right: 1rem;

        @include media(xl) {
          margin-right: 0;
        }

        & .imgWrap{
          top: 0;
          transform: rotate(0deg);

          &.imgWrapActive{
            transform: rotate(180deg);
          }
        }
      }

      & .allLangWrap{
        position: absolute;
        overflow: hidden;
        width: 4rem;
        max-height: 0;
        top: 3rem;
        transition: all 0.25s ease-out;

        &.allLangWrapActive{
          max-height: 20rem;
        }

        & .allLangBox{
          grid-auto-flow: inherit;
        }
      }
    }
  }

  & .contentWrap{
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    position: relative;

    & .currentLangBox{
      cursor: pointer;

      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      grid-gap: 0.5rem;

      & .currentLang{
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #FF8C00;
        text-transform: uppercase;
      }

      & .imgWrap{
        display: grid;
        position: relative;
        top: -0.2rem;
        transition: all 0.25s ease-out;
        transform: rotate(-90deg);

        &.imgWrapActive{
          transform: rotate(90deg);
        }
      }
    }

    & .allLangWrap{
      //position: absolute;
      //overflow: hidden;
      //left: 0;
      //top: 2rem;
      //max-height: 0;
      overflow: hidden;
      width: 0;

      &.allLangWrapActive{
        width: 100%;
      }
      & .allLangBox{
        display: grid;
        grid-auto-flow: column;
        justify-items: left;
        grid-gap: 1rem;
        width: max-content;

        & .allLangBoxItem{
          cursor: pointer;
          font-style: normal;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: #F5F5F5;
          text-transform: uppercase;
          width: max-content;

          &:hover{
            color: #FF8C00 !important;
          }

          &.allLangBoxItemActive{
            color: #FF8C00 !important;
          }
        }
      }
    }
  }
}