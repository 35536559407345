@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    display: grid;
    grid-gap: 3rem;

    @include media(xl) {
      grid-template-columns: 30rem 1fr;
      justify-content: space-between;
    }

    & .contentWrapItem{
      display: grid;
      align-items: center;

      & .t1{
        margin-bottom: 2.25rem;

        & .typeBeta{
          color: #FFFFFF !important;
          font-size: 1rem;
          padding: 0.55rem 0.5rem;
          border-radius: 0.5rem;
          border: 1px solid #FF8C00;
          background: rgba(255, 140, 0, 0.10);

          position: relative;
          top: -0.2rem;
        }
      }

      & .infoWrap{
        & .infoTitle{
          margin-bottom: 1rem;
        }

        & .infoValue{
          border-radius: 1rem;
          border: 1px solid #FFD600;
          background: rgba(255, 214, 0, 0.10);

          padding: 1rem 2rem;
          text-align: center;

          color: #FFFFFF;
          font-size: 2.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-bottom: 3rem;

          & span{
            color: #FFD600;
          }
        }
      }

      & .textWrap {
        display: grid;
        grid-gap: 2rem;

        & .text {
          color: #FFFFFF;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          & span {
            color: #FFD600;
          }
        }
      }
    }
  }
}