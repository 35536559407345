@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap {
    & .modalContentWrapTitle{
      color: #FF8C00;

      text-align: center;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    & .modalContentWrapSubTitle{
      color: #FFFFFF;

      text-align: center;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    & .modalContentWrapResult{
      display: grid;
      align-items: center;
      justify-items: center;
      justify-self: center;

      height: 5rem;
      width: max-content;
      border-radius: 1rem;
      border: 1px solid #FF8C00;

      background: rgba(255, 140, 0, 0.05);

      color: #FF8C00;

      text-align: center;
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      padding: 1rem 5rem;
      margin-bottom: 3rem;
    }
  }
}