@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap {
    & .iconWrap{
      display: grid;
      justify-self: center;
    }

    & .warningTitle{
      color: #FF8C00;

      text-align: center;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      text-transform: uppercase;

      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }

    & .warningNote{
      color: #FFFFFF;

      text-align: center;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-bottom: 4rem;
    }
  }
}