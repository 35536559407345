@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    & .academyS1{
      display: grid;

      align-items: center;
      grid-gap: 5rem;

      @include media(xl) {
        grid-template-columns: 1fr 1fr;
      }

      & .academyS1TextContent{
        & .titleWSF{
          font-size: 2.5rem;

          @include media(sm) {
            font-size: 3.4rem;
          }

          & span{
            font-size: 2.5rem;
            color: #FFD600;

            @include media(sm) {
              font-size: 3.4rem;
            }
          }
        }
        & .academyS1Text{
          color: #FFFFFF;

          font-size: 2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-top: 2.5rem;
          margin-bottom: 4rem;

          @include media(sm) {
            font-size: 2.4rem;
          }
        }
      }

      & .academyS1ImgWrap{
        display: grid;
        justify-items: center;

        @include media(xl) {
          justify-items: end;
        }

        & img{
          width: 100%;
          max-width: 45rem;

          @include media(xl) {
            width: initial;
            max-width: initial;
          }
        }
      }
    }

    & .academyS2{
      display: grid;
      grid-gap: 1.5rem;

      margin-top: 1.5rem;

      @include media(sm) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media(xl) {
        grid-template-columns: repeat(4, 1fr);
      }



      & .academyS2Item{
        border-radius: 1rem;
        border: 1px solid #0FF;

        background: linear-gradient(138deg, rgba(0, 163, 163, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%);
        padding: 1.8rem;

        &:nth-child(2){
          border: 1px solid #00FF7A !important;
          background: linear-gradient(138deg, rgba(0, 161, 77, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%) !important;

          & .academyS2ItemTitle{
            color: #00FF7A !important;
          }
        }

        &:nth-child(3){
          border: 1px solid #FF8C00 !important;
          background: linear-gradient(138deg, rgba(201, 110, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%) !important;

          & .academyS2ItemTitle{
            color: #FF8C00 !important;
          }
        }

        &:last-child{
          border: 1px solid #FF4406 !important;
          background: linear-gradient(138deg, rgba(154, 38, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%) !important;

          & .academyS2ItemTitle{
            color: #FF4406 !important;
          }
        }

        & .academyS2ItemPreTitle{
          font-size: 1rem;
        }

        & .academyS2ItemTitle{
          font-size: 1.8rem;
          color: #0FF;
        }

        & .academyS2ItemText{
          color: #FFFFFF;

          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-top: 0.75rem;
        }
      }
    }

    & .academyS3{
      display: grid;
      align-items: center;
      grid-gap: 3rem;

      margin-top: 9rem;

      @include media(xl) {
        grid-template-columns: 1fr 1fr;
      }

      & .academyS3TextContent{
        & .academyS3Title{
          font-size: 2.5rem;

          @include media(sm) {
            font-size: 3.4rem;
          }

          & span{
            color: #FFD600;
            font-size: 2.5rem;

            @include media(sm) {
              font-size: 3.4rem;
            }
          }
        }

        & .academyS3Text{
          color: #FFFFFF;

          font-size: 1.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-top: 2.5rem;

          @include media(sm) {
            font-size: 1.8rem;
          }
        }
      }

      & .academyS3ImgWrap{
        display: grid;
        justify-items: center;

        @include media(xl) {
          justify-items: end;
        }

        & img{
          width: 100%;
          max-width: 43rem;

          @include media(xl) {
            width: initial;
            max-width: initial;
          }
        }
      }
    }

    & .academyS4 {
      display: grid;
      grid-gap: 3rem;
      align-items: center;

      margin-top: 3rem;

      @include media(xl) {
        grid-template-columns: 1fr 1fr;
      }

      & .academyS4TextContent {
        display: grid;
        order: 1;

        @include media(xl) {
          order: 2;
        }

        & .academyS4Title {
          font-size: 2.5rem;

          @include media(sm) {
            font-size: 3.4rem;
          }

          & span {
            color: #FFD600;
            font-size: 2.5rem;

            @include media(sm) {
              font-size: 3.4rem;
            }
          }
        }

        & .academyS4Text {
          color: #FFFFFF;

          font-size: 1.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-top: 2.5rem;

          @include media(sm) {
            font-size: 1.8rem;
          }
        }
      }

      & .academyS4ImgWrap {
        display: grid;
        justify-items: center;
        order: 2;

        @include media(xl) {
          order: 1;
        }

        @include media(xl) {
          justify-items: start;
        }

        & img {
          width: 100%;
          max-width: 43rem;

          @include media(xl) {
            width: initial;
            max-width: initial;
          }
        }
      }
    }

    & .academyS5{
      margin-top: 9rem;

      & .academyS5Title{
        font-size: 2.5rem;
        margin-bottom: 2.5rem;

        @include media(sm) {
          font-size: 3.4rem;
        }

        & span{
          font-size: 2.5rem;
          color: #FFD600;

          @include media(sm) {
            font-size: 3.4rem;
          }
        }
      }

      & .academyS5ItemWrap{
        display: grid;
        justify-content: space-between;
        grid-gap: 2rem;

        @include media(sm) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 3rem;
        }

        @include media(xl) {
          grid-template-columns: repeat(3, 1fr);
        }

        & .academyS5Item{
          & .academyS5ItemIconWrap{
            display: grid;
          }

          & .academyS5ItemTitle{
            color: #FFFFFF;

            font-size: 1.8rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            margin-top: 0.75rem;
            margin-bottom: 1.25rem;
          }

          & .academyS5ItemText{
            color: #FFFFFF;

            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    & .academyS6{
      margin-top: 9rem;

      & .academyS6Title{
        text-align: center;
        font-size: 4.4rem;
        color: #FFFFFF;

        margin-bottom: 2.6rem;

        & span{
          color: #00FF7A;
          font-size: 4.4rem;
        }
      }

      & .academyS6ContentWrap{
        display: grid;
        grid-gap: 3rem;
        align-items: center;

        border-radius: 1rem;
        border: 1px solid #FFD600;
        background: linear-gradient(138deg, rgba(201, 110, 0, 0.10) -23.04%, rgba(255, 255, 255, 0.01) 118.95%);

        padding: 3rem 2rem;

        @include media(sm) {
          padding: 7rem 5rem;
        }

        @include media(xl) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 7rem;
        }

        & .academyS6ContentBuyWrap{
          display: grid;
          justify-self: center;
          //max-width: 34rem;

          & .academyS6ContentBuyNote{
            color: #FFFFFF;

            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin-bottom: 3rem;
            text-align: center;

            @include media(xl) {
              text-align: left;
            }
          }

          & .academyBtn{
            border: 1px solid #00FF7A !important;
            background: #00FF7A !important;
          }

          & .academyS6ContentBuyTextWrap{
            margin-top: 1rem;

            & .academyS6ContentBuyTextWrapItem{
              color: #888581;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        & .academyS6ContentImgWrap{
          display: grid;
          justify-items: end;


          & img{
            max-width: 100%;
          }
        }

        & .academyS6ContentPriceWrap{
          & .academyS6ContentPrice{
            text-align: center;
            font-size: 8.9rem;
            color: #FFD600;
          }

          & .academyS6ContentPriceNote{
            color: #FFFFFF;

            text-align: center;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    & .academyBtn{
      display: grid;
      align-items: center;
      justify-items: center;

      cursor: pointer;
      border-radius: 1rem;
      border: 1px solid #FFD600;

      background: #FFD600;

      color: #100A02;

      text-align: center;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      height: 4.8rem;
      max-width: 38rem;
    }
  }
}