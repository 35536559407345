@import "../../theme/scss/resources";

.container{
  //@include pagePadding;
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;

  @include media(lg) {
    display: block;
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
    padding-left: 0 !important;
  }
  & .contentWrap{
    max-width: 42rem;
    margin: 0 auto;

    & .title2{
      font-weight: 700;
      font-size: 2.5rem;
      padding-top: 0.5rem;
      margin-bottom: 1.5rem;
      text-align: center;

      @include media(lg) {
        text-align: left;
      }
    }

    & .contentBox{
      display: grid;
      grid-gap: 3.2rem;

      background: rgba(88, 193, 239, 0.05);
      border: 1px solid #FF8C00;
      border-radius: 1rem;

      padding: 2.5rem;

      & .contentBoxItem{
        cursor: pointer;
        border-bottom: 1px solid #F5F5F5;

        &:last-child{
          border-bottom: none;

          & .contentBoxItemCategory{
            margin-bottom: 0 !important;
          }
        }

        & .dateWrap{
          @include dateWrap;
          margin-bottom: 1.2rem;
        }

        & .title3{
          font-weight: 600;
          height: 5.75rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 1.2rem;
        }

        & .contentBoxItemDescription{
          @include newsDescription;
          margin-bottom: 2.4rem;
        }

        & .contentBoxItemCategory{
          @include newsCategory;
          margin-bottom: 3.2rem;
          display: none;
        }
      }
    }
  }
}