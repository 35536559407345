@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    display: grid;
    grid-gap: 3.5rem;

    padding: 2rem 3rem;

    //@include media(sm) {
    //  grid-template-columns: 1fr 17.5rem;
    //}
    //
    //@include media(sm) {
    //  grid-template-columns: 1fr 20rem;
    //  padding: 3.5rem;
    //}
    //
    //@include media(xl) {
    //  grid-template-columns: 1fr 17.5rem;
    //}
    //
    //@include media(xxl) {
    //  grid-template-columns: 1fr 19rem;
    //}
    //
    //@include media(fullHD) {
    //  grid-gap: 3.5rem;
    //}

    & :global(.slick-slider) {
      display: grid !important;
    }

    & :global(.slick-dots) {
      & button::before{
        font-size: 1.5rem;
        color: #F5F5F5 !important;

        @include media(xs) {
          font-size: 1.5rem;
        }
      }
    }

    //&.contentWrapP{
    //  max-width: 61rem;
    //  margin: 0 auto;
    //
    //  @include media(fullHD) {
    //    max-width: 80rem;
    //  }
    //}

    & .cardWrap{
      border-radius: 1rem;
      border: 1px solid #FF8C00;

      & .cardWrapItem{
        & .cardWrapBgBoxFE{
          border-radius: 1rem;
          height: 23.6rem;
          background: #0d0a07 url("../../theme/assets/other/honee_main_bg.png") no-repeat;
          background-size: cover;

          padding: 2rem;
          //padding: 2rem;
          text-align: center;
        }

        & .cardWrapBgBox{
          border-radius: 1rem;
          height: 23.6rem;
          background: #0d0a07 url("../../theme/assets/other/honee_main_bg.png") no-repeat;
          background-size: cover;

          padding: 2rem;

          & .titleRow{
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: space-between;
            & .title{
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F5F5F5;
            }

            & .settingsBtn{
              opacity: 0;
              padding: 0.25rem 0;
              display: grid;
              //grid-template-rows: 1fr 1fr 1fr;
              align-items: center;
              justify-items: center;

              background: #092532;
              border: 1px solid #F5F5F5;
              border-radius: 0.5rem;

              width: 2rem;
              height: 2rem;

              & .settingsBtnItem{
                background: #F5F5F5;
                width: 0.2rem;
                height: 0.2rem;
              }
            }
          }

          & .balanceRow{
            margin-top: 1rem;
            margin-bottom: 3.7rem;

            & .balance{
              font-style: normal;
              font-weight: 400;
              font-size: 2rem;
              line-height: 3rem;

              color: #FFFFFF;

              border-radius: 1rem;
              border: 1px solid #FF8C00;

              padding: 1rem 0.75rem;

              background: rgba(255, 140, 0, 0.01);
              backdrop-filter: blur(12px);

              width: max-content;
            }
          }

          & .btnWrap{
            display: grid;
            grid-template-columns: max-content max-content;
            justify-content: space-around;
            & .btnWrapItem{
              cursor: pointer;

              &:last-child{
                & .btnWrapItemImgWrap{
                  transform: rotate(-180deg);
                }
              }

              & .btnWrapItemImgWrap{
                display: grid;
                justify-items: center;
                align-items: center;

                width: 4.2rem;
                height: 4.2rem;

                border-radius: 5rem;
                border: 1px solid #F5F5F5;

                margin: 0 auto;
              }

              & .btnWrapItemContent{
                font-style: normal;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.9rem;

                color: #F5F5F5;
                text-align: center;
                margin-top: 0.5rem;
              }
            }
          }
        }
      }
    }

    & .addBtn{
      display: grid;
      grid-auto-rows: 4rem 1fr;
      grid-gap: 1rem;
      //cursor: pointer;

      & .soon{
        display: grid;
        align-items: center;
        justify-items: center;
        text-align: center;

        height: 4rem;

        border-radius: 1.5rem;
        border: 1px solid #FF0;
        background: rgba(255, 255, 0, 0.10);
        backdrop-filter: blur(7px);

        color: #FF0;

        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      & .addBtnWrap{
        display: grid;
        grid-template-columns: max-content max-content;
        justify-items: center;
        align-items: center;
        justify-content: center;
        grid-gap: 1rem;

        border-radius: 1.5rem;
        border: solid 1px #AFA293;

        background: rgba(175, 162, 147, 0.10);

        backdrop-filter: blur(7px);

        padding: 1rem;
        position: relative;

        @include media(sm) {
          align-content: center;
          grid-template-columns: initial;
        }

        & .absSoon{
          position: absolute;
          right: 2rem;
          top: 1rem;

          font-style: normal;
          font-weight: 500;
          font-size: 2rem;
          line-height: 2rem;
          color: #F5F5F5;
          text-align: right;

          width: 8rem;
        }

        & .addBtnPlus{
          font-size: 4rem;
          line-height: 4rem;
          color: #58C1EF;

        }

        & .addBtnContent{
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.9rem;

          text-align: center;

          //color: #58C1EF;
          color: #FFFFFF;
        }

      }
    }

  }
}