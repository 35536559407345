@import "../../theme/scss/resources";

.container{
  //height: 101rem;
  overflow: hidden;

  @include media(xl) {
    display: grid;
    //order: 5;
  }

  & .contentWrap{
    height: 100%;
    & .cardBodyWithScroll{
      height: 100%;

      & .titleWSF{
        margin-bottom: 2.5rem;
      }

      & .titleRow{
        display: none;
        grid-template-columns: 38% 38% 24%;
        justify-content: space-between;

        margin-bottom: 2rem;
        padding-right: 2rem;

        @include media(md) {
          grid-template-columns: repeat(6, 16.666%);
        }

        @include media(xl) {
          display: grid;
        }

        @include media(fullHD) {
          padding-right: 6rem;
        } 

        & .title6{
          margin-bottom: 0;

          &:nth-child(2){
            text-align: center;
            display: none;

            @include media(md) {
              display: block;
              text-align: center;
            }
          }

          &:nth-child(3){
            display: none;

            @include media(md) {
              display: block;
              text-align: center;
            }
          }

          &:nth-child(4), &:nth-child(5){
            text-align: center;
          }

          &:last-child{
            text-align: right;
          }
        }
      }

      & .contentBox{
        display: grid;


        overflow-y: scroll;
        height: 58rem;
        scroll-padding-bottom: 0;
        grid-auto-rows: max-content;

        &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
        &::-webkit-scrollbar {width: 0.5rem;}
        &::-webkit-scrollbar-thumb {border-radius: 4px; background: #FF8C00;}
        //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}

        @include media(xl) {
          grid-gap: 1.5rem;
          height: 20rem;
        }


        & .contentBoxItem{
          display: grid;
          height: max-content;
          margin-right: 1.6rem;

          grid-gap: 1rem;

          padding: 2rem 0;
          border-bottom: 1px solid #F7931A;

          &:first-child{
            padding-top: 0;
          }

          &:last-child{
            padding-bottom: 0;
            border: none;
          }

          @include media(xl) {
            grid-auto-rows: max-content;
            align-items: center;
            justify-content: space-between;
            grid-template-columns: repeat(6, 16.666%);

            grid-gap: 0;
            padding: 0;
            border: none;
          }

          @include media(fullHD) {
            margin-right: 5.6rem;
          }

          &.contentBoxItemGray{
            & .contentBoxItemWrap{
              & .contentBoxItemWrapValue{
                color: #8C9497 !important;
                border: 1px solid #8C9497 !important;
                background: none !important;
                backdrop-filter: none !important;
              }
            }
          }

          & .contentBoxItemWrap{
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: space-between;

            &:first-child{
              @include media(xl) {
                justify-content: left;
              }
            }

            &:last-child{
              @include media(xl) {
                justify-content: right;
              }
            }

            @include media(xl) {
              justify-content: center;
            }

            & .contentBoxItemWrapName{
              @include media(xl) {
                display: none;
              }
            }

            & .contentBoxItemWrapValue{
              font-size: 1.1rem;

              border-radius: 1rem;
              border: 1px solid #FF8C00;

              color: #FF8C00;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              padding: 1rem;

              &.pointer{
                cursor: pointer !important;
              }

              &.contentBoxItemWrapValueRed{
                border: 1px solid #FF060A;
                color: #FF060A;
                background: rgba(255, 6, 10, 0.10);
                backdrop-filter: blur(10px);
              }

              &.contentBoxItemWrapValueWhite{
                border: 1px solid #FFFEFE;
                color: #FFFEFE;
                background: rgba(255, 254, 254, 0.10);
                backdrop-filter: blur(10px);
              }

              &.contentBoxItemWrapValueGreen{
                border: 1px solid #00FF7A;
                color: #00FF7A;
                background: rgba(0, 255, 122, 0.10);
                backdrop-filter: blur(10px);
              }

              &.contentBoxItemWrapValueGray{
                color: #8C9497;
                border: 1px solid #8C9497;
              }

              & .contentBoxItemNameWrap{
                display: grid;
                grid-template-columns: repeat(2, max-content);
                align-items: center;
                grid-gap: 1rem;

                & .contentBoxItemNameImgWrap{
                  display: grid;
                }

                & .contentBoxItemNameBox{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.1rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;

                  @include media(md) {
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }


          & .contentBoxItemValues{
            font-style: normal;
            font-weight: 500;
            font-size: 1.1rem;
            line-height: 1.6rem;

            color: #F5F5F5;

            &:nth-child(2){
              display: none;

              @include media(md) {
                display: block;
                text-align: center;
                font-size: 1.4rem;
              }
            }

            &:nth-child(3){
              display: none;

              @include media(md) {
                display: block;
                text-align: center;
              }
            }

            &:nth-child(4){
              text-align: center;
            }

            &:last-child{
              text-align: right;
            }

            &.contentBoxItemValuesSpecial{
              font-style: normal;
              font-weight: 400;
              font-size: 1.1rem;
              line-height: 2.2rem;

              color: #03B759;

              @include media(md) {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}