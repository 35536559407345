@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{

    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin-bottom: 1.25rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin-bottom: 2.5rem;
      }
    }

    & .typeRow{
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      grid-gap: 1.25rem;

      & .typeRowName{
        color: #FFFFFF;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      & .typeRowBtnsWrap{
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: 1.25rem;

        & .typeRowBtnsItem{
          cursor: pointer;

          color: #8C9497;

          border-radius: 1rem;
          border: 1px solid #8C9497;

          padding: 1rem;

          text-align: center;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          width: max-content;

          &:first-child{
            &.typeRowBtnsItemActive{
              border: 1px solid #FF060A !important;
              color: #FF060A !important;
            }
          }

          &:last-child{
            &.typeRowBtnsItemActive{
              border: 1px solid #00FF7A !important;
              color: #00FF7A !important;
            }
          }
        }
      }
    }

    & .modalContentBox{
      & .commissionRow{
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1.25rem;

        & .commissionRowName{
          color: #FFFFFF;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        & .commissionRowValue{
          color: #FF8C00;
          text-align: center;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          border-radius: 1rem;
          border: 1px solid #FF8C00;

          padding: 1rem;
        }
      }

      & .note{
        color: #FF8C00;
        text-align: center;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        border-radius: 1rem;
        border: 1px solid #FF8C00;

        padding: 1rem;

        margin-top: 1rem;
        margin-bottom: 2rem;
      }

      & .mainBtn{
        color: #8C9497;

        border-radius: 1rem;
        border: 1px solid #8C9497;

        padding: 1rem;

        text-align: center;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.mainBtnActive{
          cursor: pointer !important;
          background: #00FF7A !important;
          border: 1px solid #00FF7A !important;
          color: #15100A !important;
        }
      }

      & .inputWrap{
        display: grid;
        grid-gap: 2rem;

        margin: 2rem 0;

        & .inputBox{
          position: relative;

          & .inputBoxTitle{
            color: #F5F5F5;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin-bottom: 1rem;

            & span{
              color: #FF8C00;
            }
          }

          & input{
            border-radius: 1rem;
            border: 1px solid #FF8C00;
            background: linear-gradient(138deg, rgba(201, 110, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%);

            padding: 1.25rem 8.5rem 1.25rem 6rem;
            width: 100%;

            color: #F5F5F5;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            -moz-appearance: textfield;

            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          & .inputBoxAvailable{
            color: #F5F5F5;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin-top: 0.5rem;

            & span{
              color: #FF8C00;
            }
          }

          & .inputBoxAbsCryptoIcon{
            position: absolute;
            left: 1.5rem;
            top: 3.5rem;
          }

          & .inputBoxAbsText{
            position: absolute;
            right: 1.5rem;
            top: 4rem;

            color: #FF8C00;
            text-align: right;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    & .modalContentWrapRepositionBox {
      display: grid;
      grid-gap: 1.5rem;

      & .modalContentWrapItem {
        display: grid;
        align-items: center;
        grid-template-columns: max-content max-content;
        grid-gap: 1.25rem;

        & .modalContentWrapItemName {
          color: #FFFFFF;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          & span {
            color: #FF8C00;
          }
        }

        & .modalContentWrapItemValue {
          border-radius: 1rem;
          border: 1px solid #FF8C00;

          padding: 1rem;

          width: max-content;

          color: #FF8C00;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          &.modalContentWrapItemValueBuy {
            color: #00FF7A !important;
            border: 1px solid #00FF7A !important;
          }

          &.modalContentWrapItemValueSell {
            color: #FF060A !important;
            border: 1px solid #FF060A !important;
          }

          &.modalContentWrapItemValueWallet {
            color: #FFFFFF !important;
            border: 1px solid #FFFFFF !important;
          }
        }
      }
    }

    & .mainBtn{
      color: #8C9497;

      border-radius: 1rem;
      border: 1px solid #8C9497;

      padding: 1rem;

      text-align: center;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-top: 4rem;

      &.mainBtnActive{
        cursor: pointer !important;
        background: #00FF7A !important;
        border: 1px solid #00FF7A !important;
        color: #15100A !important;
      }
    }
  }
}