@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    display: grid;
    order: 3;
  }

  & .honeeCardBody{
    height: 62rem;
    overflow: hidden;

    @include media(xl) {
      height: 49rem;
    }

    & .cardBodyWithScroll {
      & .title3 {
        margin-bottom: 2rem;
      }

      & .titleRow {
        display: none;
        grid-template-columns: repeat(3, 33.333%);
        justify-content: space-between;

        margin-bottom: 2rem;
        padding-right: 2rem;

        @include media(sm) {
          grid-template-columns: repeat(4, 25%);
        }

        @include media(xl) {
          display: grid;
          grid-template-columns: 1.5fr repeat(6, 1fr);
        }


        @include media(fullHD) {
          padding-right: 6rem;
        }

        & .title6 {
          margin-bottom: 0;
          text-align: center;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
        }
      }

      & .contentBox {
        display: grid;
        grid-auto-rows: max-content;
        grid-gap: 1.5rem;

        overflow-y: scroll;
        height: 51.2rem;
        scroll-padding-bottom: 0;

        &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
        &::-webkit-scrollbar {width: 0.5rem;}
        &::-webkit-scrollbar-thumb {border-radius: 4px; background: #FF8C00;}

        @include media(xl) {
          height: 34.1rem;
        }


        & .contentBoxItem {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr;
          grid-template-rows: max-content;
          align-items: center;
          justify-content: space-between;

          margin-right: 1.6rem;

          padding: 2rem 0;
          border-bottom: 1px solid #F7931A;

          @include media(xl) {
            grid-template-columns: 1.5fr repeat(6, 1fr);
            padding: 0;
            border-bottom: none;
            grid-gap: 0;
          }

          @include media(fullHD) {
            margin-right: 5.6rem;
          }

          &:first-child{
            padding-top: 0;
          }

          &:last-child{
            padding-bottom: 0;
            border: none;
          }

          & .contentBoxItemWrap{
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: space-between;

            @include media(xl) {
              grid-auto-flow: initial;
              justify-content: center;
              justify-items: center;
            }

            &:first-child{
              @include media(xl) {
                justify-content: left;
                justify-items: left;
              }
            }

            &:last-child{

              @include media(xl) {
                justify-content: right;
                justify-items: right;
              }

              & .contentBoxItemValues{
                text-align: right;
                color: #F7931A !important;
                cursor: pointer;
              }
            }

            & .contentBoxItemWrapTitle{
              @include media(xl) {
                display: none;
              }
            }

            & .contentBoxItemAmount {
              text-align: center;
            }

            & .contentBoxItemTotal {
              text-align: right;

              font-style: normal;
              font-weight: 500;
              font-size: 1.4rem !important;
              line-height: 1.9rem !important;

              color: #F5F5F5 !important;
              width: max-content;
              justify-self: end;

              @include media(md) {
                font-size: 1.6rem !important;
                line-height: 2.2rem !important;
                justify-self: center;
              }

              & .contentBoxItemTotalRow {
                &:first-child {
                  color: #F5F5F5 !important;

                  @include media(md) {
                    display: none;
                  }
                }

                &:last-child {
                  //text-align: right !important;
                }

                &.fluc {
                  &:first-letter {
                    text-transform: uppercase;
                  }
                }
              }
            }

            & .contentBoxItemNameWrap {
              display: grid;
              grid-template-columns: repeat(2, max-content);
              align-items: center;
              grid-gap: 1.5rem;

              & .contentBoxItemNameImgWrap {
                display: grid;
              }

              & .contentBoxItemNameBox {
                & .contentBoxItemFullName {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 0.7rem;
                  line-height: 1rem;

                  color: #8C9497;
                }

                & .contentBoxItemShortName {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 1.5rem;
                  line-height: 2rem;

                  color: #F5F5F5;
                }
              }
            }

            & .contentBoxItemValues {
              font-style: normal;
              font-weight: 500;
              font-size: 1.1rem;
              line-height: 2rem;

              color: #F5F5F5;
              text-align: center;

              & .tabletShow {
                @include media(md) {
                  display: none;
                }
              }

              @keyframes pulse {
                0% {
                  transform: scale(1);
                  box-shadow: 0 0 0 rgba(247, 147, 26, 0.4);
                }
                50% {
                  transform: scale(1.05);
                  box-shadow: 0 0 10px rgba(247, 147, 26, 0.6);
                }
                100% {
                  transform: scale(1);
                  box-shadow: 0 0 0 rgba(247, 147, 26, 0.4);
                }
              }


              &.contentBoxItemValuesBtn{
                display: grid;
                align-items: center;
                justify-items: center;
                text-align: center;

                cursor: pointer;

                border: 1px solid #F7931A !important;
                border-radius: 1rem;
                background: rgba(255, 255, 0, 0.05) !important;
                color: #F7931A !important;

                height: 3.2rem;
                padding: 0 0.75rem;



                &.contentBoxItemValuesBtnAnimate{
                  animation: pulse 2s infinite;
                }
              }

              &.contentBoxItemValuesDisBtn{
                cursor: initial !important;

                color: #8C9497 !important;
                background-color: transparent !important;
                border: 1px solid #8C9497 !important;
              }

              & .contentBoxItemValuesRowWrap {

                & .contentBoxItemValuesRow {
                  width: max-content;
                  margin: 0 auto;

                  font-style: normal;
                  font-weight: 500;
                  font-size: 1.4rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;

                  &:first-child {
                    border-bottom: 1px solid rgba(247, 147, 26, 0.4);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}