@import "../../theme/scss/resources";

.container{


  position: absolute;
  left: -28rem;
  top: 6.2rem;

  //height: 100vh;
  z-index: 1000;

  transition: all 0.5s ease-out;
  //display: none;

  @include media(lg) {
    position: initial;

    top: 0 !important;
    left: 0 !important;

    height: 100%;
  }


  &.containerActive{
    left: 0;
  }

  & .contentWrap{
    background: linear-gradient(138deg, rgba(201, 110, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%);
    backdrop-filter: blur(27px);
    border: 1px solid #915207;
    border-radius: 0 0 1rem 0;

    padding: 3rem 3rem 7rem 3rem;
    width: 28rem;

    @include media(lg) {
      padding: 6rem 3.5rem;
      height: 100%;
      border-radius: 1rem;
      width: 31rem;
    }


    & .logoWrap{
      cursor: pointer;
      //margin-bottom: 6rem;
      display: none;

      @include media(lg) {
        display: block;
      }
    }

    & .balanceWrap{
      display: grid;
      align-items: center;

      border-radius: 1rem;
      border: 1px solid #FF8C00;
      background: rgba(255, 140, 0, 0.10);

      height: 3.4rem;
      width: max-content;

      color: #FFFFFF;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      padding: 0 1rem;
      margin: 4.5rem 0;
    }

    & .honeechizeWrap{
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 1rem;
      align-items: center;

      border-radius: 10px;
      border: 1px solid #FFC200;
      background: rgba(255, 194, 0, 0.10);

      height: 3.4rem;
      padding-left: 1rem;
      padding-right: 1.5rem;

      margin-bottom: 1.6rem;

      width: max-content;



      & .honeechizeImgWrap{
        display: grid;

        & img{
          width: 2.2rem;
          height: 2.2rem;
        }
      }

      & .honeechizeText{
        color: #FFC200;

        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    & .mainNavWrap{
      display: grid;
      grid-gap: 3rem;

      & .navItem{
        cursor: pointer;

        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1rem;

        @include media(fullHD) {
          grid-gap: 2rem;
        }

        &.navItemBoost{
          &.navItemActive{
            & span{
              color: #f13301 !important;
            }
          }

          &:hover{
            & span{
              color: #f13301 !important;
            }
          }
          & span{
            color: #f13301 !important;
          }
        }

        &.navItemIndex{
          & span{
            & span{
              color: #FFD600 !important;
            }
          }
        }

        &.navItemOrange{
          &.navItemActive{
            & span{
              color: #FFC200 !important;
            }
          }

          &:hover{
            & span{
              color: #FFC200 !important;
            }
          }
        }

        &.navItemActive{
          & span{
            color: #F7931A !important;
          }
        }

        &:hover{
          & span{
            color: #F7931A !important;
          }
        }

        & span{
          font-style: normal;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 2.2rem;

          color: #AFA293;

          & .titleWSF{
            color: #FFFFFF !important;
            font-size: 1rem;
            padding: 0.55rem 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid #FF8C00;
            background: rgba(255, 140, 0, 0.10);

            position: relative;
            top: -0.2rem;
          }
        }
      }
    }

    & .otherNavWrap{
      display: grid;
      grid-gap: 3rem;

      padding: 3.5rem 0;
      margin: 3.5rem 0;
      border-top: 1px solid #915207;
      border-bottom: 1px solid #915207;
      padding: 3.5rem 0;
      margin: 3.5rem 0;
      border-top: 1px solid #F5F5F5;
      border-bottom: 1px solid #F5F5F5;

      & .navItem{
        cursor: pointer;

        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1rem;

        @include media(fullHD) {
          grid-gap: 2rem;
        }

        &.navItemActive{
          & span{
            color: #F7931A !important;
          }
        }

        &:hover{
          & span{
            color: #F7931A !important;
          }
        }



        & span{
          font-style: normal;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 2.2rem;

          color: #AFA293;
        }
      }
    }

    & .socialsWrap{
      margin-top: 2.5rem;

      & .socialsTitle{
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;

        color: #F5F5F5;
        margin-bottom: 1.5rem;
      }

      & .socialsBox{
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-gap: 1rem;

        & .socItem{
          cursor: pointer;
          display: grid;
        }
      }
    }

    & .logOutBtn{
      cursor: pointer;

      border-radius: 10px;
      border: 0.766px solid #FF8C00;

      background: rgba(255, 140, 0, 0.10);

      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      justify-content: center;
      grid-gap: 1rem;

      margin-top: 2.5rem;
      padding: 0.75rem;

      @include media(fullHD) {
        grid-gap: 2rem;
      }

      & img{
        display: grid;
      }

      & span{
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;

        color: #F5F5F5;
      }
    }
  }
}