@import 'resources.scss';

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
  font-family: 'JetBrains Mono', monospace;
  //font-family: 'Open Sans', sans-serif;
  //font-family: 'Proxima Nova', sans-serif;
  line-height: 2rem;

  box-sizing: border-box;
  scroll-behavior: smooth;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;

  outline: none !important;
}

html {
  max-width: 100vw;
  font-size: 10px;
  overflow-x: hidden;
}

//.withRuFont *{
//  font-family: 'JetBrains Mono', monospace !important;
//}

body {
  &::-webkit-scrollbar {width: 0;}
    padding: 0 !important;
  //background: $pageBg;
  //overflow-x: hidden;
}

#root{
  height: 100vh;
  scroll-behavior: initial !important;
}

.Toastify {
  position: absolute;
}

.block-header{
  color: #FFFFFF;

  text-align: center;
  font-family: 'Phage Regular KG', sans-serif;
  text-transform: uppercase;
  font-size: 2.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 1rem;
  border: 1px solid #FFD600;

  padding: 7.75rem 1rem;
  margin-bottom: 2.5rem;

  background-image: url("../assets/svg/h2_academy_lesson_hbg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.block-title{
  color: #FFFFFF;

  text-align: center;
  font-family: 'Phage Regular KG', sans-serif;
  text-transform: uppercase;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 1rem;
  border: 1px solid #FFD600;

  padding: 2.5rem 1rem;
  margin-bottom: 2.5rem;
}

.block-footer{
  color: #FFFFFF;

  text-align: center;
  font-family: 'Phage Regular KG', sans-serif;
  text-transform: uppercase;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 1rem;
  border: 1px solid #FFD600;

  padding: 7rem 1rem;
  margin-bottom: 2.5rem;
  background-image: url("../assets/svg/h2_academy_lesson_fbg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.theme-color{
  color: #FFD600 !important;
}
