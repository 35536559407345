@import "../../theme/scss/resources";


:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }
}