@import "../../theme/scss/resources";

.container{
  //background: #0d0a07;
  padding-top: 12rem;
  padding-bottom: 10rem;

  & .contentWrap{
    & .title1{
      text-align: center;
      font-weight: 400;
      & span{
        color: #FF8C00;
      }
    }

    & .button{
      width: max-content;
      margin: 0 auto;
      margin-top: 10rem;
    }
  }
}