@import "../../theme/scss/resources";

.container{


  @include media(xl) {
    grid-column: 1 / 3;
  }

  & .contentWrap{
    display: grid;

    grid-gap: 3rem;

    //@include media(xl) {
    //  grid-template-columns: 30rem 1fr;
    //}

    & .investWrap{
      & .titleWSF{

      }

      & .inputWrap{
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;

        & .inputTitle{
          color: #F5F5F5;

          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0.75rem;
        }

        & .inputAvailable{
          color: #F5F5F5;

          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 0.5rem;

          & span{
            color: #0FF;
          }
        }
      }

      & .reinvestWrap{
        & .reinvestBox{
          display: grid;
          grid-template-columns: max-content max-content;
          align-items: center;
          grid-gap: 1.25rem;

          & .profitInvestItemTitle{
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.9rem;

            color: #F5F5F5;
          }

          & .profitBox{
            background: rgba(3, 183, 89, 0.1);
            border: 1px solid #03B759;
            border-radius: 1rem;

            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 1.8rem;

            color: #F5F5F5;
            max-width: 4.919rem;
            text-align: center;
            padding: 0.7rem 0;

            @include media(md) {
              max-width: initial;
              width: 8.3rem;
            }
          }

          & .investBox{
            & input{
              position: relative;
              width: 6.9rem;
              height: 3.4rem;
              -webkit-appearance: none;
              outline: none;
              background: #211406;
              border-radius: 2rem;
              transition: 0.5s;
              border: 1px solid #8C9497;

              &:checked{
                border: 1px solid #00FF7A;

                &:before{
                  left: 4rem;
                  background: #00FF7A;
                }
              }

              &:before{
                content: '';
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 5rem;
                background: #8C9497;
                transition: 0.5s;
              }
            }
          }
        }

        & .reinvestNote{
          color: #FFFFFF;

          font-size: 1.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-top: 2rem;

          & span{
            color: #FF8C00;
          }
        }
      }
    }

    & .detailsWrap{
      & .titleRow{
        display: grid;
        grid-gap: 2rem;

        @include media(md) {
          grid-auto-flow: column;
          justify-content: space-between;
          align-items: center;
        }

        & .imgWrap{
          display: grid;
        }

        & .button{

        }
      }

      & .dataWrap{
        display: grid;
        grid-gap: 1.75rem;

        margin-top: 3.5rem;

        @include media(xl) {
          grid-template-columns: max-content 1fr;
        }

        @include media(xl) {
          grid-template-columns: repeat(3, 1fr);
        }

        & .dataItem{
          display: grid;
          grid-gap: 1rem;

          @include media(md) {
            grid-template-columns: initial;
            align-items: center;
          }

          &:last-child{

            @include media(xl) {
              grid-template-columns: initial;
            }

            @include media(xl) {
              grid-column: 1 / 4;
            }

            & .dataItemValue{
              @include media(xl) {
                width: 100%;
              }
            }
          }

          & .dataItemName{
            color: #FFFFFF;

            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & .dataItemValue{
            display: grid;
            align-items: center;
            justify-items: center;

            color: #00FF7A;

            text-align: center;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.6rem;

            border-radius: 1rem;
            border: 1px solid #00FF7A;

            background: rgba(0, 255, 122, 0.10);

            height: 4.8rem;
            width: 100%;
            padding: 0 2.25rem;

            @include media(xl) {
              width: 100%;
            }

            @include media(fullHD) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}