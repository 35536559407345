@import "../../theme/scss/resources";

.container{

  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 4rem;

      color: #FF8C00;

      margin-bottom: 1.75rem;

      text-align: center;

      @include media(md) {
        text-align: left;

        font-size: 3.8rem;
        line-height: 5.2rem;

        margin-bottom: 3rem;
      }
    }

    & .subTitle{
      font-style: normal;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 2.5rem;

      color: #F5F5F5;
      margin-bottom: 1rem;
      text-align: center;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;

        margin-bottom: 1.5rem;
        text-align: left;
      }
    }

    & .note{
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2rem;

      color: #F5F5F5;
      max-width: 62.5rem;

      @include media(md) {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
    }

    & .noteTitle{
      font-style: normal;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 2.5rem;

      color: #FF8C00;
      text-align: center;

      margin-top: 1.5rem;
      margin-bottom: 1rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;

        text-align: left;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
      }
    }

    & .mainBtn{
      display: grid;
      justify-items: center;
      align-items: center;
      justify-content: center;
      grid-template-columns: max-content max-content;
      grid-gap: 0.6rem;

      cursor: pointer;

      background: #FF8C00;
      border: 1px solid #FF8C00;
      border-radius: 10px;

      max-width: 30rem;
      width: 100%;
      height: 4.8rem;

      margin: 0 auto;
      margin-top: 2.5rem;
      margin-bottom: 3.5rem;

      @include media(md) {
        margin: initial;
        margin-top: 5.5rem;
        margin-bottom: 3.5rem;
      }



      & .mainBtnContent{
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #F5F5F5;
      }

      & img{
        position: relative;
        top: 0.05rem;
      }
    }
  }
}