@import "../../theme/scss/resources";

.container{
  //background: #213034;
  //border-radius: 1rem;
  //border: 1px solid #8C9497;
  grid-column: 1 / 3;

  & .contentWrap {
    display: grid;

    padding: 3.5rem;


    @include media(fullHD) {
      padding: 3.5rem 6.5rem;
    }

    &.contentWrapMBC{
      & .note{
        margin-bottom: 2rem !important;
      }
    }

    & .title{
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.6rem;

      color: #F5F5F5;
    }

    & .note{
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.5rem;

      color: #FFFFFF;
      margin-top: 3.5rem;
      margin-bottom: 9rem;

      @include media(md) {
        font-size: 2rem;
        line-height: 3rem;
      }

      & span{
        cursor: pointer;
        color: #F7931A;
        text-decoration: underline;
      }
    }

    & .ambassadorlink{
        color: #f7931a;
        cursor: pointer;
        text-decoration: underline;
    }
    & .turnover{
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2rem;

      color: #F7931A;
      text-align: center;
      height: 10rem;

      margin-bottom: 1rem;

      @include media(md) {
        font-size: 2rem;
        line-height: 3rem;
      }
    }

    & .btn{
      position: relative;
      display: grid;
      align-items: center;

      cursor: pointer;
      background: #F7931A;
      border: 1px solid #F7931A;
      border-radius: 1rem;

      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.7rem;
      text-align: center;

      color: #F5F5F5;
      padding: 1rem 0;
      max-width: 69rem;
      width: 100%;
      margin:  0 auto;

      &.btnCs{
        border: 1px solid #6E7679 !important;
        background: transparent !important;
        color: #6E7679 !important;
        cursor: initial !important;
        font-size: 1.4rem !important;
      }

      & .btnAbsSoon{
        position: absolute;
        right: -7rem;
        top: -0.2rem;

        cursor: pointer;

        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: #F5F5F5;
        border: 1px solid #F5F5F5;
        padding: 0.5rem;
        border-radius: 0.5rem;
      }
    }

    & .btnWC{
      color: #F5F5F5 !important;
      background: #8C9497 !important;
      border: 1px solid #8C9497 !important;
      cursor: initial !important;
    }
  }
}