@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    & .text{
      color: #FFFFFF;

      font-size: 3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      text-align: center;

      @include media(sm) {
        font-size: 4.63rem;
      }

      & span{
        color: #FFD600;

        font-size: 3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include media(sm) {
          font-size: 4.63rem;
        }
      }
    }
  }
}