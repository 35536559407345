@import "../../theme/scss/resources";

.container{
  //@include pagePadding;
  padding-top: 1.5rem !important;

  @include media(md) {
    padding-top: 3rem !important;
  }

  & .contentWrap{
    & .contentBox{
      & .title{
        position: relative;

        font-style: normal;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 4rem;

        color: #FF8C00;

        margin-bottom: 1.75rem;

        text-align: center;

        @include media(md) {
          text-align: left;

          font-size: 3.8rem;
          line-height: 5.2rem;

          margin-bottom: 3rem;
        }
      }

      & .serverWrap{
        & .regionWrap{
          & .regionTitle{
            color: #FFFEFE;

            font-size: 2.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            margin-bottom: 2rem;
          }

          & .regionBox{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 2.5rem;

            & .regionItem{
              cursor: pointer;

              display: grid;
              align-items: center;
              justify-items: center;

              border-radius: 1rem;
              border: 1px solid #FF8C00;
              background: rgba(255, 140, 0, 0.10);

              color: #FF8C00;

              text-align: center;
              font-size: 1.8rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;

              height: 4.8rem;
              width: 100%;

              &.regionItemActive{
                border: 1px solid #FF8C00 !important;
                background: #FF8C00 !important;
                color: #2B1D02 !important;
              }
            }
          }
        }

        & .detailsWrap{
          margin-top: 3rem;

          & .detailsTitle{
            color: #FFFEFE;

            font-size: 2.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            margin-bottom: 2rem;
          }

          & .detailsBox{
            display: grid;
            grid-gap: 2.5rem;

            min-height: 38.5rem;

            @include media(sm) {
              grid-template-columns: repeat(2, 1fr);
              min-height: 76.5rem;
            }

            @include media(md) {
              grid-gap: 4.5rem;
            }

            @include media(xl) {
              grid-template-columns: repeat(4, 1fr);
              min-height: 38.5rem;
            }


            & .detailsBoxItem{
              cursor: pointer;
              width: 100%;
              height: max-content;

              background: rgba(255, 255, 0, 0.01);
              border: 1px solid #FF0;
              backdrop-filter: blur(7px);
              border-radius: 1rem;
              margin-top: 4.6rem !important;

              padding: 2rem;

              &.detailsBoxItemActive{
                background: #008f44 !important;

                & .serverName{
                  color: #FFFFFF !important;
                }

                & .serverBarWrap{
                  & .serverBarBox{
                    border: 1px solid #00FF7A !important;
                  }

                  & .serverBarAbs{
                    background: #00ad52 !important;
                  }
                }

                & .serverDataWrap{
                  & .serverDataValue{
                    border: 1px solid #00FF7A !important;
                  }
                }
              }

              //&.detailsBoxItemInviter{
              //  margin-top: 4.6rem !important;
              //}

              & .serverName{
                color: #FF0;
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;

                margin-bottom: 2rem;
              }

              & .serverInviterAbs{
                display: grid;
                align-items: center;
                justify-items: center;

                position: absolute;
                left: 0;
                top: -4.8rem;

                width: 100%;
                height: 3.6rem;

                color: #00FF7A;

                text-align: center;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 15px; /* 115.385% */
                text-transform: uppercase;

                border: 1px solid #00FF7A;
                border-radius: 1rem;
              }

              & .serverBarWrap{
                & .serverBarTitle{
                  font-size: 1.2rem;
                  margin-bottom: 1rem;
                }

                & .serverBarBox{
                  display: grid;
                  align-items: center;
                  justify-items: center;
                  height: 3.5rem;

                  position: relative;

                  background: rgba(255, 255, 0, 0.01);
                  border: 1px solid #FF0;
                  backdrop-filter: blur(7px);
                  border-radius: 1rem;

                  margin-bottom: 1.5rem;



                  & .serverBarBoxValue{
                    position: relative;
                    z-index: 100;

                    color: #FFFFFF;

                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                  }

                  & .serverBarAbs{
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: #A9A900;
                    height: 100%;
                    border-radius: 1rem;

                    z-index: 10;
                  }
                }
              }

              & .serverDataWrap{
                margin-bottom: 1.5rem;

                &:last-child{
                  margin-bottom: 0;
                }

                & .serverDataName{
                  font-size: 1.2rem;
                  margin-bottom: 1rem;
                }

                & .serverDataValue{
                  display: grid;
                  align-items: center;
                  justify-items: center;

                  height: 3.5rem;

                  color: #FFFFFF;

                  text-align: center;
                  font-size: 1.6rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  background: rgba(255, 255, 0, 0.01);
                  border: 1px solid #FF0;
                  backdrop-filter: blur(7px);
                  border-radius: 1rem;
                }
              }
            }
          }

          & .mainBtn{
            display: grid;
            align-items: center;
            justify-items: center;

            border-radius: 1rem;
            border: 1px solid #AFA293;
            //background: #FF8C00;

            color: #AFA293;

            text-align: center;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            width: 30rem;
            height: 4.8rem;


            margin: 0 auto;
            margin-top: 4rem;

            @include media(md) {
              margin-top: 3rem;
            }

            &.mainBtnActive{
              cursor: pointer !important;
              border: 1px solid #FF8C00 !important;
              background: #FF8C00 !important;
              color: #FFFEFE !important;
            }
          }
        }
      }
    }
  }
}