@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

:global(.modalWithoutP-overlay) {
  :global(.popup-content) {
    padding: 0 !important;
  }
}

:global(#tradingview_fe159-wrapper) {
  width: 100% !important;
}


.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;

    top: 0.25rem;
    right: 0.25rem;

    @include media(md) {
      top: 1rem;
      right: 1rem;
    }
  }

  & .modalContentWrap{
    & .tabRow{
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: 3rem;
      align-items: center;
      margin-bottom: 2rem;

      & .tabRowItem{
        cursor: pointer;

        color: #5D6588;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:hover{
          color: #F7931A !important;
        }

        &.tabRowItemActive{
          color: #F5F5F5;
        }

        //&:last-child{
        //  display: none;
        //}
      }
    }

    & .modalContentBox{
      height: 50rem;

      @include media(xxl) {
        height: 62rem;
      }

      & .loadingWrap{
        display: grid;
        align-items: center;
        text-align: center;
        height: 100%;

        color: #F5F5F5;
        font-size: 2.2rem;
        line-height: 2.2rem;
        font-style: normal;
        font-weight: 700;
      }

      & .newsPosWrap{
        & .newsWrap{
          overflow-y: scroll;
          //overflow-x: hidden;
          height: 47rem;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
          -ms-overflow-style: none;

          @include media(xxl) {
            height: 59rem;
          }

          & .newsBox{
            & .newsTitle{
              color: #FFFFFF;
              font-size: 1.75rem;
              line-height: 2rem;
              font-style: normal;
              font-weight: 700;


              height: 4rem;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;

              @include media(md) {
                font-size: 2.5rem;
                line-height: 3.2rem;
                height: 6rem;
              }
            }

            & .newsDate{
              margin-top: 0.8rem;
              margin-bottom: 1.4rem;

              color: #F7931A;

              font-size: 1.5rem;
              line-height: 1.5rem;
              font-style: normal;
              font-weight: 400;

              @include media(md) {
                font-size: 1.8rem;
                line-height: 1.8rem;

                margin-top: 1.6rem;
                margin-bottom: 2.8rem;
              }
            }

            & .newsImgWrap{
              display: grid;

              & img{
                width: 100%;
                max-height: 35rem;
              }
            }

            & .newsDescription{
              margin-top: 1.5rem;

              color: #FFFFFF;

              font-size: 1.5rem;
              line-height: 1.75rem;
              font-style: normal;
              font-weight: 400;

              @include media(md) {
                font-size: 1.8rem;
                line-height: 2.8rem;

                margin-top: 3rem;
              }
            }
          }

          & .newsLoading{
            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            height: 100%;

            color: #FFFFFF;

            font-size: 1.8rem;
            line-height: 2.8rem;
            font-style: normal;
            font-weight: 400;

          }
        }

        & .navRow{
          display: grid;
          grid-auto-flow: column;
          justify-content: space-between;
          align-content: center;

          & .navBtn{
            cursor: pointer;

            display: grid;
            grid-template-columns: max-content max-content;
            grid-gap: 1rem;
            align-content: center;
            justify-content: right;

            margin-top: 1rem;

            &:hover{
              & .navBtnText{
                color: #F7931A !important;
              }
            }

            &:last-child{
              & .navBtnImgWrap{
                transform: rotate(180deg);
              }
            }

            & .navBtnText{
              color: #58C1EF;
              font-size: 1.8rem;
              line-height: 2.7rem;
              font-style: normal;
              font-weight: 400;
            }

            & .navBtnImgWrap{
              display: grid;
              align-items: center;


              position: relative;
              top: 1px;
            }
          }
        }
      }

      & .infoWrap{
        overflow-y: scroll;
        //overflow-x: hidden;
        height: 50rem;

        @include media(xxl) {
          overflow-y: initial;
          height: auto;
        }

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        & .timeRow{
          display: grid;
          grid-gap: 2rem;
          align-items: center;
          margin-bottom: 1.5rem;

          @include media(md) {
            grid-auto-flow: column;
          }

          & .pairName{
            color: #F5F5F5;
            text-align: right;
            font-size: 2.1rem;
            line-height: 2.2rem;
            font-style: normal;
            font-weight: 700;

            @include media(md) {
              text-align: left;
            }
          }

          & .timeBox{
            display: grid;
            grid-template-columns: repeat(6, max-content);
            align-items: center;

            padding: 1rem 0;
            border-radius: 1rem;
            border: 1px solid #F7931A;

            & .timeBoxItem{
              border-right: 1px solid #5D6588;
              padding: 0 1rem;

              width: 1fr;

              @include media(md) {
                padding: 0 1.5rem;
              }

              &:last-child{
                border-right: none;
              }

              & .textContent{
                cursor: pointer;

                text-align: center;
                color: #5D6588;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 1.1rem;
                line-height: 1.2rem;
                font-style: normal;
                font-weight: 400;

                @include media(md) {
                  font-size: 1.4rem;
                  line-height: 1.4rem;
                }

                &:hover{
                  color: #F7931A !important;
                }

                &.textContentActive{
                  color: #F7931A !important;
                }
              }
            }
          }
        }

        & .graphWrap{
          height: 36rem;
        }

        & .predictionWrap{
          display: grid;

          grid-gap: 4rem;
          margin-top: 2.5rem;

          @include media(md) {
            grid-template-columns: 27rem 1fr;
            grid-gap: 1.75rem;
          }

          & .signalBox{
            & .signalController{
              cursor: pointer;

              display: grid;
              grid-template-columns: max-content max-content;
              grid-gap: 1rem;
              align-items: center;

              & .signalControllerText{
                color: #F5F5F5;
                font-size: 2.3rem;
                line-height: 2.4rem;
                font-style: normal;
                font-weight: 400;
              }

              & .signalControllerArrow{
                display: grid;
                transform: rotate(180deg);

                &.signalControllerArrowOpen{
                  transform: rotate(0deg) !important;
                }
              }
            }

            & .signalContent{
              & .signalOpen{
                height: 14.2rem;
                width: 27rem;
                overflow: hidden;
                margin-top: 1.6rem;

                border-radius: 1rem;
                border: 1px solid #58C1EF;

                background: #031319;

                & .signalOpenBox{
                  margin: 2rem;
                  height: 10.2rem;
                  overflow-y: scroll;
                  scroll-padding-bottom: 0;

                  &::-webkit-scrollbar-track {border-radius: 0.6rem; background: #737b7e}
                  &::-webkit-scrollbar {width: 0.5rem;}
                  &::-webkit-scrollbar-thumb {border-radius: 4px;background: #d9d9d9;}

                  & .signalOpenBoxItem{
                    display: grid;
                    grid-template-columns: repeat(3, max-content);
                    grid-gap: 1.25rem;
                    padding: 0.4rem 0;

                    & .signalOpenBoxItemName{
                      color: #f5f5f5;
                      font-size: 1.8rem;
                      line-height: 1.8rem;
                      font-style: normal;
                      font-weight: 400;
                    }

                    & .signalOpenBoxItemSeparator{
                      color: #f5f5f5;
                      font-size: 1.8rem;
                      line-height: 1.8rem;
                      font-style: normal;
                      font-weight: 400;
                    }

                    & .signalOpenBoxItemPrediction{
                      & .ct{
                        display: grid;
                        align-items: center;
                        justify-items: center;
                        text-align: center;

                        color: #F5F5F5;
                        font-size: 1.8rem;
                        line-height: 1.8rem;
                        font-style: normal;
                        font-weight: 400;

                        text-transform: uppercase;
                      }

                      & .cth{
                        color: #FFA500 !important;
                      }

                      & .cts{
                        color: #F7343E !important;
                      }

                      & .ctb{
                        color: #03B759 !important;
                      }
                    }
                  }
                }
              }

              & .signalClose{
                & .totalRow{
                  display: grid;
                  grid-template-columns: max-content 1fr;
                  grid-gap: 2rem;
                  align-items: center;

                  margin-top: 3rem;
                  width: 27rem;

                  & .totalRowValues{
                    color: #F5F5F5;

                    font-size: 2rem;
                    line-height: 2rem;
                    font-style: normal;
                    font-weight: 400;
                  }

                  & .totalRowPredictions{
                    display: grid;
                    justify-self: end;

                    & .cb{
                      display: grid;
                      align-items: center;
                      justify-items: center;
                      text-align: center;

                      border-radius: 1rem;
                      border: 1px solid #f5f5f5;

                      color: #F5F5F5;
                      font-size: 2rem;
                      line-height: 2rem;
                      font-style: normal;
                      font-weight: 400;

                      text-transform: uppercase;

                      width: 9.5rem;
                      height: 4.8rem;

                      //border: 1px solid #03B759;
                      //
                      //background: rgba(3, 183, 89, 0.10);
                    }

                    & .cbh{
                      border: 1px solid #FFA500 !important;
                      background: rgba(247, 147, 26, 0.10) !important;
                    }

                    & .cbs{
                      border: 1px solid #F7343E !important;
                      background: rgba(247, 52, 62, 0.10) !important;
                    }

                    & .cbb{
                      border: 1px solid #03B759 !important;
                      background: rgba(3, 183, 89, 0.10) !important;
                    }
                  }
                }

                & .mainSignalWrap{
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-gap: 2.5rem;
                  width: 27rem;

                  margin-top: 1.5rem;

                  & .mainSignalItem{
                    display: grid;
                    grid-template-columns: 6.2rem max-content;
                    grid-gap: 1rem;
                    align-items: center;

                    & .mainSignalItemName{
                      color: #F5F5F5;
                      font-size: 2rem;
                      line-height: 2rem;
                      font-style: normal;
                      font-weight: 400;
                      text-align: left;
                    }

                    & .mainSignalItemPrediction{
                      & .ct{
                        display: grid;
                        align-items: center;
                        justify-items: center;
                        text-align: center;

                        color: #F5F5F5;
                        font-size: 2rem;
                        line-height: 2rem;
                        font-style: normal;
                        font-weight: 400;

                        text-transform: uppercase;
                      }

                      & .cth{
                        color: #FFA500 !important;
                      }

                      & .cts{
                        color: #F7343E !important;
                      }

                      & .ctb{
                        color: #03B759 !important;
                      }
                    }
                  }
                }
              }
            }
          }

          & .predictionBox{
            & .predictionRow{
              display: grid;
              grid-template-columns: max-content max-content;
              align-items: center;
              grid-gap: 1.75rem;

              & .predictionTitle{
                color: #f5f5f5;
                font-size: 2.3rem;
                line-height: 2.4rem;
                font-style: normal;
                font-weight: 400;
              }

              & .predictionValue{
                & .ct{
                  display: grid;
                  align-items: center;
                  justify-items: center;
                  text-align: center;

                  color: #F5F5F5;
                  font-size: 2.3rem;
                  line-height: 2.4rem;
                  font-style: normal;
                  font-weight: 400;

                  text-transform: uppercase;
                }

                & .cth{
                  color: #FFA500 !important;
                }

                & .cts{
                  color: #F7343E !important;
                }

                & .ctb{
                  color: #03B759 !important;
                }
              }
            }

            & .predictionText{
              color: #8C9497;

              font-size: 1.15rem;
              line-height: 1.25rem;
              font-style: normal;
              font-weight: 400;
              padding-top: 1rem;

              text-align: left;

              @include media(md) {
                padding-top: 3rem;
              }
            }
          }
        }
      }
    }
  }
}