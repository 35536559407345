@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap {
    & .titleWSF {
      margin-bottom: 2.5rem;
    }

    & .priceRow{
      display: grid;

      align-items: center;
      grid-gap: 0.25rem;

      @include media(md) {
        grid-template-columns: max-content 1fr;
        grid-gap: 1.25rem;
      }

      & .priceNote{
        color: #FFFFFF;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      & .price{
        display: grid;
        align-items: center;
        justify-items: center;
        text-align: center;

        border-radius: 1rem;
        border: 1px solid #FF8C00;

        background: rgba(255, 140, 0, 0.05);

        color: #FF8C00;

        text-align: center;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        height: 4.1rem;
        width: max-content;

        padding: 0 1rem;
      }
    }

    & .textRow{
      color: #FF8C00;

      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }

    & .balanceRow{
      display: grid;
      align-items: center;
      grid-gap: 0.25rem;

      @include media(md) {
        grid-template-columns: max-content 1fr;
        grid-gap: 1.25rem;
      }

      & .balanceNote{
        color: #FFFFFF;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      & .balance{
        display: grid;
        align-items: center;
        justify-items: center;
        text-align: center;

        border-radius: 1rem;
        border: 1px solid #FF060A;
        background: rgba(255, 6, 10, 0.10);
        color: #FF060A;

        text-align: center;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        height: 4.1rem;
        width: max-content;

        padding: 0 1rem;

        &.balanceSuccess{
          border: 1px solid #00FF7A !important;
          background: rgba(0, 255, 122, 0.05) !important;
          color: #00FF7A !important;
        }
      }
    }

    & .commission{
      color: #f5f5f5;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }

    & .cabinetBtnDisable{
      height: 4.8rem;

      display: grid;
      align-items: center;
      justify-items: center;
      text-align: center;

      font-size: 1.8rem;
      line-height: 1.8rem;
    }
  }
}