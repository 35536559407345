@import "../../theme/scss/resources";


:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    & .formWrap{
      & .formTitle{
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #F5F5F5;
        margin-bottom: 1.5rem;
      }

      & .inputWrap{
        @include media(md) {
          display: grid;
          grid-template-columns: 1fr 14rem;
          align-items: start;
          grid-gap: 1.2rem;
        }

        & .inputBox{
          position: relative;

          & input{
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #F5F5F5;
            width: 100%;

            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            border-radius: 1rem;

            padding: 1.4rem 2.4rem;
          }

          & .inputSum{
            padding: 1.4rem 6.4rem 1.4rem 2.4rem;
            -moz-appearance: textfield;

            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          & .inputAbs{
            position: absolute;
            right: 2rem;
            top: 1.35rem;
            height: 2rem;

            & .inputAbsCur{
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F5F5F5;
              padding-right: 1rem;
            }

            & .inputAbsBtn{
              cursor: pointer;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F7931A;
              padding-left: 1rem;
              border-left: 1px solid #D9D9D9;
            }
          }

          & .inputNote{
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #F5F5F5;
            margin-top: 0.5rem;

            & span{
              color: #F7931A;
            }
          }
        }

        & .mainBtn{
          display: none;
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.5rem;
          text-align: center;
          padding: 0.95rem 0;

          color: #8C9497;

          border: 1px solid #8C9497;
          border-radius: 1rem;

          @include media(md) {
            display: block;
          }

          &.mainBtnActive{
            cursor: pointer;

            background: #F7931A;
            color: #F5F5F5;
            border: 1px solid #F7931A;
          }
        }
      }

      & .daysWrap{
        margin-top: 1.5rem;
        margin-bottom: 2rem;

        & .daysTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.9rem;

          margin-bottom: 1rem;
          color: #F5F5F5;
        }

        & .daysBox{
          display: grid;
          grid-auto-flow: column;
          grid-gap: 1.8rem;
          align-items: center;

          @include media(md) {
            justify-content: left;
          }

          &.daysBoxInitial{
            & .daysBoxItem{
              &:first-child{
                color: #F7931A;
                background: rgba(247, 147, 26, 0.1);
                border: 1px solid #F7931A;
              }
            }
          }

          & .daysBoxItem{
            cursor: pointer;

            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 1.8rem;

            color: #F5F5F5;
            border: 1px solid #F5F5F5;
            border-radius: 1rem;
            max-width: 8.3rem;
            text-align: center;
            padding: 0.7rem 0;

            @include media(md) {
              max-width: initial;
              width: 8.3rem;
            }

            &.daysBoxItemActive{
              color: #F7931A;
              background: rgba(247, 147, 26, 0.1);
              border: 1px solid #F7931A;
            }
          }
        }
      }

      & .profitInvestWrap{
        display: grid;
        grid-template-columns: 4.919rem max-content;
        grid-gap: 4rem;

        margin-bottom: 2.5rem;

        @include media(md) {
          grid-template-columns: 8.3rem max-content;
        }

        & .profitInvestWrapItem{
          & .profitInvestItemTitle{
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.9rem;

            color: #F5F5F5;
            margin-bottom: 1rem;
          }

          & .profitBox{
            background: rgba(3, 183, 89, 0.1);
            border: 1px solid #03B759;
            border-radius: 1rem;

            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 1.8rem;

            color: #F5F5F5;
            max-width: 4.919rem;
            text-align: center;
            padding: 0.7rem 0;

            @include media(md) {
              max-width: initial;
              width: 8.3rem;
            }
          }

          & .investBox{
            & input{
              position: relative;
              width: 6.9rem;
              height: 3.4rem;
              -webkit-appearance: none;
              outline: none;
              background: #D9D9D9;
              border-radius: 2rem;
              transition: 0.5s;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

              &:checked{
                background: #F7931A;

                &:before{
                  left: 4rem;
                }
              }

              &:before{
                content: '';
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 5rem;
                background: #ffffff;
                transition: 0.5s;
              }
            }
          }
        }
      }

      & .noteWrap{
        & .noteWrapRow{
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.9rem;

          color: #F5F5F5;
          margin-bottom: 0.5rem;

          & span{
            color: #F7931A;
          }
        }

        & .noteWrapText{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #8C9497;
        }
      }

      & .mainBtnM{
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        text-align: center;
        padding: 1rem 0;

        color: #8C9497;

        border: 1px solid #8C9497;
        border-radius: 1rem;

        margin-top: 3rem;

        @include media(md) {
          display: none;
        }

        &.mainBtnMActive{
          cursor: pointer;

          background: #F7931A;
          color: #F5F5F5;
          border: 1px solid #F7931A;
        }
      }
    }
  }
}