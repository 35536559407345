@import "../../../theme/scss/resources";

.container{
  & .contentWrap{
    display: grid;
    grid-template-columns: max-content 1fr max-content;

    border-top: 1px solid #f5f5f5;
    margin-top: 1.5rem;
    padding-top: 2rem;

    & .staticBtn{
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      grid-gap: 1.2rem;


      &.staticBtnActive{
        cursor: pointer;

        &:hover{
          & .btnText{
            color: #F7931A !important;
          }
        }
      }

      & .btnText{
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2rem;

        color: #F5F5F5;
      }
    }

    & .apyBtnWrap{
      display: grid;
      grid-auto-flow: column;
      width: max-content;
      justify-self: center;
      & .apyBtnItem{
        cursor: pointer;
        display: grid;
        align-items: center;
        justify-items: center;

        width: 4rem;
        height: 4rem;
        border-radius: 0.8rem;

        border: 1px solid transparent;

        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;

        color: #F5F5F5;

        &.apyBtnItemActive{
          border: 1px solid #F7931A;
          background: rgba(247, 147, 26, 0.1);
        }
      }
    }
  }
}