@import "../../theme/scss/resources";

.container{
  padding-top: 1.5rem !important;
  padding-bottom: 3.5rem !important;

  @include media(md) {
    padding-top: 3rem !important;
    padding-bottom: 7rem !important;
  }

  & .contentWrap{
    min-height: 55rem;

    & .title{
      font-style: normal;
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 4rem;

      color: #FF8C00;

      margin-bottom: 1.75rem;

      text-align: center;

      @include media(md) {
        text-align: left;

        font-size: 3.8rem;
        line-height: 5.2rem;

        margin-bottom: 3.5rem;
      }
    }

    & .typeWrap{
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      max-width: 30rem;
      background: rgba(15, 32, 52, 0.1);
      border: 1px solid #FF8C00;
      backdrop-filter: blur(7px);
      border-radius: 1rem;
      margin: 0 auto 1.75rem;
      overflow: hidden;

      @include media(md) {
        margin: 0 0 2.5rem 0;
      }

      & .typeItem{
        cursor: pointer;

        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.5rem;

        color: #696B6B;
        padding: 2rem 0;

        text-align: center;

        @include media(md) {
          font-size: 2rem;
          line-height: 2rem;
        }

        &:first-child{
          border-right: 1px solid #FF8C00;
        }

        &:hover{
          color: #FF8C00;
        }

        &.typeItemActive{
          background: #FF8C00;
          color: #F5F5F5;

          &:hover{
            color: #F5F5F5;
          }
        }
      }
    }

    & .inputsBox{
      display: grid;
      //grid-gap: 2rem;

      max-width: 30rem;
      margin: 0 auto;

      @include media(md) {
        margin: initial;
      }

      & .note{
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;
        max-width: 30rem;
        color: #F5F5F5;

        margin-bottom: 2rem;

        & span{
          cursor: pointer;
          color: #FF8C00;
          border-bottom: 1px solid #FF8C00;
        }
      }

      & .inputWrap{
        position: relative;

        &.inputWrapWithErr{
          & input{
            border: 1px solid #CF0000;
          }
        }

        & .inputTitle{
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;

          margin-bottom: 0.75rem;
        }

        & input{
          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #FF8C00;
          backdrop-filter: blur(7px);

          border-radius: 1rem;

          height: 4.8rem;
          width: 100%;
          max-width: 30rem;

          font-size: 14px;
          line-height: 19px;

          color: #F5F5F5;
          padding: 0 1.5rem;

          &::placeholder {
            color: #696B6B !important;
            opacity: 1;
            font-size: 1.2rem !important;
            line-height: 1.6rem !important;
            vertical-align: middle !important;
          }
          &:-ms-input-placeholder {
            color: #696B6B !important;
            font-size: 1.2rem !important;
            line-height: 1.6rem !important;
            vertical-align: middle !important;
          }
          &::-ms-input-placeholder {
            color: #696B6B !important;
            font-size: 1.2rem !important;
            line-height: 1.6rem !important;
            vertical-align: middle !important;
          }
        }

        & .inputErr{
          color: #CF0000;
          font-style: normal;
          font-weight: 500;
          font-size: 1.25rem;
          line-height: 1.5rem;
          padding-top: 0.25rem;
          text-align: right;
          height: 3.3rem;
        }
      }
    }

    & .links{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

      color: #F5F5F5;

      padding-bottom: 3rem;

      text-align: center;

      @include media(md) {
        text-align: left;
      }

      & span{
        cursor: pointer;
        color: #FF8C00;
        border-bottom: 1px solid #FF8C00;
        margin-left: 0.25rem;
      }
    }

    & .mainBtn{
      cursor: pointer;

      background: #FF8C00;
      border-radius: 1rem;

      text-align: center;

      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #F5F5F5;

      max-width: 30rem;
      margin: 0 auto;

      padding: 1.25rem 0;

      @include media(md) {
        margin: initial;
      }
    }
  }
}