@import "../../theme/scss/resources";

.container{
  height: auto;
  overflow: hidden;
  grid-column: 1 / 3;

  //@include media(md) {
  //  height: 33rem;
  //}

  @include media(xl) {
    display: grid;
    grid-column: auto;
    order: 3;
    grid-row: 2 / 4;
  }

  & .honeeCardBody{
    & .cardBody{
      & .titleRow{
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 2rem;

        & .title3{
          margin-bottom: 0;
        }

        & .settingsBtn{
          display: none;
          cursor: pointer;

          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 2.2rem;
          text-decoration-line: underline;

          color: #F7931A;

          @include media(xs) {
            font-size: 1.6rem;
          }
        }
      }

      & .mainTaskWrap{
        & .inputTitle{
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.8rem;

          color: #F5F5F5;
          margin-bottom: 0.5rem;
        }

        & .inputWrap{
          display: grid;
          column-gap: 2rem;
          grid-template-columns: 1fr 10rem;

          &.inputWrapWithErr{
            position: relative;
            & input{
              border: 1px solid #CF0000;
            }

            & .inputErr{
              position: absolute;
              right: 12rem;
              bottom: -1.5rem;

              color: #CF0000;
              font-style: normal;
              font-weight: 500;
              font-size: 1.25rem;
              line-height: 1.5rem;
            }
          }

          & input{
            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            border-radius: 1rem;
            width: 100%;

            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.8rem;

            color: #F5F5F5;
            padding: 1.4rem 1.5rem;
          }

          & .sendBtn{
            display: grid;
            align-items: center;

            cursor: pointer;

            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.7rem;

            text-align: center;

            color: #F5F5F5;

            background: #F7931A;
            border-radius: 1rem;
            width: 10rem;
            height: 4.8rem;
          }
        }

        & .fakeInputWrap{
          display: grid;
          column-gap: 2rem;
          row-gap: 1rem;

          grid-template-columns: 1fr 1fr;
          grid-template-rows: 4.8rem 4.8rem;

          @include media(sm) {
            column-gap: initial;
            row-gap: initial;
            grid-gap: 2rem;
            grid-template-columns: 1fr 10rem;
            grid-template-rows: initial;
          }

          & .fakeInput{
            grid-column: 1 / 3;
            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            border-radius: 1rem;
            width: 100%;

            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.8rem;

            color: #F5F5F5;
            padding: 1.4rem 1.5rem;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include media(sm) {
              grid-column: initial;
            }
          }

          & .statusBtn{
            grid-column: 2;
            display: grid;
            align-items: center;
            justify-items: center;

            background: #F7931A;
            border-radius: 1rem;
            width: 100%;
            height: 4.8rem;

            &.statusBtnAccepted{
              background: #00411F !important;
              border: 1px solid #03B759 !important;
            }

            &.statusBtnConsideration{
              background: #2F2F2F !important;
              border: 1px solid #8C9497 !important;
            }
          }
        }

        & .noteWrap{
          display: grid;
          grid-gap: 1.5rem;
          margin-top: 1rem;
          padding-bottom: 1.25rem;
          border-bottom: 1px solid #F7931A;

          @include media(md) {
            grid-auto-flow: column;
            justify-content: space-between;
          }

          @include media(xl) {
            grid-auto-flow: initial;
            justify-content: initial;
          }

          @include media(xxl) {
            grid-auto-flow: column;
            justify-content: space-between;
          }



          & .noteWrapItem{
            & .noteWrapItemTitle{
              font-style: normal;
              font-weight: 400;
              font-size: 1.2rem;
              line-height: 1.8rem;

              color: #F5F5F5;

            }

            & .noteWrapItemContent{
              & .noteWrapItemContentRow{
                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;

                color: #F5F5F5;

                & .colored{
                  color: #F7931A !important;
                }
              }
            }
          }
        }
      }

      & .otherTaskWrap{
        display: grid;
        grid-gap: 2rem;

        margin-top: 1.25rem;

        @include media(sm) {
          grid-gap: 1rem;
        }

        & .otherTaskBox{
          & .inputWrap{
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: 1fr 10rem;
            grid-template-rows: max-content max-content;
            align-items: end;

            @include media(sm) {
              grid-template-columns: 1fr 10rem 10rem;
              grid-template-rows: initial;
              grid-gap: 2rem;
            }

            & .inputRow{
              grid-column: 1 / 3;
              grid-row: 1;

              @include media(sm) {
                grid-column: auto;
                grid-row: auto;
              }

              &.inputRowWithErr{
                position: relative;
                & input{
                  border: 1px solid #CF0000;
                }

                & .inputErr{
                  position: absolute;
                  right: 0;
                  bottom: -1.5rem;

                  color: #CF0000;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 1.25rem;
                  line-height: 1.5rem;
                }
              }

              & .inputTitle{
                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;

                color: #F5F5F5;
                margin-bottom: 0.5rem;
              }

              & input{
                background: rgba(15, 32, 52, 0.1);
                border: 1px solid #FF8C00;
                backdrop-filter: blur(7px);
                border-radius: 1rem;
                width: 100%;

                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;

                color: #F5F5F5;
                padding: 1.4rem 1.5rem;
              }
            }

            & .reward{
              grid-column: 1;
              grid-row: 2;

              @include media(sm) {
                grid-column: auto;
                grid-row: auto;
              }

              & .rewardTitle{
                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;

                color: #F5F5F5;
                margin-bottom: 0.5rem;
              }

              & .rewardValue{
                display: grid;
                align-items: center;
                justify-items: center;

                background: #00411F;
                border: 1px solid #03B759;
                border-radius: 1rem;

                width: 10rem;
                height: 4.8rem;

                font-style: normal;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 1.8rem;

                color: #F5F5F5;
              }
            }

            & .sendBtn{
              grid-column: 2;
              grid-row: 2;

              display: grid;
              align-items: center;

              cursor: pointer;

              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.7rem;

              text-align: center;

              color: #F5F5F5;

              background: #F7931A;
              border-radius: 1rem;
              width: 10rem;
              height: 4.8rem;

              @include media(sm) {
                grid-column: auto;
                grid-row: auto;
              }
            }
          }

          & .fakeInputWrap{
            display: grid;
            column-gap: 2rem;
            row-gap: 1rem;

            grid-template-columns: 1fr 1fr;
            grid-template-rows: max-content max-content;

            @include media(sm) {
              column-gap: 2rem;
              row-gap: 2rem;
              grid-template-columns: 1fr 10rem 10rem;
              grid-template-rows: initial;
            }

            & .inputRow {
              grid-column: 1 / 3;

              @include media(sm) {
                grid-column: initial;
              }

              & .inputTitle {
                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;

                color: #F5F5F5;
                margin-bottom: 0.5rem;
              }

              & .fakeInput{
                background: rgba(15, 32, 52, 0.1);
                border: 1px solid #FF8C00;
                backdrop-filter: blur(7px);
                border-radius: 1rem;
                width: 100%;

                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;

                color: #F5F5F5;
                padding: 1.4rem 1.5rem;
                overflow: hidden;


              }
            }

            & .reward{
              align-self: end;
              & .rewardTitle{
                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;

                color: #F5F5F5;
                margin-bottom: 0.5rem;
              }

              & .rewardValue{
                display: grid;
                align-items: center;
                justify-items: center;

                background: #00411F;
                border: 1px solid #03B759;
                border-radius: 1rem;

                width: 100%;
                height: 4.8rem;

                font-style: normal;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 1.8rem;

                color: #F5F5F5;

                @include media(sm) {
                  width: 10rem;
                }
              }
            }

            & .statusBtn{
              display: grid;
              align-items: center;
              justify-items: center;

              background: #F7931A;
              border-radius: 1rem;
              width: 100%;
              height: 4.8rem;
              align-self: end;

              @include media(sm) {
                width: 10rem;
              }

              &.statusBtnAccepted{
                background: #00411F !important;
                border: 1px solid #03B759 !important;
              }

              &.statusBtnConsideration{
                background: #2F2F2F !important;
                border: 1px solid #8C9497 !important;
              }
            }
          }

          & .btnInputWrap{
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: max-content max-content;
            align-items: end;

            @include media(sm) {
              grid-template-columns: 1fr 10rem 10rem;
              grid-template-rows: initial;
              grid-gap: 2rem;
            }

            & .btnInputBox{
              cursor: pointer;

              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 1rem;
              width: 100%;

              font-style: normal;
              font-weight: 400;
              font-size: 1.2rem;
              line-height: 1.8rem;

              color: #F7931A;
              padding: 1.4rem 1.5rem;
              height: 4.8rem;
              overflow: hidden;
            }
          }

          &.otherTaskBoxSocialContent{
            & .inputTitleRow{
              display: grid;


              @include media(sm) {
                grid-gap: 2rem;
                grid-template-columns: 1fr 10rem 10rem;
                align-items: end;
              }

              & .inputTitle{
                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;

                color: #F5F5F5;
                margin-bottom: 0.5rem;

                &:last-child{
                  display: none;
                  @include media(sm) {
                    display: block;
                  }
                }
              }
            }

            & .socialContent{
              & .socialRow{
                display: grid;
                grid-gap: 2rem;

                @include media(sm) {
                  grid-gap: 0.6rem;
                }

                &.fakeInputWrap{

                }

                & .inputWrapWithErr{
                  position: relative;
                  & input{
                    border: 1px solid #CF0000;
                  }

                  & .inputErr{
                    position: absolute;
                    right: 12rem;
                    bottom: -1.5rem;

                    color: #CF0000;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}