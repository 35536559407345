@import "../../theme/scss/resources";

//:global(.echarts-for-react) {
//  width: 100% !important;
//  height: 100% !important;
//}

.container{
  overflow: hidden;
  //@include media(xl) {
  //  display: grid;
  //  order: 3;
  //}

  & .honeeCardBody{
    display: grid;
    grid-template-rows: 1fr 21rem;
    height: 27.5rem;
    overflow: hidden;

    padding: 3.5rem 0 0 0 !important;

    @include media(xl) {
      height: 100%;
    }

    & .cardHeader {
      padding-bottom: 0;

      & .titleRow{
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;

        padding: 0 2rem;

        @include media(xs) {
          padding: 0 3.5rem;
        }

        //margin-bottom: 2rem;
        & .title3{
          margin-bottom: 0;
        }
      }
    }

    & .cardBody{
      padding: 0;
      //height: max-content;

      & .chartWrap{
        //height: max-content;

        & .soonNote{
          display: grid;
          align-items: center;
          justify-items: center;
          height: 21rem;

          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 2.5rem;
          color: #8C9497;
        }
      }
    }
  }
}