@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap{
    &.modalContentWrapShort{
      & .titleRow{
        display: none;
      }

      & .contentBox{
        grid-template-columns: 1fr;
      }
    }
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin-bottom: 1.25rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin-bottom: 2.5rem;
      }
    }

    & .textWrap{
      & .textWrapItem{
        color: #F5F5F5;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    & .titleRow{
      display: grid;
      grid-auto-flow: column;
      //margin-bottom: 2rem;

      @include media(lg) {
        display: none;
      }

      & .titleRowItem{
        cursor: pointer;

        color: #f5f5f5;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;

        text-align: center;
        border: 1px solid #ffffff;
        padding: 1rem 0;

        &:first-child{
          border-right: 1px solid #ffffff;
        }

        &.titleRowItemActive{
          &.bgPro{
            background: #F7931A;
          }

          &.bgReg{
            background: #58C1EF;
          }

          &.bgProPlus{
            background: #FFC200;
          }
        }

      }
    }

    & .contentBox{
      @include media(lg) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
      }

      & .contentBoxItem{
        display: none;
        border: 1px solid #58C1EF;
        border-radius: 1rem;
        padding: 1.5rem;
        margin-top: 7rem;
        position: relative;


        @include media(lg) {
          display: block;
          margin-top: 5rem;
        }

        &.contentBoxItemActive{
          display: block;
        }

        &.contentBoxItemPro{
          border: 1px solid #F7931A !important;

          & .contentBoxItemAbsNote{
            color: #F7931A !important;
            border: 1px solid #F7931A !important;
          }
        }

        &.contentBoxItemProPlus{
          border: 1px solid #FFC200 !important;
        }

        &.contentBoxItemCentral{
          @include media(lg) {
            grid-column: 1 / 3 !important;

            & .contentBoxItemName{
              text-align: center !important;
            }

            & .contentBoxItemPrice{
              text-align: center !important;
            }

            & .contentBoxItemPeriodWrap{
              grid-template-columns: repeat(3, max-content) !important;
              justify-content: center !important;
            }

            & .contentBoxItemLink{
              text-align: center !important;
            }
          }
        }

        & .contentBoxItemAbsNote{
          position: absolute;
          left: 0;
          top: -5rem;

          width: 100%;
          height: 4rem;

          display: grid;
          align-items: center;
          justify-items: center;
          text-align: center;

          border-radius: 1rem;
          border: 1px solid #FF0;
          background: rgba(255, 255, 0, 0.10);
          backdrop-filter: blur(32px);

          color: #FF0;
        }

        & .contentBoxItemName{
          color: #58C1EF;
          font-size: 2.6rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 1.3px;

          margin-bottom: 2.5rem;

          & span{
            font-size: 2.6rem !important;
          }
        }

        & .contentBoxItemPrice{
          color: #ffffff;
          font-size: 1.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          & span{
            color: #58C1EF;
          }

          & .contentBoxItemPriceText{
            color: #ffffff;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        & .contentBoxItemPeriodWrap{
          margin: 1.5rem 0;
          display: grid;
          grid-gap: 1.5rem;

          min-height: 6.3rem;
          align-items: center;

          & .contentBoxItemPeriodItem{
            cursor: pointer;

            display: grid;
            grid-template-columns: max-content 1fr;
            grid-gap: 1.2rem;

            & .contentBoxItemPeriodItemImgWrap{
              display: grid;
            }

            & .contentBoxItemPeriodItemValue{
              color: #F5F5F5;
              font-size: 2rem;
              font-style: normal;
              font-weight: 400;
              line-height: 2.37rem;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
    & .contentBoxItemLink{
      cursor: pointer;

      color: #58C1EF;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }

    & .btn{
      margin-top: 1.5rem;

      &.btnRegular{
        background: #58C1EF;
      }

      &.btnYellow{
        background: #FFC200;
      }

      &.btnDisabled{
        cursor: initial !important;

        color: #8C9497 !important;
        background-color: transparent !important;
        border: 1px solid #8C9497 !important;
      }
    }

    & .balance{
      color: #f5f5f5;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      margin-top: 0.5rem;
    }

    & .commission{
      color: #f5f5f5;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      margin-top: 0.5rem;
    }
  }
}