@import "../../theme/scss/resources";

.container{
  padding: 8.5rem 0;
  background: #0d0a07 url("../../theme/assets/other/honee_main_about_bg.png") no-repeat;
  background-size: cover;

  @include media(lg) {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  @include media(fullHD) {
    background-size: 50% 100%;
    background-position: right;
  }

  & .contentWrap{
    & .title2{
      text-align: center;
      max-width: 63rem;
      margin: 0 auto;
      margin-bottom: 6.5rem;

      & span{
        color: #FF8C00;
      }
    }

    & .contentBox{
      @include media(lg) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-gap: 5rem;
      }

      & .imgWrap{
        display: grid;
        justify-content: center;

        & img{
          width: 100%;
          max-width: 51rem;
        }
      }

      & .aboutWrap{
        display: grid;
        grid-gap: 1.5rem;
        margin-top: 3rem;

        & .aboutItem{
          border: 1px solid #FF8C00;
          border-radius: 1rem;
          padding: 2.5rem;

          & .imgWrap{
            display: grid;
            justify-content: start;

            & img{
              width: initial;
            }
          }

          & .aboutItemContent{
            color: #F5F5F5;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.4rem;

            margin-top: 1.5rem;

            @include media(xl) {
              max-width: 70rem;
            }
          }
        }
      }
    }
  }
}