@import "../../theme/scss/resources";

.container{
  background: #213034;
  border-radius: 1rem;
  border: 1px solid #8C9497;
  height: auto;
  overflow: hidden;
  grid-column: 1 / 3;

  //@include media(md) {
  //  height: 33rem;
  //}

  @include media(xl) {
    display: grid;
    grid-column: auto;
    order: 3;
    grid-row: 3 / 5;
  }

  & .contentWrap{
    padding: 3.5rem;

    @include media(fullHD) {
      padding: 3.5rem 6.5rem;
    }

    & .titleRow{
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 2rem;

      & .title{
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #F5F5F5;
      }

      & .settingsBtn{
        display: none;
        cursor: pointer;

        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2.2rem;
        text-decoration-line: underline;

        color: #F7931A;

        @include media(xs) {
          font-size: 1.6rem;
        }
      }
    }

    & .mainTaskWrap{
      & .inputTitle{
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.8rem;

        color: #F5F5F5;
        margin-bottom: 0.5rem;
      }
      & .ticketblock {
        text-align: center;
      }
      & .subtitle {
        margin-top: 2rem;
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.5rem;
        color: #FD00D5;
      }
      & .ticket_img {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      & .description p {
        color: #F5F5F5;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        margin-top: 20px;
        & span {
          color: #FD00D5;
        }
      }

      & button {
        cursor: pointer;


        padding: 1rem 4rem;
        height: 4.8rem;

        background: #FD00D5;
        border: 1px solid transparent;
        border-radius: 1rem;


        color: #F5F5F5!important;
      }
    }
  }
}