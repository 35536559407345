@import "../../theme/scss/resources";

.container{
  //@include pagePadding;
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;

  @include media(lg) {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
    padding-right: 0 !important;
  }
  & .contentWrap{
    & .title1{
      font-weight: 700;
      margin-bottom: 2.4rem;

      @include media(lg) {
        font-size: 3rem;
        line-height: 4rem;
      }
    }

    & .imgWrap{
      display: grid;
      border-radius: 1rem;
      overflow: hidden;
      border: 1px solid #FF8C00;

      margin-bottom: 3.5rem;

      & img{
        width: 100%;
      }
    }

    & .dateWrap{
      @include dateWrap;
      margin-bottom: 3.2rem;
    }

    & .content{
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #FFFFFF;

      & p{
        margin-bottom: 2.5rem;
        &:last-child{
          margin-bottom: 0;
        }
      }

      & h1{
        font-style: normal;
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 3.6rem;

        color: #FFFFFF;
        margin-bottom: 3rem;
      }

      & ol, & ul{
        margin-left: 5rem;
      }

      & h2{
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3rem;

        color: #FFFFFF;
        margin-bottom: 2rem;
      }

      & a{
        color: #F7931A;
        text-decoration: none;
        cursor: pointer;
      }

      & img{
        border-radius: 1rem;
        overflow: hidden;
        border: 1px solid #FF8C00;
        display: grid;
        margin: 0 auto;
      }
    }
  }
}