@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    display: grid;
    grid-column: 1 / 3;
    //order: 3;
  }

  & .honeeCardBody{
    overflow: hidden;
    height: 100%;

    @include media(xl) {
      height: 36rem;
    }


    & .cardBodyWithScroll{
      & .titleWSF{
        margin-bottom: 2rem;
      }

      & .titleRow{
        display: grid;
        grid-template-columns: 1fr 5rem 5rem;
        justify-content: space-between;

        margin-bottom: 2rem;
        padding-right: 2rem;

        @include media(xs) {
          grid-template-columns: 1fr 6rem 6rem;
        }

        @include media(sm) {
          grid-template-columns: 1fr 12rem 12rem;
        }

        @include media(md) {
          grid-template-columns: 1fr 15rem 15rem;
        }


        @include media(fullHD) {
          padding-right: 6rem;
        }

        & .title6{
          margin-bottom: 0;

          &:nth-child(2){
            text-align: right;

            @include media(sm) {
              text-align: right;
            }
          }

          &:last-child{
            text-align: right;
          }
        }
      }

      & .contentBox{
        display: grid;
        grid-gap: 1.5rem;

        overflow-y: scroll;
        height: 21.1rem;
        scroll-padding-bottom: 0;
        grid-auto-rows: max-content;

        &::-webkit-scrollbar-track {border-radius: 0.6rem; background: transparent; border: 1px solid #915207}
        &::-webkit-scrollbar {width: 0.5rem;}
        &::-webkit-scrollbar-thumb {border-radius: 4px; background: #F7931A;}
        //&:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}

        @include media(sm) {
          height: 21.1rem;
        }

        & .contentBoxItem{
          display: grid;
          grid-template-columns: 1fr 5rem 5rem;
          align-items: center;
          justify-content: space-between;
          height: max-content;

          padding-right: 1.6rem;

          @include media(xs) {
            grid-template-columns: 1fr 6rem 6rem;
          }

          @include media(sm) {
            grid-template-columns: 1fr 12rem 12rem;
          }

          @include media(md) {
            grid-template-columns: 1fr 15rem 15rem;
          }

          @include media(fullHD) {
            padding-right: 5.6rem;
          }

          //&:hover{
          //  & .contentBoxItemShortName, & .contentBoxItemValues{
          //    color: #F7931A !important;
          //  }
          //}

          & .contentBoxItemAmount{
            text-align: right;

            @include media(sm) {
              text-align: right;
            }
          }

          & .contentBoxItemTotal{
            cursor: pointer;
            color: #F7931A !important;

            text-align: center;
            font-size: 1.6rem;
            line-height: 1.6rem;
            font-weight: 400;

            width: 6rem;
            height: 3.2rem;

            border: 1px solid #F7931A;
            display: grid;
            align-items: center;
            justify-items: center;
            justify-self: end;
            border-radius: 1rem;
            background: rgba(247, 147, 26, 0.10);

            @include media(sm) {
              width: 10.3rem;
            }
          }

          & .contentBoxItemNameWrap{
            cursor: pointer;
            display: grid;
            grid-template-columns: repeat(2, max-content);
            align-items: center;
            grid-gap: 1rem;
            width: max-content;

            @include media(sm) {
              grid-gap: 1.5rem;
            }

            &:hover{
              & .contentBoxItemShortName{
                    color: #F7931A !important;
              }
            }

            & .contentBoxItemNameImgWrap{
              display: grid;
            }

            & .contentBoxItemNameBox{
              & .contentBoxItemFullName{
                font-style: normal;
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1rem;

                color: #F5F5F5;

                & .rateBorderedRed{
                  border-radius: 0.5rem;
                  border: 1px solid #E36139;
                  padding: 0.25rem;
                  font-size: 1rem;
                  position: relative;
                  top: -0.1rem;
                }

                & .rateBorderedGreen{
                  border-radius: 0.5rem;
                  border: 1px solid #00FF7A;
                  padding: 0.25rem;
                  font-size: 1rem;
                  position: relative;
                  top: -0.1rem;
                }
              }

              & .contentBoxItemShortName{
                font-style: normal;
                font-weight: 500;
                font-size: 1.15rem;
                line-height: 2rem;

                color: #F5F5F5;

                @include media(sm) {
                  font-size: 1.5rem;
                }
              }
            }
          }

          & .contentBoxItemValues{
            font-style: normal;
            font-weight: 500;
            font-size: 1.15rem;
            line-height: 2rem;

            color: #F5F5F5;

            @include media(sm) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}