@import "../../theme/scss/resources";

.container{

  & .contentWrap{
    & .title{
      position: relative;

      font-style: normal;
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 4rem;

      color: #FF8C00;

      margin-bottom: 1.75rem;

      text-align: center;

      @include media(md) {
        text-align: left;

        font-size: 3.8rem;
        line-height: 5.2rem;

        margin-bottom: 3rem;
      }

      & .absBackBtn{
        cursor: pointer;

        position: absolute;
        left: 0;
        top: -1rem;

        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 0.5rem;

        @include media(md) {
          top: -2rem;
        }

        & img{
          display: grid;
        }

        & span{
          font-style: normal;
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 1.5rem;

          color: #F5F5F5;

          @include media(md) {
            font-size: 1.8rem;
            line-height: 2.5rem;
          }
        }
      }
    }

    & .note{
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;

      color: #F5F5F5;
      max-width: 93rem;

      @include media(md) {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }

      & span{
        color: #FF8C00;
      }
    }

    & .listTitle{
      font-style: normal;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 2.5rem;

      color: #FF8C00;
      margin-bottom: 1rem;
      margin-top: 1.5rem;
      text-align: center;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;

        margin-bottom: 1.5rem;
        margin-top: 3rem;
        text-align: left;
      }
    }

    & .listWrap{
      & .listItem{
        position: relative;

        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2rem;

        color: #F5F5F5;
        max-width: 93rem;
        padding-left: 2.5rem;

        &:before{
          content: '\2022';
          position: absolute;
          left: 0.5rem;
          top: -0.1rem;
        }

        @include media(md) {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
      }
    }

    & .checkRow{
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;
      grid-gap: 1.5rem;

      margin-top: 1rem;
      margin-bottom: 2.25rem;

      @include media(md) {
        margin-top: 1.5rem;
        margin-bottom: 4.5rem;
      }

      & .checkBox{
        cursor: pointer;

        display: grid;
        align-items: center;
        justify-items: center;
        width: 2.5rem;
        height: 2.5rem;

        border: 1px solid #F5F5F5;
        border-radius: 0.5rem;

        & img{
          display: grid;
        }
      }

      & .checkRowContent{
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2rem;

        color: #F5F5F5;

        @include media(md) {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
      }
    }

    & .phrase{
      display: grid;
      align-items: center;

      width: 30rem;
      height: 11rem;

      background: #071A21;
      border: 1px solid #FF8C00;
      border-radius: 1rem;

      padding: 1.5rem;

      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 2rem;

      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      text-align: center;

      color: #FFFFFF;

      @include media(md) {
        margin: initial;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    }

    & .btnWrap{
      display: grid;
      grid-gap: 3rem;

      padding-bottom: 3.5rem;

      @include media(md) {
        grid-template-columns: 30rem 30rem;
      }

      @include media(lg) {
        grid-gap: 6rem;
      }

      //& :first-child{
      //  background: transparent !important;
      //  border: 1px solid #58C1EF !important;
      //  color: #58C1EF !important;
      //}

      & .btn{
        cursor: pointer;

        background: #FF8C00;
        border: 1px solid transparent;
        border-radius: 1rem;

        text-align: center;

        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #F5F5F5;

        width: 100%;
        max-width: 30rem;
        margin: 0 auto;

        padding: 1.25rem 0;

        @include media(md) {
          margin: initial;
        }

        &.disable{
          color: #6E7679 !important;
          border: 1px solid #6E7679 !important;
          background: transparent !important;
        }

        &.copy{
          cursor: initial !important;
          color: #FF8C00 !important;
          border: 1px solid #FF8C00 !important;
          background: transparent !important;
        }
      }
    }
  }
}