@import "../../../theme/scss/resources";

.container{
  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #F5F5F5;
      margin-bottom: 1.5rem;
    }

    & .subTitle{
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.9rem;

      color: #F5F5F5;
      margin-bottom: 1rem;
    }

    & .inputWrap{
      & input{
        padding: 2rem 3rem;
        width: 100%;

        background: #071A21;
        border: 1px solid #58C1EF;
        border-radius: 1rem;

        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.9rem;
        text-align: center;

        color: #FFFFFF;
      }
    }

    & .btn{
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #6E7679;


      padding: 1.3rem 0;
      text-align: center;

      background: #1C343E;
      border: 1px solid #6E7679;
      border-radius: 1rem;

      max-width: 22.7rem;
      margin:  0 auto;
      margin-top: 3.5rem;

      &.btnActive{
        cursor: pointer;

        background: #1C343E;
        border: 1px solid #58C1EF;
        color: #58C1EF;
      }
    }
  }
}