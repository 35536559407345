@import "../../theme/scss/resources";

.container{
  position: absolute;
  left: -37rem;
  top: 7.9rem;

  height: 100vh;
  z-index: 1000;

  transition: all 0.5s ease-out;
  //display: none;

  @include media(lg) {
    top: 0 !important;
    left: 0 !important;

    height: 100%;
  }


  &.containerActive{
    left: 0;
  }

  & .contentWrap{
    background: linear-gradient(138deg, rgba(201, 110, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%) !important;
    backdrop-filter: blur(27px) !important;
    padding: 3rem 3rem 7rem 3rem;
    max-width: 37rem;
    border-right: 1px solid #915207;
    border-bottom: 1px solid #915207;

    @include media(lg) {
      padding: 6rem 3rem;
      height: 100%;
      border-bottom: none;
    }

    @include media(fullHD) {
      padding: 6rem 4rem;
      max-width: 37rem;
    }

    & .logoWrap{
      cursor: pointer;
      margin-bottom: 3rem;
      display: none;

      @include media(lg) {
        display: block;
      }
    }

    & .navWrap{
      display: grid;
      grid-gap: 1.5rem;

      & .navItem{
        cursor: pointer;

        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;

        color: #8C9497;

        &:hover{
          color: #F7931A !important;
        }

        &.navItemActive{
          color: #F7931A !important;
        }
      }

      & .navBoxWrap{
        & .navBox{
          border-top: 1px solid #F5F5F5;

          padding: 1rem 0;

          &:last-child{
            border-bottom: 1px solid #F5F5F5;
          }

          & .navBoxTitle{
            cursor: pointer;

            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: #F5F5F5;
            margin-bottom: 1rem;

            &:hover{
              color: #F7931A !important;
            }

            &.navItemActive{
              color: #F7931A !important;
            }
          }

          & .navBoxContent{
            display: grid;
            grid-gap: 0.5rem;

            & .navBoxContentItem{
              cursor: pointer;

              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.2rem;

              color: #8C9497;

              &:hover{
                color: #F7931A !important;
              }

              &.navItemActive{
                color: #F7931A !important;
              }
            }
          }
        }
      }
    }

    & .otherNavWrap{
      display: grid;
      grid-gap: 3.8rem;

      padding: 4.5rem 0;
      margin: 4.5rem 0;
      border-top: 1px solid #F5F5F5;
      border-bottom: 1px solid #F5F5F5;

      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.2rem;

      color: #8C9497;

      & .navItem{
        cursor: pointer;

        &:hover{
          color: #F7931A !important;
        }
      }
    }

    & .socialsWrap{
      margin-top: 3rem;

      & .socialsTitle{
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;

        color: #F5F5F5;
        margin-bottom: 1.5rem;
      }

      & .socialsBox{
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-gap: 1rem;

        & .socItem{
          cursor: pointer;
          display: grid;
        }
      }
    }

    & .logOutBtn{
      cursor: pointer;

      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      grid-gap: 1rem;

      margin-top: 4.5rem;

      @include media(fullHD) {
        grid-gap: 2rem;
      }

      & img{
        display: grid;
      }

      & span{
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;

        color: #F5F5F5;
      }
    }
  }
}