@import "../../theme/scss/resources";

.container{
  //border-radius: initial !important;
  //border: none !important;
  //background: transparent !important;
  //backdrop-filter: initial !important;

  & .honeeCardBody{
    //padding: 0 !important;
    & .lessonWrap{
      & .lessonTitleWrap{
        display: grid;
        grid-gap: 1.5rem;

        margin-bottom: 2.5rem;

        & .backBtn{
          display: grid;
          grid-template-columns: repeat(2, max-content);
          align-items: center;
          grid-gap: 1rem;

          cursor: pointer;
          width: max-content;

          & .backIcon{
            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            width: 3.6rem;
            height: 3.6rem;

            border-radius: 1rem;
            border: 1px solid #FF8C00;

            background: rgba(255, 140, 0, 0.10);
          }

          & .backText{
            font-size: 1.6rem;
          }
        }

        & .lessonTitle{
          font-size: 2.4rem;

          & span{
            font-size: 2.4rem;
            color: #FFD600;
          }
        }
      }

      & .lessonContentWrap {
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #FFFFFF;

        & img{
          max-width: 100% !important;
          width: 100% !important;
        }

        & p {
          margin-bottom: 2.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        & h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 3.2rem;
          line-height: 3.6rem;

          color: #FFFFFF;
          margin-bottom: 3rem;
        }

        & ol, & ul {
          margin-left: 5rem;
        }

        & h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 2.5rem;
          line-height: 3rem;

          color: #FFFFFF;
          margin-bottom: 2rem;
        }

        & a {
          color: #F7931A;
          text-decoration: none;
          cursor: pointer;
        }

        & img {
          border-radius: 1rem;
          overflow: hidden;
          border: 1px solid #FF8C00;
          display: grid;
          margin: 0 auto;
          height: auto;
        }
      }

      & .lessonTestWrap{
        padding-top: 2.5rem;
        margin-top: 2.5rem;
        border-top: 1px solid #FFD600;

        & .lessonTestTitle{
          color: #FFD600;

          text-align: center;
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        & .lessonTestNote{
          color: #FFFFFF;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          text-align: center;
        }

        & .lessonTestBtn{
          margin-top: 1.5rem;
          background: #FFD600;
          color: #15100A;
        }
      }
    }
  }
}

.block-header{
  color: #FFFFFF;

  text-align: center;
  font-family: 'Phage Regular KG', sans-serif;
  text-transform: uppercase;
  font-size: 2.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}