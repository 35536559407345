@import "../../theme/scss/resources";

.container{
  & .contentWrap{
    display: grid;
    grid-gap: 3rem;

    & .titleWSF{
      margin-bottom: 1.5rem !important;
    }

    & .contentWrapItem{
      &.contentWrapItemReposition{
        & .contentWrapItemBox{
          & .inputWrap{

            &:first-child{
              display: none;
              order: 1;
            }
            &:nth-child(2){
              display: none;
              order: 3;
            }

            &:nth-child(3){
              order: 2;
            }

            &:last-child{
              order: 4;
            }

            @include media(md) {
              &:first-child{
                order: 1;
              }
              &:nth-child(2){
                order: 2;
              }

              &:nth-child(3){
                order: 3;
              }

              &:last-child{
                order: 4;
              }
            }
          }
        }
      }

      & .contentWrapItemBox{
        display: grid;
        grid-gap: 2rem;

        @include media(md) {
          grid-template-columns: 1fr 1fr;
          row-gap: 2rem;
          column-gap: 7rem;

          width: 100%;
          max-width: 67rem;
        }

        & .inputWrap{
          &:nth-child(2){
            grid-column: 1;
          }
          &.inputWrapZero{
            display: none;

            @include media(md) {
              display: block;
              opacity: 0;
              -webkit-touch-callout: none !important; /* iOS Safari */
              -webkit-user-select: none !important;   /* Chrome/Safari/Opera */
              -khtml-user-select: none !important;    /* Konqueror */
              -moz-user-select: none !important;      /* Firefox */
              -ms-user-select: none !important;       /* Internet Explorer/Edge */
              user-select: none !important;
            }
          }
          &.inputWrapWithErr{
            position: relative;

            & input{
              border: 1px solid #CF0000 !important;
            }

            & .inputErr{
              position: absolute;
              right: 0;
              bottom: -1.5rem;

              color: #CF0000;
              font-style: normal;
              font-weight: 500;
              font-size: 1.25rem;
              line-height: 1.5rem;

              white-space: nowrap; /* Текст не переносится */
              overflow: hidden; /* Обрезаем всё за пределами блока */
              text-overflow: ellipsis;
            }
          }
          &.inputWrapWithCopyBtn{
            position: relative;
            cursor: pointer;

            & .fakeInputCopyBtn{
              display: grid;
              padding: 1rem;

              position: absolute;
              right: 0.3rem;
              top: 0.5rem;
            }
          }

          &.inputWrapWithSaveBtn{
            position: relative;

            & input{
              padding: 1.3rem 9.3rem 1.3rem 1.3rem !important;
            }

            & .inputSaveBtn{
              cursor: pointer;

              position: absolute;
              right: 0.3rem;
              bottom: 0.4rem;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              text-decoration-line: underline;
              padding: 1rem;

              color: #F7931A;
            }
          }

          & .inputTitle{
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.9rem;

            color: #F5F5F5;
            margin-bottom: 0.8rem;
          }

          & .fakeInput{
            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            border-radius: 0.5rem;
            height: 4.8rem;

            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: #F5F5F5;
            padding: 1.3rem;
          }

          & input{
            background: rgba(15, 32, 52, 0.1);
            border: 1px solid #FF8C00;
            backdrop-filter: blur(7px);
            border-radius: 0.5rem;
            height: 4.8rem;
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: #F5F5F5;
            padding: 1.3rem;

            &::-webkit-input-placeholder { color: #8C9497; transform: translateY(0.35rem); }
            &::-moz-placeholder { color: #8C9497; transform: translateY(0.35rem); }
            &:-ms-input-placeholder { color: #8C9497; transform: translateY(0.35rem); }
            &:-moz-placeholder { color: #8C9497; transform: translateY(0.35rem); }
          }
        }
      }

      & .button{
        margin-top: 4rem;

        @include media(md) {
          max-width: 19rem;
        }
      }
    }
  }
}