@import "../../theme/scss/resources";

.container{
  & .contentWrap{
    display: grid;
    position: relative;
    //grid-gap: 3rem;

    padding: 4.35rem 3.5rem;

    @include media(fullHD) {
      padding: 4.35rem 6.5rem;
    }

    & .absBackBtn{
      cursor: pointer;

      position: absolute;
      left: 1rem;
      top: 1rem;

      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      grid-gap: 0.5rem;

      & img{
        display: grid;
      }

      & span{
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.5rem;

        color: #F5F5F5;

        @include media(md) {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
      }
    }

    & .iconWrap{
      margin: 0 auto;
      margin-bottom: 1rem;
    }

    & .sum{
      color: #F5F5F5;
      text-align: center;
      font-size: 2.4rem;
      line-height: 3rem;

      font-style: normal;
      font-weight: 400;
    }

    & .email{
      color: #F7931A;
      text-align: center;
      font-size: 1.8rem;
      line-height: 2rem;
      font-style: normal;
      font-weight: 400;
    }

    & .textWrap{
      height: 6.6rem;
      display: grid;
      align-items: center;

      color: #F5F5F5;
      text-align: center;
      font-size: 2rem;
      line-height: 2.5rem;
      font-style: normal;
      font-weight: 400;
    }

    & .btnWrap{
      display: grid;
      grid-template-columns: max-content max-content;
      justify-content: space-around;
      width: 20rem;
      margin: 0 auto;
      margin-top: 2.5rem;

      &.btnWrapOE{
        grid-template-columns: initial !important;
        & .btnWrapItemImgWrap{
          transform: initial !important;
          border: none !important;

          & img{
            width: 4.2rem !important;
            height: 4.2rem !important;
          }
        }

      }

      & .btnWrapItem{
        cursor: pointer;

        &:last-child{
          & .btnWrapItemImgWrap{
            transform: rotate(-180deg);
          }
        }

        & .btnWrapItemImgWrap{
          display: grid;
          justify-items: center;
          align-items: center;

          width: 4.2rem;
          height: 4.2rem;

          border-radius: 5rem;
          border: 1px solid #F5F5F5;

          margin: 0 auto;
        }

        & .btnWrapItemContent{
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.9rem;

          color: #F5F5F5;
          text-align: center;
          margin-top: 0.5rem;
        }
      }
    }
  }
}