@import "../../../theme/scss/resources";

.container{
  & .contentWrap{
    display: grid;
    grid-gap: 3rem;

    @include media(md) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 7.4rem;
    }

    & .contentWrapItem{
      cursor: pointer;

      background: rgba(88, 193, 239, 0.1);
      border: 1px solid #58C1EF;
      backdrop-filter: blur(7px);
      border-radius: 1.5rem;
      display: grid;
      grid-template-rows: max-content max-content;
      justify-content: center;
      align-content: center;
      height: 10rem;

      @include media(md) {
        height: 20rem;
      }

      & .contentWrapItemImgWrap{
        display: grid;
        justify-self: center;
        margin-bottom: 2rem;
      }

      & .contentWrapItemText{
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;

        color: #58C1EF;
      }
    }
  }
}