@import "../../theme/scss/resources";

.container{

  & .contentWrap{
    & .title{
      position: relative;

      font-style: normal;
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 4rem;

      color: #FF8C00;

      margin-bottom: 1.75rem;

      text-align: center;

      @include media(md) {
        text-align: left;

        font-size: 3.8rem;
        line-height: 5.2rem;

        margin-bottom: 3rem;
      }

      & .absBackBtn{
        cursor: pointer;

        position: absolute;
        left: 0;
        top: -1rem;

        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 0.5rem;

        @include media(md) {
          top: -2rem;
        }

        & img{
          display: grid;
        }

        & span{
          font-style: normal;
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 1.5rem;

          color: #F5F5F5;

          @include media(md) {
            font-size: 1.8rem;
            line-height: 2.5rem;
          }
        }
      }
    }

    & .note{
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;

      color: #F5F5F5;
      max-width: 76rem;

      @include media(md) {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }

      & span{
        color: #FF8C00;
      }
    }

    & .inputWrap{
      margin-top: 1.5rem;

      &.inputWrapWithErr{
        & input{
          border: 1px solid #E94435 !important;
          color: #E94435 !important;
        }
      }

      & input{
        background: #071A21;
        border: 1px solid #FF8C00;
        border-radius: 1rem;

        width: 100%;
        max-width: 76rem;

        padding: 2.5rem 1.5rem;

        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;

        color: #FFFFFF;

        @include media(md) {
          padding: 2.5rem;

          font-size: 1.4rem;
          line-height: 1.9rem;
        }
      }

      & .inputErr{
        margin-top: 0.6rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.75rem;

        color: #CF0000;
        height: 3.5rem;

        @include media(md) {
          margin-top: 1.2rem;

          font-size: 1.6rem;
          line-height: 2.2rem;
          height: 1.6rem;
        }
      }
    }

    & .btn{
      cursor: initial !important;

      display: grid;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.5rem;

      text-align: center;

      color: #6E7679 !important;

      max-width: 30rem;
      height: 4.8rem;

      border: 1px solid #6E7679 !important;
      background: transparent !important;
      border-radius: 1rem;

      margin: 1rem auto 0;

      @include media(md) {
        margin: initial;
        margin-top: 3rem;
      }

      &.btnActive{
        cursor: pointer !important;
        background: #FF8C00 !important;
        border: 1px solid #FF8C00 !important;
        color: #F5F5F5 !important;
      }
    }
  }
}