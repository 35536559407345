@import "../../theme/scss/resources";

.container{
  & .honeeCardBody {
    display: grid;
    grid-template-rows: 1fr max-content max-content;
    height: 100%;

    & .titleWSF{
      margin-bottom: 2rem;
    }

    & .totalRow{
      display: grid;
      grid-gap: 1.5rem;

      @include media(xs) {
        grid-template-columns: max-content;
      }

      @include media(md) {
        grid-template-columns: max-content max-content max-content;
        justify-content: space-between;
      }

      & .totalRowItem{
        &.hide{
          opacity: 0;
        }

        & .title4{
          margin-bottom: 0.5rem;

          @include media(xl) {
            font-size: 1.25rem;
          }

          @include media(fullHD) {
            font-size: 1.6rem;
          }
        }

        & .totalRowItemValue{
          font-size: 2.6rem;
          line-height: 4rem;
          font-style: normal;
          font-weight: 400;

          @include media(xl) {
            font-size: 2rem;
          }

          @include media(fullHD) {
            font-size: 2.6rem;
          }
        }
      }
    }

    & .inputRow{
      display: grid;
      grid-gap: 1rem;

      margin-top: 1.5rem;

      @include media(sm) {
        grid-template-columns: 1fr 18.5rem;
        grid-gap: 3.5rem;
      }

      & .inputWrap{
        & .inputTitle{
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;

          margin-bottom: 0.75rem;
        }

        & input{
          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #8C9497;
          backdrop-filter: blur(7px);

          border-radius: 1rem;

          height: 4.8rem;
          width: 100%;

          font-size: 14px;
          line-height: 19px;

          color: #F5F5F5;
          padding: 0 1.5rem;
        }
      }

      & .button{
        align-self: end;

        @include media(sm) {
          max-width: 18.5rem;
        }
      }
    }
  }
}