@import "../../theme/scss/resources";

.container{
  position: relative;

  @include media(xl) {
    display: grid;
    grid-row: 3;
    grid-column: 2;
  }

  & .blurAbs{
    display: grid;
    justify-items: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    & .blurMessage{
      font-size: 4rem;
      line-height: 5rem;
      text-align: center;

      color: #F5F5F5;
    }
  }

  & .honeeCardBody{
    display: grid;

    border: 1px solid #FFD600;
    border-radius: 1rem;

    &.honeeCardBodyWB{
      filter: blur(8px) !important;
      pointer-events: none !important;
    }

    & .contentWrap{
      display: grid;
      grid-template-rows: max-content max-content 1fr;
      & .titleRow{
        display: grid;
        grid-gap: 1rem;

        & .betaType{
          position: relative;
          top: -0.25rem;
          border-radius: 0.5rem;
          border: 1px solid #FF8C00;
          background: rgba(255, 140, 0, 0.10);

          color: #FFFFFF;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          padding: 0.5rem 0.85rem;
        }

        @include media(md) {
          grid-template-columns: max-content 1fr;
          align-items: center;
          grid-gap: 3rem;
        }

        @include media(xl) {
          grid-template-columns: initial;
          align-items: center;
          grid-gap: 1rem;
        }

        @include media(fullHD) {
          grid-template-columns: max-content 1fr;
          align-items: center;
          grid-gap: 3rem;
        }

        & .title4{
          margin-bottom: 0;
          font-size: 1.3rem;

          @include media(sm) {
            font-size: 1.6rem;
          }
        }

        & .balanceWrap{
          display: grid;

          grid-template-columns: max-content max-content;
          align-items: center;
          grid-gap: 2rem;

          @include media(md) {
            justify-self: end;
          }

          @include media(xl) {
            justify-self: initial;
            grid-auto-flow: column;
            justify-content: space-between;
          }

          @include media(fullHD) {
            justify-self: end;
          }

          //@include media(xs) {
          //  grid-template-columns: max-content max-content;
          //  align-items: center;
          //  grid-gap: 2rem;
          //}

          & .balanceWrapItem{
            display: grid;
            grid-gap: 1rem;

            @include media(md) {
              grid-template-columns: max-content max-content;
              align-items: center;
            }

            &:last-child{
              display: grid;


              & .balanceWrapItemValue{
                color: #00FF7A;
                border: 1px solid #00FF7A;
                background: rgba(0, 255, 122, 0.05);
              }
            }

            & .balanceWrapItemName{
              color: #FFFFFF;

              font-size: 1.3rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.28px;

              @include media(sm) {
                font-size: 1.4rem;
              }
            }

            & .balanceWrapItemValue{
              display: grid;
              align-items: center;
              text-align: center;

              height: 3rem;
              width: 9.5rem;

              border-radius: 0.8rem;
              border: 1px solid #F7931A;

              background: rgba(247, 147, 26, 0.05);

              color: #F7931A;

              font-size: 1.4rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.28px;
            }
          }
        }
      }

      & .textWrap{
        display: grid;
        grid-gap: 1.5rem;
        margin-top: 2rem;

        @include media(md) {
          grid-gap: 3rem;
          margin-top: 4rem;
        }
        & .textP{
          color: #FFFFFF;
          font-family: "JetBrains Mono";
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          & span{
            color: #FFD600;
          }
        }
      }

      & .swapWrap{
        align-self: end;
        margin-top: 1rem;

        & .inputWrap{
          & .inputTitle{
            color: #F5F5F5;

            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin-bottom: 0.5rem;
          }

          & .inputBox{
            position: relative;

            &.inputBoxOmt{
              & input{
                border: 1px solid #FFD600 !important;
                background: rgba(255, 214, 0, 0.05) !important;
                color: #FFC200 !important;
              }
            }

            & input{
              border-radius: 1rem;
              border: 1px solid #915207;

              padding: 1.75rem 9rem 1.75rem 6rem;

              background: #241604;
              height: 6.2rem;
              width: 100%;

              color: #FFFFFF;

              font-size: 1.8rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              -moz-appearance: textfield;

              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }

            & .balance{
              color: #f5f5f5;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;

              margin-top: 0.5rem;
            }

            & .absCur{
              position: absolute;
              top: 1.75rem;
              right: 2rem;

              color: #F5F5F5;

              text-align: right;
              font-size: 1.8rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            & .absIcon{
              display: grid;

              position: absolute;
              left: 2rem;
              top: 1.5rem;
            }
          }
        }

        & .changeBtn{
          width: 4rem;
          height: 4rem;

          display: grid;
          cursor: pointer;

          margin: 2rem auto 0;

          @include media(xl) {
            margin: 1.25rem auto 0;
          }

          @include media(fullHD) {
            margin: 2rem auto 0;
          }
        }

        & .rateRow{
          display: grid;
          grid-auto-flow: column;
          justify-content: space-between;
          align-items: center;

          margin: 2rem 0;

          @include media(xl) {
            margin: 1rem 0;
          }

          @include media(fullHD) {
            margin: 2rem 0;
          }

          & .rateRowName{
            color: #F5F5F5;

            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & .rateRowValue{
            color: #F5F5F5;

            text-align: right;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            & span{
              color: #FFC200;
            }
          }
        }

        & .btn{

        }
      }
    }
  }
}