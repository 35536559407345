@import "../../theme/scss/resources";

.container{
  & .contentWrap{
    & .cardBody{
      display: grid;
      grid-gap: 3rem;

      & .contentWrapTitle{
        margin-bottom: 1.5rem;
      }

      & .contentWrapNote{
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2rem;

        color: #F5F5F5;

        @include media(md) {
          font-size: 2.2rem;
          line-height: 3rem;
        }

        & span{
          cursor: pointer;

          color: #F7931A;
          text-decoration: underline;
        }
      }

      & .button{
        @include media(md) {
          max-width: 20rem;
        }
      }
    }
  }
}