@import "../../theme/scss/resources";

.container{
  border-radius: initial !important;
  border: none !important;
  background: transparent !important;
  backdrop-filter: initial !important;

  & .honeeCardBody{
    padding: 0 !important;


    & .lessonsWrap{
      display: grid;
      grid-gap: 3rem;

      & .lessonsItem{
        display: grid;
        align-items: center;
        justify-items: center;
        text-align: center;

        border: 1px solid #00FF7A;
        border-radius: 1rem;
        height: 32rem;

        background-size: cover;
        position: relative;

        &.lessonsItemActive{
          cursor: pointer !important;
          border: 1px solid #FFD600 !important;

          & .lessonsItemContent{
            & .lessonsItemContentNumber{
              color: #FFD600 !important;
            }
          }

          & .lessonsAbsInfo{
            & .lessonsAbsInfoStatusWrap{
              & .lessonsAbsInfoStatusWrapValue{
                border: 1px solid #FFD600;
                background: rgba(255, 214, 0, 0.30);
              }
            }

            & .lessonsAbsInfoPointWrap{
              & .lessonsAbsInfoPointWrapValue{
                border: 1px solid #FFD600;
                background: rgba(255, 214, 0, 0.30);
              }
            }
          }

          & .lessonsAbsShade{
            display: none !important;
          }
        }

        &.lessonsItemInActive {
          border: 1px solid #9A9A9A !important;

          & .lessonsItemContent {
            & .lessonsItemContentNumber {
              color: #9A9A9A !important;
            }
          }

          & .lessonsAbsInfo {
            & .lessonsAbsInfoStatusWrap {
              & .lessonsAbsInfoStatusWrapValue {
                border: 1px solid #9A9A9A;
                background: #9A9A9A;
              }
            }

            & .lessonsAbsInfoPointWrap {
              & .lessonsAbsInfoPointWrapValue {
                border: 1px solid #9A9A9A;
                background: #9A9A9A;
              }
            }
          }
        }

        &.lessonsItemCompleted{
          cursor: pointer !important;

          & .lessonsAbsShade{
            display: none !important;
          }
        }

        & .lessonsItemContent{
          & .lessonsItemContentNumber{
            color: #00FF7A;
            font-size: 3rem;

            @include media(md) {
              font-size: 4.4rem;
            }
          }

          & .lessonsItemContentTitle{
            color: #F5F5F5;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @include media(md) {
              font-size: 3.4rem;
            }
          }
        }

        & .lessonsAbsInfo{
          position: absolute;
          left: 3rem;
          bottom: 3rem;

          display: grid;
          grid-template-columns: repeat(2, max-content);
          align-items: center;
          grid-gap: 1rem;

          z-index: 9999;

          @include media(md) {
            grid-template-columns: repeat(2, max-content);
            grid-gap: 3rem;
          }

          & .lessonsAbsInfoStatusWrap{
            display: grid;
            grid-template-columns: repeat(2, max-content);
            align-items: center;
            grid-gap: 0.25rem;

            @include media(md) {
              grid-gap: 1.25rem;
            }

            & .lessonsAbsInfoStatusWrapName{
              color: #FFFFFF;

              font-size: 1.3rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;

              @include media(md) {
                font-size: 1.6rem;
              }
            }

            & .lessonsAbsInfoStatusWrapValue{
              display: grid;
              align-items: center;
              justify-items: center;

              border-radius: 1rem;
              border: 1px solid #00FF7A;

              background: rgba(0, 255, 122, 0.30);

              color: #FFFFFF;

              text-align: center;
              font-size: 1.3rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              height: 4.1rem;
              width: max-content;

              padding: 0 1rem;

              @include media(md) {
                font-size: 1.6rem;
              }
            }
          }

          & .lessonsAbsInfoPointWrap{
            display: grid;
            grid-template-columns: repeat(2, max-content);
            align-items: center;
            grid-gap: 1.25rem;

            & .lessonsAbsInfoPointWrapName{
              color: #FFFFFF;

              font-size: 1.3rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;

              @include media(md) {
                font-size: 1.6rem;
              }
            }

            & .lessonsAbsInfoPointWrapValue{
              display: grid;
              align-items: center;
              justify-items: center;

              border-radius: 1rem;
              border: 1px solid #00FF7A;

              background: rgba(0, 255, 122, 0.30);

              color: #FFFFFF;

              text-align: center;
              font-size: 1.3rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              height: 4.1rem;
              width: max-content;

              padding: 0 1rem;

              @include media(md) {
                font-size: 1.6rem;
              }
            }
          }
        }

        & .lessonsAbsShade{
          position: absolute;
          width: 100%;
          height: 100%;
          background: #000000;
          opacity: 0.6;
          border-radius: 1rem;

          z-index: 999;
        }
      }
    }
  }
}