@import "../../../theme/scss/resources";

.container{
  height: inherit;
  & .contentWrap{
    display: grid;
    align-items: center;
    align-content: center;
    justify-items: center;
    grid-gap: 1rem;

    height: 100%;

    & .contentItemIconWrap{
      display: grid;
    }

    & .contentItemText{
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #8C9497;
    }

    & .contentItemBtn{
      cursor: pointer;

      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.2rem;

      color: #F5F5F5;
      background: #F7931A;
      border: 1px solid #F7931A;
      border-radius: 1rem;

      text-align: center;
      padding: 1.2rem 0;

      width: 22.7rem;
    }
  }
}