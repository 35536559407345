@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    display: grid;
    height: auto;
    overflow: initial;

    @include media(md) {
      height: 45rem;
    }

    @include media(xl) {
      display: grid;
      order: 3;
      height: 40rem;
    }
    & .titleWSF{
      margin-bottom: 0 !important;

      @include media(md) {
        margin-bottom: 2rem !important;
      }

      @include media(xl) {
        margin-bottom: 0 !important;
      }

      @include media(fullHD) {
        margin-bottom: 2rem !important;
      }
    }

    & .contentBox{
      display: grid;
      grid-gap: 2rem;

      @include media(md) {
        grid-template-columns: 1fr 5rem 1fr;
        grid-gap: 3rem;
      }

      & .contentBoxExchangeBtnWrap{
        display: grid;
        align-items: center;
        justify-items: center;

        & .contentBoxExchangeBtn{
          cursor: pointer;
          display: grid;
          justify-items: center;
          align-items: center;

          width: 5rem;
          height: 5rem;
          border: 1px solid #F7931A;
          border-radius: 5rem;

          & img{
            @include media(md) {
              transform: rotate(90deg);
            }
          }
        }
      }

      & .contentBoxColumn{

        & .contentBoxColumnTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.5rem;

          color: #F5F5F5;
          margin-bottom: 1rem;



          @include media(md) {
            height: 5rem;
            display: grid;
            align-content: end;
          }

          @include media(fullHD) {
            height: auto;
            display: grid;
            align-content: end;
          }
        }

        & .contentBoxColumnItem{
          & .inputCurseNote{
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #FFFF00;
            margin-top: 0.5rem;
          }
          & .inputNote{
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.6rem;

            color: #F5F5F5;
            margin-top: 0.5rem;

            & span{
              color: #F7931A;
            }
          }
          & .fakeInputBox{
            margin-top: 1rem;

            & .fakeInput{
              display: grid;
              align-items: center;

              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 1rem;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              width: 100%;

              color: #F5F5F5;
              padding: 1.4rem 2.4rem;
              height: 6.2rem;
            }
          }
          & .sumInputBox{
            margin-top: 1rem;

            & input{
              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 1rem;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              width: 100%;

              color: #F5F5F5;
              padding: 1.4rem 2.4rem;
              height: 6.2rem;
            }
          }
          & .listPosWrap{
            height: 8.2rem;

            & .listBoxEmpty{
              position: relative;

              display: grid;
              align-items: center;
              grid-gap: 2.25rem;

              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 1rem;

              padding: 1.4rem 2.4rem;

              height: 6.2rem;

              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F5F5F5;
            }

            & .listBox{
              cursor: pointer;
              position: relative;

              display: grid;
              grid-gap: 2.25rem;

              background: rgba(15, 32, 52, 0.1);
              border: 1px solid #FF8C00;
              backdrop-filter: blur(7px);
              border-radius: 1rem;

              padding: 1.4rem 2.4rem;

              height: 6.2rem;
              //overflow-y: scroll;
              //


              & .listBtn{
                cursor: pointer;

                position: absolute;
                right: 2.4rem;
                top: 2.25rem;

                width: 2rem;
                height: 2rem;

                display: grid;
                justify-items: center;
                align-items: center;

                transition: all 0.5s ease-out;

                &.listBtnActive{
                  transform: rotate(180deg);
                }
              }

              & .listResult{
                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
                grid-gap: 0.75rem;

                & .listResultEmpty{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;
                }

                & .listResultImgWrap{
                  display: grid;
                }

                & .listResultText{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;
                }
              }
            }

            & .abslistWrap{
              //overflow-y: hidden;
              //max-height: 0;
              overflow: hidden;
              position: relative;
              z-index: 1000;
              max-height: 0;

              -ms-overflow-style: none;
              scrollbar-width: none;

              transition: all 0.5s ease-out;

              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }

              &.abslistWrapActive{
                max-height: 25.3rem;

                & .abslistBorderWrap{
                  max-height: 17.3rem;
                }
              }



              & .abslistBorderWrap{
                background: #24180d;
                border: 1px solid #FF8C00;
                //backdrop-filter: blur(7px);
                border-radius: 1rem;

                max-height: 0;
                margin-top: 1rem;
                overflow: scroll;

                -ms-overflow-style: none;
                scrollbar-width: none;

                transition: all 0.5s ease-out;

                &::-webkit-scrollbar {
                  width: 0;
                  height: 0;
                }

                & .abslistBox{
                  overflow-y: scroll;
                  position: relative;

                  display: grid;
                  grid-gap: 2.25rem;



                  padding: 1.4rem 2.4rem;

                  -ms-overflow-style: none;
                  scrollbar-width: none;

                  transition: all 0.5s ease-out;

                  &::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                  }

                  //height: 6.2rem;

                  & .listBoxItem{
                    cursor: pointer;

                    display: grid;
                    grid-template-columns: max-content 1fr;
                    align-items: center;
                    grid-gap: 0.75rem;

                    & .listBoxItemImgWrap{
                      display: grid;
                    }

                    & .listBoxItemText{
                      font-style: normal;
                      font-weight: 400;
                      font-size: 1.4rem;
                      line-height: 1.9rem;

                      color: #F5F5F5;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    & .button{
      margin-top: 1.2rem;

      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.5rem;
      text-align: center;
      //padding: 0.95rem 0;

      color: #8C9497;

      border: 1px solid #8C9497;
      border-radius: 1rem;
      background: initial;
      cursor: initial;

      @include media(md) {
        width: 19rem;
        margin-top: 0;
        justify-self: end;
      }

      &.buttonActive{
        cursor: pointer !important;

        background: #F7931A !important;
        color: #F5F5F5 !important;
        border: 1px solid #F7931A !important;
      }

      & .loaderWrap{
        display: none;
      }

      & .textWrap{
        display: block;
      }

      &.mainBtnLoading{
        background: transparent !important;

        & .loaderWrap{
          display: grid !important;
          justify-items: center;
          align-items: center;
        }

        & .textWrap{
          display: none !important;
        }
      }
    }
  }
}