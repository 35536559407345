@import "../../theme/scss/resources";

.container{
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;

  @include media(md) {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  & .contentWrap{
    & .title1{
      font-weight: 700;
      margin-bottom: 3rem;
      text-transform: uppercase;
    }

    & .update{
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 3.6rem;

      color: #F7931A;
      margin-bottom: 4rem;
    }

    & .contentBox{
      display: grid;
      grid-gap: 4rem;

      & .contentBoxItem{
        display: grid;
        grid-gap: 2.5rem;

        & .bolded{
          font-weight: 600;
        }

        & .link{
          cursor: pointer;
          color: #F7931A
        }

        & .paragraphBox{

          & .paragraph{
            font-style: normal;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.5rem;

            color: #FFFFFF;
          }

          & .paragraphList{
            display: grid;
            grid-gap: 0.5rem;
            margin-top: 1.5rem;

            & .paragraphListItem{
              position: relative;

              padding-left: 2rem;
              font-style: normal;
              font-weight: 400;
              font-size: 1.8rem;
              line-height: 2.5rem;

              color: #FFFFFF;

              &:before{
                position: absolute;
                left: 0;
                top: 0.1rem;

                content: '•';
                font-style: normal;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.5rem;

                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}