@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    display: grid;
    grid-row: 6;
    grid-column: 1 / 3;
  }

  & .honeeCardBody{
    display: grid;

    border: 1px solid #FFD600;
    border-radius: 1rem;

    & .contentWrap {
      display: grid;

      & .titleRow{
        display: grid;
        grid-gap: 2rem;


        @include media(md) {
          grid-auto-flow: column;
          align-content: center;
          justify-content: space-between;
        }

        & .titleBox{
          display: grid;
          grid-template-columns: max-content max-content;
          align-items: center;
          grid-gap: 1rem;
          order: 2;

          @include media(md) {
            order: initial;
          }

          & .titleWSF{
            margin-bottom: 0;
            font-size: 1.2rem;

            @include media(sm) {
              font-size: 1.8rem;
            }

            & span{
              color: #FFD600;
            }
          }

          & .cryptoBtn{


            cursor: pointer;
            border-radius: 0.8rem;
            border: 1px solid #F7931A;
            background: rgba(247, 147, 26, 0.05);

            padding: 0.5rem 1rem;
            width: 10rem;

            position: relative;

            & .cryptoBtnClick{
              display: grid;
              grid-template-columns: max-content max-content;
              justify-content: space-between;
              align-items: center;
              grid-gap: 0.5rem;

              & .cryptoBtnText{
                color: #F7931A;

                text-align: center;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.32px;
                text-transform: uppercase;
              }

              & .cryptoBtnImgWrap{
                display: grid;
              }
            }

            & .cryptoBtnDropdown{
              display: none;
              position: absolute;
              left: 0;
              top: 4rem;
              border: 1px solid #F7931A;
              border-radius: 0.8rem;
              width: 10rem;
              overflow: hidden;

              background: #213034;
              z-index: 1000;

              &.cryptoBtnDropdownActive{
                display: grid;
              }

              & .cryptoBtnDropdownItem{
                position: relative;
                z-index: 1000;
                cursor: pointer;
                color: #8C9497;

                text-align: center;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.32px;
                text-transform: uppercase;

                padding: 0.5rem 1rem;

                &.cryptoBtnDropdownItemActive{
                  color: #F7931A;
                  background: rgba(255, 194, 0, 0.05);
                }
              }
            }
          }
        }

        & .predictionBox{
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          grid-gap: 1.5rem;
          order: 1;

          @include media(md) {
            order: initial;
            grid-template-columns: max-content max-content;
          }

          & .predictionBoxItem{
            cursor: pointer;

            border-radius: 0.8rem;
            border: 1px solid #8C9497;
            background: rgba(140, 148, 151, 0.05);

            color: #8C9497;

            text-align: center;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;
            text-transform: uppercase;
            padding: 1rem;





            @include media(md) {
              font-size: 1.4rem;
              padding: 1rem 2rem;
              width: max-content;
            }

            &.predictionBoxItemActive{
              border: 1px solid #FFC200;
              background: rgba(255, 194, 0, 0.05);

              color: #FFC200;
            }
          }
        }
      }

      & .graphWrap{
        height: 26rem;
      }

      & .timeWrap{
        display: grid;
        align-items: center;
        grid-gap: 1rem;

        @include media(lg) {
          grid-template-columns: max-content max-content;
          grid-gap: 2rem;
        }

        & .timeTitle{
          color: #8C9497;

          font-size: 1.8rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        & .timeBox{
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          grid-gap: 1.5rem;

          @include media(md) {
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            grid-template-columns: initial;
            grid-gap: 1rem;
          }

          & .timeBoxItem{
            cursor: pointer;

            border-radius: 0.8rem;
            border: 1px solid #8C9497;
            background: rgba(140, 148, 151, 0.05);

            color: #8C9497;

            text-align: center;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;

            text-transform: uppercase;

            padding: 1rem 2rem;

            @include media(md) {
              width: 10rem;
            }

            &.timeBoxItemActive{
              border: 1px solid #FFC200;
              background: rgba(255, 194, 0, 0.05);

              color: #FFC200;
            }
          }
        }
      }
    }
  }
}