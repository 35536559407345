@import "../../theme/scss/resources";

.container{
  @include media(xl) {
    grid-column: 1 / 3;
  }

  & .contentWrap{
    & .titleWSF{
      & span{
        color: #FFD600;
      }
    }

    & .periodRow{
      display: grid;
      grid-gap: 1rem;

      margin-top: 2.5rem;
      padding-right: 2rem;

      @include media(sm) {
        grid-auto-flow: column;
        align-content: center;
        justify-content: space-between;
        padding-right: 3.5rem;
      }

      @include media(fullHD) {
        padding-right: 6.5rem;
      }

      & .periodBox{
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1rem;

        & .periodBoxName{
          color: #FFFFFF;

          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
        }

        & .periodBoxBtn{
          display: grid;
          grid-template-columns: max-content max-content;
          align-items: center;
          grid-gap: 0.5rem;

          cursor: pointer;
          position: relative;


          & .periodBoxBtnText{
            color: #FFD600;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.32px;
            text-transform: uppercase;
          }

          & .periodBoxBtnImgWrap{
            display: grid;
          }

          & .periodBoxBtnDropContent{
            display: none;


            position: absolute;
            left: 0;
            top: 4rem;
            border: 1px solid #FFD600;
            border-radius: 0.8rem;
            width: 10rem;
            overflow: hidden;

            background: #213034;
            z-index: 1000;

            &.periodBoxBtnDropContentActive{
              display: grid;
            }

            & .periodBoxBtnDropContentItem{
              position: relative;
              z-index: 1000;
              cursor: pointer;
              color: #8C9497;

              text-align: center;
              font-size: 1.6rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.32px;
              text-transform: uppercase;

              padding: 0.5rem 1rem;

              &.periodBoxBtnDropContentItemActive{
                color: #FFD600;
                background: rgba(255, 194, 0, 0.05);
              }
            }
          }
        }
      }

      & .priceBox{
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1rem;

        & .priceBoxName{
          color: #FFFFFF;

          text-align: right;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
        }

        & .priceBoxValue{
          border-radius: 0.8rem;
          border: 1px solid #FFC200;
          background: rgba(255, 194, 0, 0.05);

          color: #FFC200;

          text-align: center;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;

          padding: 0.5rem 1rem;

          width: max-content;
        }
      }
    }

    & .graphWrap{
      display: grid;
      align-self: end;

      @include media(sm) {
        padding-right: 0.5rem;
      }

      @include media(fullHD) {
        padding-right: 3.5rem;
      }
    }
  }
}