@import "../../theme/scss/resources";

.container{
  & .honeeCardBody{
    display: grid;
    grid-gap: 4rem;

    @include media(xl) {
      grid-template-columns: 23rem 1fr;
      column-gap: 8rem;
      row-gap: 2rem;
    }

    & .statisticWrap{
      display: grid;
      align-content: space-between;

      & .statisticTitle{
        font-size: 2.4rem;
        margin-bottom: 2.4rem;

        @include media(xl) {
          margin-bottom: 0;
        }

        @include media(fullHD) {
          margin-bottom: 2.4rem;
        }

        & span{
          font-size: 2.4rem;
          color: #FFD600;
        }
      }

      & .statisticBox{
        display: grid;
        grid-gap: 2.4rem;

        & .statisticBoxItem{
          & .statisticBoxItemTitle{
            margin-bottom: 0.75rem;
          }

          & .statisticBoxItemValue{
            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            color: #FFFFFF;

            font-size: 1.8rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            border-radius: 1rem;
            border: 1px solid #FFD600;
            background: rgba(255, 214, 0, 0.10);

            height: 4.4rem;
          }
        }
      }
    }

    & .noteWrap{
      display: grid;
      align-items: center;
      grid-gap: 2rem;

      @include media(xl) {
        margin-top: 5rem;
      }

      & .noteItem{
        color: #FFFFFF;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        & span{
          color: #FFD600;
        }
      }
    }
  }
}