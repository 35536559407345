@import "../../theme/scss/resources";

.container{
  display: grid;

  & .contentWrap{
    display: grid;
    grid-template-rows: 1fr max-content;
    grid-gap: 2rem;
  }
}