@import "../../theme/scss/resources";

.container{
  background: #0d0a07 url("../../theme/assets/other/honee_main_work_bg.png") no-repeat;
  background-size: contain;
  background-position: 50% 3%;
  padding: 8.5rem 0;

  @include media(sm) {
    background-position: 50% 0;
  }

  @include media(lg) {
    background-position: 50% 70%;
  }

  @include media(xl) {

    background-position: 50% 100%;
  }

  & .contentWrap{
    & .title2{
      text-align: center;
      max-width: 70rem;
      margin: 0 auto;
      margin-bottom: 6.5rem;

      & span{
        color: #FF8C00;
      }
    }

    & .contentBox{
      display: grid;
      grid-gap: 2.5rem;

      @include media(lg) {
        grid-gap: 3.5rem;
      }

      & .contentBoxRow{
        display: grid;
        grid-gap: 2.5rem;

        @include media(lg) {
          grid-gap: 3.5rem;
        }

        &:first-child{
          @include media(lg) {
            grid-template-columns: 1fr 1.25fr;
          }


          &:first-child{
            & .contentBoxRowItemNote{
              max-width: 37rem;
            }
          }

          &:last-child{
            & .contentBoxRowItemNote{
              max-width: 43rem;
            }
          }
        }

        &:last-child{
          @include media(lg) {
            grid-template-columns: 1.25fr 1fr;
          }


          &:first-child{
            & .contentBoxRowItemNote{
              max-width: 43rem;
            }
          }

          &:last-child{
            & .contentBoxRowItemNote{
              max-width: 37rem;
            }
          }
        }

        & .contentBoxRowItem{
          border: 1px solid #FF8C00;
          border-radius: 1rem;
          padding: 2.5rem;

          @include media(lg) {
            display: grid;
            align-content: space-between;
            padding: 4.5rem;
            height: 27rem;
          }

          & .contentBoxRowItemTitle{
            color: #FF8C00;

            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 3rem;

            margin-bottom: 2rem;

            @include media(lg) {
              margin-bottom: 0;
            }
          }

          & .contentBoxRowItemNote{
            color: #F5F5F5;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.4rem;
          }
        }
      }
    }
  }
}