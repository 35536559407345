@import "../../theme/scss/resources";

.container{
  background: #251b0e;
  padding: 1.2rem 0;

  border-bottom: 1px solid #915207;

  @include media(lg) {
    display: none;
  }

  & .contentWrap{
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    //position: relative;

    @include media(lg) {
      grid-auto-flow: initial;
      grid-template-columns: max-content max-content 1fr;
      justify-content: initial;
      grid-gap: 3.5rem;
    }

    &.contentWrapWAbs{
      & .burgerBtn{
        & div{
          &:first-child{
            position: relative;
            top: 0.7rem;
            transform: rotate(45deg);
          }

          &:nth-child(2){
            opacity: 0;
          }

          &:last-child{
            position: relative;
            top: -0.7rem;
            transform: rotate(-45deg);
          }
        }
      }

      & .absMobileNavigation{
        height: 36rem;
      }
    }

    & .burgerBtn{
      display: grid;
      justify-content: center;
      align-content: center;
      grid-gap: 0.5rem;
      grid-template-rows: repeat(3, max-content);

      height: 3.8rem;
      width: 3.8rem;
      border: 1px solid #FF8C00;
      border-radius: 1rem;

      @include media(lg) {
        display: none;
      }

      & div{
        display: grid;
        transition: all 0.25s ease-out;

        width: 2rem;
        height: 0.2rem;
        background: #FF8C00;
      }
    }

    & .absMobileNavigation{
      overflow: hidden;
      height: 0;
      position: absolute;
      left: 0;
      top: 14rem;
      z-index: 100;

      width: 100%;

      background: #0d0a07;
      margin-bottom: 3rem;
      transition: all 0.25s ease-out;

      @include media(sm) {
        top: 11rem;
      }

      & .navMWrap{
        & .navMBox{
          display: grid;
          justify-content: center;
          grid-gap: 1rem;
          margin: 1.5rem 0;

          & .navMBoxItem{
            cursor: pointer;
            color: #F5F5F5;

            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.2rem;
            width: max-content;
          }
        }
      }
    }

    & .buttonsWrapBorder{
      border-top: 1px solid #FF8C00;
      border-bottom: 1px solid #FF8C00;
      padding: 2rem 0;
      margin-top: 2rem;

      & .buttonsWrap{


        & .authorizationWrap{
          display: grid;
          grid-gap: 1rem;
        }
      }
    }

    & .desktopNavigationWrap{
      display: none;

      @include media(lg) {
        display: grid;
      }

      & .navWrap{
        display: grid;
        grid-template-columns: max-content 16.5rem;
        align-items: center;
        grid-gap: 3.5rem;

        & .navBox{
          display: grid;
          grid-auto-flow: column;
          grid-gap: 2.5rem;
          align-items: center;

          & .navBoxItem{
            color: #F5F5F5;

            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.2rem;
            width: max-content;
            cursor: pointer;

            &:hover{
              color: #FF8C00;
            }
          }
        }

        & .button{
          max-width: 16.5rem;
        }
      }
    }

    & .desktopButtonsWrap{
      display: none;

      @include media(lg) {
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1.5rem;
        justify-self: end;
      }

      & .authorizationWrap{
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-gap: 1.5rem;
      }
    }
  }
}