@import "../../theme/scss/resources";


:global(.fixH-overlay) {
  & :global(.popup-content) {
    overflow: scroll;
    height: 54.4rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;

    @include media(md) {
      height: 57rem;
    }
  }
}

:global(#popup-root) {
  @include modalRootContainer;
}

:global(.popup-content) {
  @include modalPopupContainer;
}

.modalContainer{
  & .modalCloseBtn{
    @include modalCloseBtn;
  }

  & .modalContentWrap {
    & .title {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #F5F5F5;
      margin-bottom: 1.25rem;

      @include media(md) {
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin-bottom: 2.5rem;
      }
    }

    & .note {
      color: #FF060A;
      text-align: center;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      padding: 1rem 2rem;

      border-radius: 1rem;
      border: 1px solid #FF060A;

      @include media(md) {
        padding: 1rem 5rem;
      }
    }

    & .commissionRow {
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      grid-gap: 1.25rem;

      margin: 1.5rem 0;

      & .commissionRowName {
        color: #FFFFFF;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      & .commissionRowValue {
        color: #FF060A;
        text-align: center;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        border-radius: 1rem;
        border: 1px solid #FF060A;

        padding: 1rem;
      }
    }

    & .requestWrap {
      border-radius: 1rem;
      border: 1px solid #FF8C00;
      background: linear-gradient(138deg, rgba(72, 61, 0, 0.15) -23.04%, rgba(72, 61, 0, 0.01) 118.95%);
      padding: 2rem;

      & .requestTitle {
        margin-bottom: 1.5rem;
      }

      & .requestBox {
        display: grid;
        grid-gap: 1.5rem;

        & .requestBoxItem {
          display: grid;
          align-items: center;
          grid-template-columns: max-content max-content;
          grid-gap: 1.25rem;

          & .requestBoxItemName {
            color: #FFFFFF;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            & span {
              color: #FF8C00;
            }
          }

          & .requestBoxItemValue {
            border-radius: 1rem;
            border: 1px solid #FF8C00;

            padding: 1rem;

            width: max-content;

            color: #FF8C00;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &.requestBoxItemValueBuy {
              color: #00FF7A !important;
              border: 1px solid #00FF7A !important;
            }

            &.requestBoxItemValueSell {
              color: #FF060A !important;
              border: 1px solid #FF060A !important;
            }
          }
        }
      }
    }

    & .mainBtn {
      color: #8C9497;

      border-radius: 1rem;
      border: 1px solid #8C9497;

      padding: 1rem;

      text-align: center;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-top: 4rem;

      &.mainBtnActive {
        cursor: pointer !important;
        background: #00FF7A !important;
        border: 1px solid #00FF7A !important;
        color: #15100A !important;
      }
    }
  }
}