@import "../../../theme/scss/resources";

.container{
  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #F5F5F5;
      margin-bottom: 2rem;
    }

    & .listWrap{
      & .listPosWrap{
        height: 6.2rem;
        margin-bottom: 5rem;

        &.listPosWrapToken{
          position: relative;
          z-index: 500;
        }

        &.listPosWrapNet{
          position: relative;
          z-index: 400;
        }

        & .listPosWrapTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: #F5F5F5;

          margin-bottom: 0.5rem;
        }

        & .listBox{
          cursor: pointer;
          position: relative;

          display: grid;
          grid-gap: 2.25rem;

          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #FF8C00;
          backdrop-filter: blur(7px);
          border-radius: 1rem;

          padding: 1.4rem 2.4rem;

          height: 6.2rem;
          //overflow-y: scroll;
          //


          & .listBtn{
            cursor: pointer;

            position: absolute;
            right: 2.4rem;
            top: 2.25rem;

            width: 2rem;
            height: 2rem;

            display: grid;
            justify-items: center;
            align-items: center;

            transition: all 0.5s ease-out;

            &.listBtnActive{
              transform: rotate(180deg);
            }
          }

          & .listResult{
            display: grid;
            grid-template-columns: max-content max-content 1fr;
            align-items: center;
            grid-gap: 0.75rem;

            & .listResultImgWrap{
              display: grid;
            }

            & .listResultText{
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.9rem;

              color: #F5F5F5;
            }

            & .listResultTextBalance{
              justify-self: end;
              padding-right: 3rem;
            }
          }
        }

        & .abslistWrap{
          //overflow-y: hidden;
          //max-height: 0;
          overflow: hidden;
          position: relative;

          max-height: 0;

          -ms-overflow-style: none;
          scrollbar-width: none;

          transition: all 0.5s ease-out;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          &.abslistWrapActive{
            max-height: 25.3rem;

            & .abslistBorderWrap{
              max-height: 23.3rem;
            }
          }



          & .abslistBorderWrap{
            background: #1C343E;
            border: 1px solid #58C1EF;
            border-radius: 1rem;

            max-height: 0;
            margin-top: 2rem;
            overflow: scroll;

            -ms-overflow-style: none;
            scrollbar-width: none;

            transition: all 0.5s ease-out;

            &::-webkit-scrollbar {
              width: 0;
              height: 0;
            }

            & .abslistBox{
              overflow-y: scroll;
              position: relative;

              display: grid;
              grid-gap: 2.25rem;



              padding: 1.4rem 2.4rem;

              -ms-overflow-style: none;
              scrollbar-width: none;

              transition: all 0.5s ease-out;

              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }

              //height: 6.2rem;

              & .listBoxItem{
                cursor: pointer;

                display: grid;
                grid-template-columns: max-content max-content 1fr;
                align-items: center;
                grid-gap: 0.75rem;

                & .listBoxItemImgWrap{
                  display: grid;
                }

                & .listBoxItemText{
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 1.9rem;

                  color: #F5F5F5;
                }

                & .listBoxItemTextBalance{
                  justify-self: end;
                }
              }
            }
          }
        }
      }
    }

    & .otherInputsWrap{
      display: grid;
      grid-gap: 3rem;

      & .otherInputsBox{
        position: relative;

        & .otherInputsBoxTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;
          margin-bottom: 0.5rem;
        }

        & input{
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;
          width: 100%;

          background: rgba(15, 32, 52, 0.1);
          border: 1px solid #FF8C00;
          backdrop-filter: blur(7px);
          border-radius: 1rem;

          padding: 1.4rem 2.4rem;
        }

        & .inputSum{
          padding: 1.4rem 6.4rem 1.4rem 2.4rem;
          -moz-appearance: textfield;

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        & .inputAbsBtn{
          cursor: pointer;

          position: absolute;
          right: 2rem;
          top: 3.5rem;

          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.9rem;

          color: #F7931A;
          padding-left: 1rem;
          border-left: 1px solid #D9D9D9;
          height: 2rem;
        }

        & .inputNote{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;
          margin-top: 0.5rem;

          & span{
            color: #F7931A;
          }
        }
      }
    }

    & .taxWrap{
      //display: none;
      grid-gap: 1rem;

      margin-top: 1.5rem;
      margin-bottom: 3rem;

      & .taxWrapRow{
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: 1rem;
        align-items: baseline;

        & .taxWrapRowName{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;
          height: 2rem;
        }

        & .taxWrapRowValue{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;
          text-align: right;

          color: #F7931A;
          opacity: 1;
          transition: opacity 0.5s ease-out;

          &.taxWrapRowValueHide{
            opacity: 0;
          }
        }
      }
    }

    & .btn{
      cursor: pointer;
      display: grid;
      align-items: center;
      width: 100%;
      max-width: 19rem;
      text-align: center;
      background: transparent;
      border: 1px solid #8C9497;
      border-radius: 1rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: #8C9497;
      height: 4.9rem;
      margin: 0 auto;
      margin-top: 3.2rem;

      position: relative;

      &.btnActive{
        color: #F5F5F5;
        background: #F7931A;
        border: 1px solid #F7931A;
      }

      & .textWrap{
        display: block;
      }

      &.mainBtnLoading{
        background: transparent !important;

        & .loaderWrap{
          display: grid !important;
          justify-items: center;
          align-items: center;
        }

        & .textWrap{
          display: none !important;
        }
      }

      & .commissionAlert{
        position: absolute;
        right: -4rem;
        bottom: -2rem;
        color: #CF0000;
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.25rem;
        width: 26rem;
      }
    }
  }
}