@import "../../theme/scss/resources";

.container{
  background: #0d0a07;
  padding: 1.2rem 0;

  border-bottom: 1px solid #FF8C00;

  & .contentWrap{
    display: grid;
    grid-gap: 1rem;

    @include media(sm) {
      grid-gap: 0;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    & .priceWrap{
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      @include media(sm) {
        grid-template-columns: max-content max-content;
      }

      & .priceWrapName{
        color: #FF8C00;

        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
        border-right: 1px solid #FF8C00;
        width: auto;
        padding-right: 1.2rem;
        text-align: right;

        @include media(sm) {
          justify-content: start;
          width: auto;

          margin-right: 1.2rem;
          text-align: left;
        }
      }

      & .priceWrapValue{
        color: #FF8C00;

        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;

        padding-left: 1.2rem;

        @include media(sm) {
          padding-left: 0;
        }
      }
    }

    & .tradeWrap{
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      @include media(sm) {
        grid-template-columns: max-content max-content;
      }

      & .tradeWrapName{
        color: #FF8C00;

        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;

        border-right: 1px solid #FF8C00;

        width: auto;
        padding-right: 1.2rem;
        text-align: right;

        @include media(sm) {
          width: auto;
          margin-right: 1.2rem;
          text-align: left;
        }
      }

      & .tradeWrapValueWrap{
        display: grid;
        grid-template-columns: max-content;
        align-items: center;

        & .tradeWrapValueWrapItem{
          cursor: pointer;
          display: grid;

          padding-left: 1.2rem;

          @include media(sm) {
            padding-left: 0;
          }

          &:first-child{
            //padding-right: 1.2rem;
            //margin-right: 1.2rem;
            //border-right: 1px solid #FF8C00;
          }

          & img{
            width: 90%;

            @include media(xs) {
              width: auto;
            }
          }
        }
      }
    }
  }
}