@import "../../theme/scss/resources";

.container{


  & .contentWrap{
    display: grid;
    grid-auto-rows: max-content;
    grid-gap: 2rem;
  }
}