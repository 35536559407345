@import "../../../theme/scss/resources";

.container{
  & .contentWrap{
    & .title{
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.5rem;

      color: #F5F5F5;
      margin-bottom: 2rem;
    }

    & .contentBox{
      display: grid;
      grid-gap: 1.5rem;

      & .contentBoxItem{
        & .contentBoxItemTitle{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #F5F5F5;
          margin-bottom: 0.5rem;
        }

        & input{
          background: #1E363F;
          border: 1px solid #58C1EF;
          border-radius: 1rem;
          padding: 1.4rem 2.3rem;
          width: 100%;

          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }

        & .contentBoxItemNote{
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          color: #8C9497;
          margin-top: 0.5rem;

          & span {
            color: #F5F5F5;
          }
        }
      }
    }

    & .btnWrap{
      display: grid;
      grid-gap: 2rem;
      margin-top: 3.5rem;

      @include media(md) {
        grid-auto-flow: column;
        justify-content: center;
        align-items: center;
        grid-gap: 3.5rem;
      }

      & .btnItem{
        border: 1px solid #F7931A;
        border-radius: 1rem;

        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;

        color: #F7931A;
        padding: 1.1rem 0;
        text-align: center;
        width: 19rem;
        margin: 0 auto;

        &.btnItemPassive{
          border: 1px solid #8C9497;
          color: #8C9497;
        }

        &.btnItemActive{

        }
      }
    }
  }
}