@import "../../theme/scss/resources";

.container{
  //border-radius: initial !important;
  //border: none !important;
  //background: transparent !important;
  //backdrop-filter: initial !important;

  & .honeeCardBody{
    & .lessonInfo{
      display: grid;
      align-content: center;
      align-items: center;
      align-self: center;
      justify-items: center;
      text-align: center;

      height: 32.4rem;

      border-radius: 1rem;
      overflow: hidden;

      & .lessonInfoNumber{
        color: #00FF7A;
        font-size: 3rem;

        @include media(md) {
          font-size: 4.4rem;
        }
      }

      & .lessonInfoTitle{
        color: #F5F5F5;
        font-size: 2.5rem;

        @include media(md) {
          font-size: 3.4rem;
        }
      }

      & .lessonInfoAbsTimer{
        display: none;

        &.lessonInfoAbsTimerActive{
          display: block !important;
        }
      }
    }

    & .testWrap{
      margin-top: 4.5rem;

      & .testTitle{
        color: #FFFFFF;
        font-size: 2.5rem;
        line-height: 2.7rem;

        @include media(md) {
          font-size: 3.4rem;
        }
      }

      & .testBox{
        margin-top: 2rem;

        & .question{
          color: #FFD600;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @include media(md) {
            font-size: 2.1rem;
          }
        }

        & .answerWrap{
          display: grid;
          grid-gap: 1rem;

          margin: 2.5rem 0;

          & .answerItem{
            cursor: pointer;
            display: grid;
            grid-template-columns: max-content minmax(0, 1fr);
            align-items: center;
            grid-gap: 1rem;
            width: 100%;

            & .customCheckBox{
              display: grid;
              align-items: center;
              justify-items: center;

              width: 3rem;
              height: 3rem;

              border-radius: 1rem;
              border: 1px solid #FFD600;

              &.customCheckBoxActive{
                & .customCheck{
                  display: block !important;
                }
              }

              & .customCheck{
                display: none;

                border-radius: 0.3rem;
                background: #FFD600;

                width: 1rem;
                height: 1rem;

              }
            }

            & .answer{
              color: #FFFFFF;
              font-size: 1.6rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              white-space: normal;
              word-wrap: break-word;
              overflow-wrap: break-word;
              max-width: 100%;

              display: block;
            }
          }
        }

        & .answerBtn {
          max-width: 32rem;

          &.cabinetBtnDisable {
            height: 4.8rem;

            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            font-size: 1.8rem;
            line-height: 1.8rem;
            font-weight: 700;
            text-transform: initial;
            margin: 0.25rem 0.125rem;
          }
        }
      }
    }
  }
}